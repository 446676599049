import { PMC } from '../interface/pfa-pmc-thresholds';

export const pfaToPmc = (pfa: number) => {
  switch (pfa) {
    case 4:
      return PMC.High;
    case 5:
      return PMC.Low;
    default:
      return PMC.VeryLow;
  }
};
