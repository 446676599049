import { HttpErrorResponse } from '@angular/common/http';

import { AStrionApiError } from './app-error.class';
import errors from './app-errors.json';

type ErrorCode = `[${string}]`;

function getErrorCode(message: string): ErrorCode | null {
  const match = message.match(/\[.*\]/);
  return match?.length ? (match[0] as ErrorCode) : null;
}

const appErrors = errors as {
  messageErrors: Record<string, string>;
  codeErrors: Record<ErrorCode, string>;
};

export function getErrorFromHttp(response: HttpErrorResponse): AStrionApiError {
  if (response.status === 0 || response.status === 502) {
    return new AStrionApiError(response, 'Connection error', true);
  }

  const errorMessage =
    typeof response.error === 'string' || response.error instanceof String ? (response.error as string) : null;
  let displayMessage: string;
  const errorCode = errorMessage ? getErrorCode(errorMessage) : null;
  if (errorCode && errorCode in appErrors.codeErrors) {
    displayMessage = appErrors.codeErrors[errorCode];
  } else if (errorMessage && errorMessage in appErrors.messageErrors) {
    displayMessage = appErrors.messageErrors[errorMessage];
  } else {
    displayMessage = 'API Error';
  }
  return new AStrionApiError(response, displayMessage);
}
