import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { ShannonTest, ShannonTestDto, ShannonTestResult } from '../interface/sampling-test';
import { ValidationStepStatus } from '../interface/validation-step-status';

export function mapSamplingTestFromDto(dto: ShannonTestDto): ShannonTest {
  const shannonTest: ShannonTest = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (shannonTest.progress === ComputationStepProgress.Completed) {
    const dtoData = dto.data!;

    const linearTest = { ...dtoData.linearTestSummary };

    const resultsReduceMethod = (): ShannonTestResult => ({
      signalTooShort: dtoData.signalTooShort,
      linearSucceeded: dtoData.linearTestSummary.result.succeeded,
      dbSucceeded: dtoData.dbTestSummary.result.succeeded,
      noiseSucceeded: dtoData.noiseTestSummary.result.succeeded,
    });

    const results = resultsReduceMethod();

    const hasWarnings =
      results.signalTooShort || !results.linearSucceeded || !results.dbSucceeded || !results.noiseSucceeded;

    const status = hasWarnings ? ValidationStepStatus.Warning : ValidationStepStatus.Success;

    shannonTest.data = {
      ...dtoData,
      status,
      linearTestSummary: linearTest,
      dbTestSummary: { ...dtoData.dbTestSummary },
      noiseTestSummary: { ...dtoData.noiseTestSummary },
      results: resultsReduceMethod,
    };
  }

  return shannonTest;
}
