<div class="flex h-full w-full flex-col">
  <div class="-mt-14 flex flex-row justify-end">
    <mat-nav-list class="horizontal-nav-list flex rounded-3xl py-0 text-center font-bold">
      <a
        mat-list-item
        *ngFor="let tab of tabs"
        [class.bg-nav-selected]="isSelected(tab.id)()"
        class="min-w-36"
        [routerLink]="[]"
        [fragment]="tab.fragment"
        >{{ tab.name }}</a
      >
    </mat-nav-list>
  </div>

  <span class="m3-headline-small mt-2 flex-none">Sensor Overview</span>

  <div class="mt-4 flex flex-row">
    <mat-card appearance="outlined" class="w-[281px]">
      <mat-card-content
        ><div class="m3-title-large flex flex-col">
          <span>Number of signals</span>
          <div class="mt-1 flex flex-row justify-between">
            <div class="m3-display-small">{{ signalCount() }}</div>
            <mat-icon class="fg-primary-30">stacked_line_chart</mat-icon>
          </div>
        </div></mat-card-content
      >
    </mat-card>
  </div>

  <div class="mt-4 h-full w-full flex-grow">
    <app-sensor-signals
      id="Signals"
      [sensorId]="folder().id"
      [hidden]="!signalsTabSelected()"
      [loading]="loading()"
      [currentlyEditedItemId]="currentlyEditedItemId()"
      (navigateToGraph)="navigateToGraph()"
      (openInNew)="openInNew.emit($event)"
      (itemClicked)="itemClicked.emit($event)"
      (deleteClicked)="deleteClicked.emit($event)"
      (renameClicked)="renameClicked.emit($event)"
      (refreshClicked)="refreshClicked.emit()"
      (downloadClicked)="downloadClicked.emit($event)"
      (renameCanceled)="renameCanceled.emit($event)"
      (itemNameChanged)="itemNameChanged.emit($event)"
      (filesDropped)="filesDropped.emit($event)" />

    <app-sensor-trajectories
      id="Trajectories"
      class="h-full w-full"
      [sensorId]="folder().id"
      [hidden]="!trajectoriesTabSelected()"
      (navigateToGraph)="navigateToGraph()" />

    <app-sensor-graphs id="Graph" [hidden]="!graphsTabSelected()" class="h-full w-full" name="Sensor graph" />
  </div>
</div>
