import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';
import { base64DataStringToArray } from '@tools/utilities/base64-translation-utilities';

import { TimeSaturation, TimeSaturationDto } from '../interface/time-saturation';
import { ValidationStepStatus } from '../interface/validation-step-status';

export function mapTimeSaturationFromDto(dto: TimeSaturationDto): TimeSaturation {
  const saturation: TimeSaturation = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (saturation.progress === ComputationStepProgress.Completed) {
    const dtoData = dto.data!;

    const status = dtoData.saturationIndices.length > 0 ? ValidationStepStatus.Warning : ValidationStepStatus.Success;

    saturation.data = {
      status,
      saturationIndices: base64DataStringToArray(dtoData.saturationIndices, 'Int32'),
    };
  }
  return saturation;
}
