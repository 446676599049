import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { GlGraphComponent, Scaling } from '@astrion-webtools/graph';
import { FusionData, FusionPeak } from '@features/peak-identification/shared/interface/fusion';
import { peakToLine } from '@features/peak-identification/shared/utils/peak-utils';
import { MaterialModule } from '@modules/material.module';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

import { ContentStyle } from '../content-toggle/content-toggle.component';
import {
  CurvesOptions,
  PeaksGraphInteractiveLegendComponent,
  SimplifiedPeak,
} from '../peaks-graph-interactive-legend/peaks-graph-interactive-legend.component';
import { Column, PeaksTableComponent, TablePeak } from '../peaks-table/peaks-table.component';

@Component({
  selector: 'app-fusion',
  standalone: true,
  imports: [MaterialModule, GlGraphComponent, PeaksGraphInteractiveLegendComponent, PeaksTableComponent],
  templateUrl: './fusion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FusionComponent {
  public fusion = input.required<FusionData>();

  public dbScale = signal(true);
  public yScale = computed(() => (this.dbScale() ? Scaling.dB : Scaling.None));

  public yTitle = computed(() => (this.dbScale() ? 'Spectrum (dB)' : 'Spectrum'));

  public showNoiseArea = signal<boolean>(true);

  public get noiseAreaStyle(): ContentStyle {
    return {
      color: '#078bf8bb',
    };
  }

  public get curveOptions(): CurvesOptions {
    return {
      name: 'Curves',
      first: {
        name: 'Noise',
        style: this.noiseAreaStyle,
        state: this.showNoiseArea,
      },
    };
  }

  public fusionPeaks = computed((): FusionPeak[] => this.fusion().peaks);

  public peaksTableAdditionalColumns = computed((): Column[] => {
    const fusion = this.fusion();

    return [
      {
        name: 'Best cycle',
        propertyName: 'bestCycleIndex',
        formattedPropertyValue: (peak: TablePeak) => {
          return `${(peak as unknown as FusionPeak).bestCycleIndex}`;
        },
      },
      {
        name: 'Fusion cycles',
        propertyName: 'cyclesCount',
        formattedPropertyValue: (peak: TablePeak) => {
          return `${(peak as unknown as FusionPeak).cyclesCount}/${fusion.cyclesCount}`;
        },
      },
    ];
  });

  public peaksFilter = signal<(peak: SimplifiedPeak) => boolean>(() => true);

  public fusionPeakLines = computed(() => {
    return this.fusionPeaks().filter(this.peaksFilter()).map(peakToLine);
  });

  public fusionData = computed(() => {
    const noiseArea = this.fusion().noiseArea;

    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.fusion,
      areas: [
        {
          name: 'noiseArea',
          color: this.noiseAreaStyle.color,
          dataScale: Scaling.dB,
          minCurve: {
            name: 'min',
            data: {
              indexedDb: {
                id: noiseArea.minAmplitudes,
                valuesField: 'amplitudes',
                xMinField: 'freqMin',
                xMaxField: 'freqMax',
              },
            },
          },
          maxCurve: {
            name: 'max',
            data: {
              indexedDb: {
                id: noiseArea.maxAmplitudes,
                valuesField: 'amplitudes',
                xMinField: 'freqMin',
                xMaxField: 'freqMax',
              },
            },
          },
        },
      ],
    };
  });
}
