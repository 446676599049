<mat-nav-list [disableRipple]="true" [autofocus]="false" (click)="blur()">
  @for (page of pages(); track page.name) {
    <mat-list-item
      routerLink="{{ page.path }}"
      routerLinkActive
      #rla="routerLinkActive"
      class="relative overflow-visible bg-slate-100"
      [appTriggerMenuOnHover]="trigger"
      [triggerDisabled]="rla.isActive"
      #triggerHover="appTriggerMenuOnHover">
      <mat-icon matListItemIcon [style]="{ color: page.color }">{{ page.icon }}</mat-icon>

      <span matListItemTitle class="flex flex-row justify-between">
        <app-report-navigation-section
          class="justify-self-start"
          [page]="page"
          [style.font-weight]="rla.isActive ? 'bold' : 'normal'" />

        @if (loading(page)) {
          <mat-progress-spinner class="ml-5 h-[24px] w-[24px] align-middle" mode="indeterminate" />
        } @else if (error(page)) {
          <mat-icon [style]="{ color: '#fe621d' }">error_outline</mat-icon>
        } @else if (notFound(page)) {
          <mat-icon>help_outline</mat-icon>
        }
      </span>
      <span
        class="absolute right-0 top-0 h-0 w-0 opacity-0"
        matMenuTrigger
        [matMenuTriggerFor]="menu"
        #trigger="matMenuTrigger"></span>
    </mat-list-item>
    <mat-menu #menu="matMenu" [hasBackdrop]="false" yPosition="below" xPosition="after">
      <app-report-navigation-menu
        [page]="page"
        (mouseenter)="triggerHover.mouseEnterMenu()"
        (mouseleave)="triggerHover.mouseLeaveMenu()" />
    </mat-menu>
    @if (rla.isActive) {
      <app-report-navigation-anchors [page]="page" [scrolledAnchor]="scrolledAnchor()" />
    }
  }
</mat-nav-list>
