import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { isAStrionError, isError } from '../app-error/app-error.class';

@Injectable()
export class ErrorHandlingService implements ErrorHandler {
  constructor(private errorSnackbar: MatSnackBar) {}

  handleError = (error: unknown): void => {
    if (isError(error)) {
      if (isAStrionError(error) && error.displayMessage) {
        this.displayErrorMessage(error.displayMessage);
      } else {
        throw error;
        //console.log(`[Error]: ${error.message}`);
      }
    } else {
      console.log(error);
    }
  };

  displayErrorMessage = (message: string) => {
    this.errorSnackbar.open(message, 'Dismiss', {
      duration: 3000,
    });
  };

  hideErrorMessage = () => {
    this.errorSnackbar.dismiss();
  };
}
