import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { Cycles, defaultCyclesWithLoadingState } from '../interface/cycles';
import { defaultFusionWithLoadingState, Fusion } from '../interface/fusion';
import { PeakIdentification } from '../interface/peak-identification';
import { PeakIdentificationActions } from './peak-identification.actions';
import { PEAK_IDENTIFICATION_INITIAL_STATE, PeakIdentificationState } from './peak-identification.state';

export const reducer = createReducer(
  PEAK_IDENTIFICATION_INITIAL_STATE,
  on(
    PeakIdentificationActions.peakIdentificationReset,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId | null }): PeakIdentificationState => ({
      ...PEAK_IDENTIFICATION_INITIAL_STATE,
      signalId,
    })
  ),
  on(
    PeakIdentificationActions.peakIdentificationFetched,
    (
      state: PeakIdentificationState,
      { peakIdentification }: { peakIdentification: PeakIdentification }
    ): PeakIdentificationState => ({
      ...state,
      ...peakIdentification,
    })
  ),
  on(
    PeakIdentificationActions.peakIdentificationNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...PEAK_IDENTIFICATION_INITIAL_STATE,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    PeakIdentificationActions.peakIdentificationFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...PEAK_IDENTIFICATION_INITIAL_STATE,
        loadingState: LoadingState.Error,
      };
    }
  ),
  on(
    PeakIdentificationActions.cyclesFetched,
    (
      state: PeakIdentificationState,
      { signalId, cycles }: { signalId: AStrionSignalId; cycles: Cycles }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        cycles,
      };
    }
  ),
  on(
    PeakIdentificationActions.cyclesNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    PeakIdentificationActions.cyclesFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionFetched,
    (
      state: PeakIdentificationState,
      { signalId, fusion }: { signalId: AStrionSignalId; fusion: Fusion }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        fusion,
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.Error),
      };
    }
  )
);
