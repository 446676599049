import { Folder, FolderId } from '@features/folders/shared/interface/folder.interface';
import { foldersFeature } from '@features/folders/shared/store/folders.feature';
import { FoldersUri, getFolderId, getPathFromRoot } from '@features/folders/shared/utils/folders-uri';
import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { SensorSignal } from '@features/signals/shared/interface/sensor-signal.interface';
import { createSelector } from '@ngrx/store';
import { selectUrl } from '@store/router.selectors';

import { ExplorerItem, ExplorerItemType, ExplorerSensorSignalItem } from '../interface/explorer-item.interface';
import { FILES_EXPLORER_ROOT } from '../utils/files-explorer-root';
import { filesExplorerFeature } from './files-explorer.feature';

const folder2item = (folder: Folder): ExplorerItem => ({
  type: ExplorerItemType.Folder,
  object: folder,
});

const sensorSignal2item = (signal: SensorSignal): ExplorerSensorSignalItem => ({
  type: ExplorerItemType.SensorSignal,
  object: signal,
});

/**
 * append files-explorer root path to base folder paths
 */
export const selectFoldersExplorerUris = createSelector(
  foldersFeature.selectFoldersUri,
  foldersUri =>
    ({
      folderIdToUri: Object.fromEntries(
        Object.entries(foldersUri.folderIdToUri).map(([folderId, path]) => [folderId, `/${FILES_EXPLORER_ROOT}${path}`])
      ),
      uriToFolderId: Object.fromEntries(
        Object.entries(foldersUri.uriToFolderId).map(([path, folderId]) => [`/${FILES_EXPLORER_ROOT}${path}`, folderId])
      ),
    }) as FoldersUri
);

export const selectCachedFolderId = createSelector(
  filesExplorerFeature.selectCachedFolder,
  cachedFolder => cachedFolder.folderId
);

export const selectCachedFolderSignals = createSelector(
  filesExplorerFeature.selectCachedFolder,
  cachedFolder => cachedFolder.signals
);

export const selectHasSignals = createSelector(
  selectCachedFolderSignals,
  (signals: AStrionSignal[]): boolean => signals.length > 0
);

export const selectCurrentFolderId = createSelector(
  selectUrl,
  selectFoldersExplorerUris,
  (url, foldersUri): FolderId | null => getFolderId(foldersUri, url)
);

export const selectCurrentFolder = createSelector(
  selectCurrentFolderId,
  foldersFeature.selectFlattenedFoldersTree,
  (folderId, flatTree): Folder | null => (folderId ? flatTree.folders[folderId] ?? null : null)
);

export const selectUriFoldersPath = createSelector(
  selectCurrentFolder,
  foldersFeature.selectFlattenedFoldersTree,
  (folder: Folder | null, foldersTree): Folder[] | null => {
    return folder ? getPathFromRoot(folder, foldersTree) : null;
  }
);

export const selectFolderUri = (folderId: FolderId) =>
  createSelector(foldersFeature.selectFoldersUri, foldersUri => foldersUri.folderIdToUri[folderId]);

export const selectCurrentSubfolders = createSelector(
  foldersFeature.selectFlattenedFoldersTree,
  selectCurrentFolderId,
  (flattenedFoldersTree, currentFolderId): Folder[] =>
    currentFolderId
      ? flattenedFoldersTree.children[currentFolderId].map(folderId => flattenedFoldersTree.folders[folderId])
      : []
);

export const selectExplorerContent = createSelector(
  selectCurrentSubfolders,
  selectCachedFolderSignals,
  (folders, signals): ExplorerItem[][] => [folders.map(folder2item), signals.map(sensorSignal2item)]
);

export const selectExplorerSensorContent = createSelector(selectCachedFolderSignals, signals =>
  signals.map(s => sensorSignal2item(s))
);

export const selectSensorDuplicateDateSignalId = createSelector(selectCachedFolderSignals, signals => {
  return signals
    .filter(signal => !!signal.date)
    .map(signal => ({ id: signal.id, dateTime: signal.date?.getTime() ?? 0, createTime: signal.createdAt.getTime() }))
    .sort((a, b) => (a.dateTime === b.dateTime ? a.createTime - b.createTime : a.dateTime - b.dateTime))
    .filter((signal, index, sorted) => index > 0 && sorted[index - 1].dateTime === signal.dateTime)
    .map(s => s.id);
});

export const selectExplorerSensorContainSignal = (signalId: AStrionSignalId) =>
  createSelector(selectExplorerSensorContent, signals => signals.some(s => s.object.id === signalId));
