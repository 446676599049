import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { ColorMapData } from '../interfaces/interfaces';

@Component({
  selector: 'app-color-scale',
  standalone: true,
  imports: [],
  templateUrl: './color-scale.component.html',
})
export class ColorScaleComponent implements AfterViewInit {
  @Input({ required: true }) colorMapData!: ColorMapData;
  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;

  ngAfterViewInit(): void {
    const height = this.canvas.nativeElement.height;
    const widthQuotient = Math.floor(this.canvas.nativeElement.width / this.colorMapData.colorMap.length);
    const actualWidth = widthQuotient * this.colorMapData.colorMap.length;

    this.canvas.nativeElement.width = actualWidth;

    const width = 256;

    const ctx = this.canvas.nativeElement.getContext('2d')!;
    const colorMap = this.colorMapData.colorMap;

    ctx.beginPath();

    for (let i = 0; i < width; i += widthQuotient) {
      const color = colorMap[i];
      ctx.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a / 256})`;
      ctx.fillRect(i, 0, widthQuotient, height);
    }
  }
}
