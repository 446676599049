import { Pipe, PipeTransform } from '@angular/core';

const HOUR_S = 3600;
const MIN_S = 60;

const get_time_units = (duration_s: number, unit_s: number): [number, number] => {
  let units = 0;
  if (duration_s >= unit_s) {
    units = Math.floor(duration_s / unit_s);
    duration_s -= units * unit_s;
  }
  return [duration_s, units];
};

const pad = (n: number, z: number = 2): string => `${n}`.padStart(z, '0');

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  transform(duration_s: number): string {
    const [remaining_min, hours] = get_time_units(duration_s, HOUR_S);
    const [remaining_s, minutes] = get_time_units(remaining_min, MIN_S);
    const seconds = Math.floor(remaining_s);
    const milliseconds = Math.round(1000.0 * (remaining_s - seconds));
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${pad(milliseconds, 3)}`;
  }
}
