export const strictCharacters = '()';
export const componentCharacters = ';/?:@&=+$,#';

const strictRegex = new RegExp(String.raw`[${strictCharacters}]`, 'g');

const validChars = String.raw`${strictCharacters}${componentCharacters}`;
const validRegex = new RegExp(`^[a-zA-Z'!~\\.\\-_ \\d${validChars}]+$`);

export const encodeURIStrict = (uri: string): string =>
  encodeURI(uri).replace(strictRegex, c => '%' + c.charCodeAt(0).toString(16).toUpperCase());

export const encodeURIComponentStrict = (uri: string): string =>
  encodeURIComponent(uri).replace(strictRegex, c => '%' + c.charCodeAt(0).toString(16).toUpperCase());

export const isValidName = (name: string) => validRegex.test(name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
