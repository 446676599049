import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';

import { Project } from './shared/interfaces/project';
import { authFeature } from './shared/store/auth.feature';

export const projectGuardFunction: CanActivateFn = (_, state: RouterStateSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(authFeature.selectChosenProject).pipe(
    first(),
    map((project: Project | null) => {
      if (project === null) {
        const redirectoTo = state.url.toString();
        return router.parseUrl('/auth/chooseproject?redirectTo=' + redirectoTo);
      } else {
        return true;
      }
    })
  );
};
