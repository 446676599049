<div class="flex h-full w-full flex-col">
  <div class="mb-1 flex w-full flex-row items-center">
    <div class="m3-headline-small mb-1 mt-2">Sensor Trajectories</div>
    <button mat-icon-button matTooltip="Refresh trajectories" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
    @if (canRecompute()) {
      <button matTooltip="Recompute" mat-icon-button (click)="recompute()">
        <mat-icon>play_circle</mat-icon>
      </button>
    }
    <button
      mat-icon-button
      matTooltip="{{ statusItemDescription().tooltip }}{{
        sensorTrajectoriesNextComputationDate() | date: ' (HH:mm)'
      }}">
      <mat-icon
        class="text-center"
        [ngStyle]="{ color: statusItemDescription().color }"
        [ngClass]="statusItemDescription().itemClass">
        {{ statusItemDescription().icon }}
      </mat-icon>
    </button>
    <button
      mat-flat-button
      class="ml-auto"
      data-testid="visualize-selected"
      matTooltip="Show selected data in graph"
      [disabled]="!hasSelected()"
      (click)="visualize()">
      <mat-icon>insights</mat-icon>Visualize
    </button>
  </div>
  @if (dataSource(); as dataSource) {
    <app-scrollable-bordered-container class="flex-grow" [fadeTop]="true" [fadeBottom]="true">
      <mat-table [dataSource]="dataSource" matSort matSortActive="detectionPercentage" matSortDirection="desc">
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            data-testid="name-column"
            mat-sort-header
            class="bg-neutral-90 grow-0 basis-48 rounded-tl-lg"
            >Trajectory</mat-header-cell
          >
          <mat-cell *matCellDef="let trajectory" class="grow-0 basis-48">{{ trajectory.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="detectionPercentage">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="bg-neutral-90 grow-0 basis-48"
            >Detection ratio</mat-header-cell
          >
          <mat-cell *matCellDef="let trajectory" class="grow-0 basis-48"
            >{{ trajectory.detectionPercentage | number: '2.0-0' }}%</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="frequency">
          <mat-header-cell *matHeaderCellDef class="bg-neutral-90 grow-0 basis-48 justify-center"
            ><mat-checkbox
              [checked]="allSelected()"
              [indeterminate]="partiallySelected()"
              (change)="checkFrequencyUpdate($event.checked)" />Frequency</mat-header-cell
          >
          <mat-cell *matCellDef="let trajectory" class="grow-0 basis-48 justify-center p-1">
            <app-trend-miniature
              (click)="toggle(TrendType.Trajectory, trajectory.id)"
              [selected]="isSelected(TrendType.Trajectory, trajectory.id)"
              [attr.data-testid]="'visualize-frequency-' + trajectory.id"
              [contentPath]="trajectory.frequencyTrendMiniatureContentPath" />
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="filler">
          <mat-header-cell *matHeaderCellDef class="bg-neutral-90 flex-grow rounded-tr-lg" />
          <mat-cell *matCellDef="let trajectory" class="flex-grow" />
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns; sticky: true" />
        <mat-row *matRowDef="let trajectory; columns: columns" />

        <div *matNoDataRow class="flex flex-row justify-center">
          <span>No trajectory found</span>
        </div>
      </mat-table>
    </app-scrollable-bordered-container>
    <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons pageSize="50" class="mr-auto" />
  } @else {
    <span>Trajectories not computed for this sensor</span>
  }
</div>
