import { createFeature } from '@ngrx/store';

import { reducer } from './peak-identification.reducer';
import { extraSelectors } from './peak-identification.selectors';
import { featureName } from './peak-identification.state';

export const peakIdentificationFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors,
});
