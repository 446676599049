import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { props } from '@ngrx/store';

import { Stationarity } from '../../interface/stationarity';

export const stationarityActions = () => {
  return {
    signalStationarityTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalStationarityTestFetched: props<{ signalId: AStrionSignalId; stationarityTest: Stationarity }>(),
    signalStationarityTestNotFound: props<{ signalId: AStrionSignalId }>(),
    signalStationarityTestFetchFailed: props<{ signalId: AStrionSignalId }>(),
  };
};
