import { TimeSaturation } from '@features/data-validation/shared/interface/time-saturation';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { props } from '@ngrx/store';

export const timeSaturationActions = () => {
  return {
    signalTimeSaturationTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalTimeSaturationTestFetched: props<{ signalId: AStrionSignalId; saturationTest: TimeSaturation }>(),
    signalTimeSaturationTestNotFound: props<{ signalId: AStrionSignalId }>(),
    signalTimeSaturationTestFetchFailed: props<{ signalId: AStrionSignalId }>(),
  };
};
