import { createActionGroup, props } from '@ngrx/store';

import { ConnectionStatus } from '../interfaces/connection-status';

export const RealtimeActions = createActionGroup({
  source: 'Realtime',
  events: {
    connectionStatusChanged: props<{ status: ConnectionStatus; previousStatus: ConnectionStatus | undefined }>(),
  },
});
