import { createFeature } from '@ngrx/store';

import { reducer } from './auth.reducer';
import { extraSelectors } from './auth.selectors';
import { featureName } from './auth.state';

export const authFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors,
});
