export interface Project {
  id: number;
  name: string;
  fullname: string;
  roles: string[];
}

export function buildProjectsFromClaims(claims: string[] | undefined): Project[] {
  if (!claims) return [];
  const flatProjects = claims.map<Project>(p => {
    const withoutStartingSlash = p.startsWith('/') ? p.substring(1) : p; // TODO remove test when migrated with slash
    const sanitized = withoutStartingSlash.split('/'); //p.substring(1).split('/');

    return {
      id: parseInt(sanitized[0].split(':')[0]),
      name: sanitized[0].split(':')[1],
      fullname: p,
      roles: sanitized[1] !== undefined ? [sanitized[1]] : [],
    };
  });

  const projects: Project[] = flatProjects.reduce((acc: Project[], project: Project) => {
    const existingProject = acc.find(p => p.name === project.name);
    if (existingProject) {
      existingProject.roles.push(...project.roles);
    } else {
      acc.push(project);
    }
    return acc;
  }, []);

  return projects;
}
