import { FolderId } from '@features/folders/shared/interface/folder.interface';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';

export const featureName = 'signal-report';

export interface SignalReportState {
  name: string;
  signalId: AStrionSignalId | null;
  folderId: FolderId | null;
  loadingState: LoadingState;
}

export const SIGNAL_REPORT_INITIAL_STATE: SignalReportState = {
  name: '',
  signalId: null,
  folderId: null,
  loadingState: LoadingState.Unknown,
};
