<div class="mt-2 h-full w-full">
  @if (data(); as data) {
    <app-trajectory-graph
      graphHeight="50vh"
      [id]="id()"
      [name]="name() | titlecase"
      xTitle="Date"
      yTitle="Rate"
      [data]="data"
      (delete)="onDelete()"
      (removeCurve)="onRemoveCurve($event)" />
  }
</div>
