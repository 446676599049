import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { createSelector } from '@ngrx/store';
import { DataBytes } from '@shared/interfaces/data-bytes';

import { SignalContent } from '../interface/signal-content';
import { SignalMetadata } from '../interface/signal-metadata';
import { featureName, SignalOverviewState } from './signal-overview.state';

export const extraSelectors = ({ selectSignalData }: BaseSelectors<typeof featureName, SignalOverviewState>) => ({
  selectSignalBytes: createSelector(
    selectSignalData,
    (signalData: SignalContent | undefined): DataBytes | undefined => signalData?.content
  ),
  selectSignalMetadata: createSelector(
    selectSignalData,
    (signalData: SignalContent | undefined): SignalMetadata | undefined => signalData?.metadata
  ),
});
