<mat-list>
  @for (upload of uploads(); track $index) {
    <mat-list-item>
      <mat-icon [ngStyle]="{ color: color(upload.status) }" matListItemIcon>
        {{ icon(upload.status) }}
      </mat-icon>
      <span matListItemTitle>{{ upload.name }}</span>
      @if (!isSuccess(upload.status)) {
        <span matListItemLine>{{ statusMessage(upload.status) }}</span>
      }
    </mat-list-item>
  }
</mat-list>
