import { Routes } from '@angular/router';

import { authGuardFunction } from './auth.guard';
import { ChooseProjectComponent } from './views/chooseproject/chooseproject.component';

export const AUTH_ROUTES: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'chooseproject',
        canActivate: [authGuardFunction],
        component: ChooseProjectComponent,
      },
    ],
  },
];
