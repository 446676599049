<div class="mb-6 flex w-full flex-col gap-8">
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.overview.anchors.signal">
    <h3 appReportAnchorTitle>Signal</h3>
    @if (ready(signalBytes())) {
      <app-report-signal-graph [samplesId]="signalBytes()!.data!" />
    } @else {
      <app-result-unavailable [loadableData]="signalBytes() ? signalBytes()! : loadableItem()" class="h-32 w-full" />
    }
  </div>

  <div class="flex flex-initial flex-col items-center gap-2" [appReportAnchor]="REPORT_NAMES.overview.anchors.metadata">
    <h3 appReportAnchorTitle>Metadata</h3>
    @if (ready(loadableItem())) {
      <app-signal-overview-metadata class="flex-none" [metadata]="signalMetadata()!" />
    } @else {
      <app-result-unavailable [loadableData]="loadableItem()" class="h-32 w-full" />
    }
  </div>
</div>
