import { Routes } from '@angular/router';

import { ReportDataValidationComponent } from './views/report-data-validation/report-data-validation.component';
import { ReportOverviewComponent } from './views/report-overview/report-overview.component';
import { ReportPeakIdentificationComponent } from './views/report-peak-identification/report-peak-identification.component';
import { SignalReportComponent } from './views/signal-report/signal-report.component';

export const SIGNAL_REPORT_ROUTES: Routes = [
  {
    path: ':id',
    component: SignalReportComponent,
    data: {
      signalReport: true,
    },
    children: [
      { path: 'overview', component: ReportOverviewComponent },
      { path: 'data-validation', component: ReportDataValidationComponent },
      { path: 'peak-identification', component: ReportPeakIdentificationComponent },
      { path: '', redirectTo: 'overview', pathMatch: 'prefix' },
    ],
  },
];
