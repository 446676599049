import { createFeature } from '@ngrx/store';

import { reducer } from './signals.reducer';
import { extraSelectors } from './signals.selectors';
import { name } from './signals.state';

export const signalsFeature = createFeature({
  name,
  reducer,
  extraSelectors,
});
