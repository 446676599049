import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataValidationSensorApiService } from '@features/data-validation/shared/services/data-validation-sensor-api.service';
import { ValidationFlagsApiService } from '@features/data-validation/shared/services/validation-flags-api.service';
import { validationFlagsFromDto } from '@features/data-validation/shared/utils/validation-flags-mapping';
import { UploadStatusDialogComponent } from '@features/files-explorer/components/upload-status-dialog/upload-status-dialog.component';
import { HOME_FOLDER } from '@features/folders/shared/interface/folder.interface';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import { foldersFeature } from '@features/folders/shared/store/folders.feature';
import { selectFolderIsLeaf } from '@features/folders/shared/store/folders.selectors';
import { getFolderUrl } from '@features/folders/shared/utils/folders-uri';
import { PeakIdentificationSensorApiService } from '@features/peak-identification/shared/services/peak-identification-sensor-api.service';
import { TrajectoriesApiService } from '@features/sensor-trajectories/services/trajectories-api/trajectories-api.service';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { AStrionWebError } from '@modules/error-handling/app-error/app-error.class';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { selectFragment, selectRouteData } from '@store/router.selectors';
import { concatMap, filter, map, tap } from 'rxjs';

import { FilesExplorerActions } from './files-explorer.actions';
import { selectCachedFolderId, selectCurrentFolder, selectFoldersExplorerUris } from './files-explorer.selectors';

@Injectable()
export class FilesExplorerEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private errorSnackbar: MatSnackBar,
    private router: Router,
    private store: Store,
    private validationFlagsApiService: ValidationFlagsApiService,
    private dataValidationSensorApiService: DataValidationSensorApiService,
    private peakIdentificationSensorApiService: PeakIdentificationSensorApiService,
    private trajectoriesApiService: TrajectoriesApiService
  ) {}

  closeEditionAfterFolderNameChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.folderUpdated, FoldersActions.folderUpdateFailed),
      map(({ folder }) => FilesExplorerActions.editedItemCleared({ fromId: folder.id }))
    );
  });

  closeEditionAfterSignalNameChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalUpdated, SignalsActions.signalUpdateFailed),
      map(({ signal }) => FilesExplorerActions.editedItemCleared({ fromId: signal.id }))
    );
  });

  loadContentWhenFoldersFetched$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.foldersFetched, routerNavigatedAction),
      concatLatestFrom(() => [
        this.store.select(selectCurrentFolder),
        this.store.select(selectRouteData),
        this.store.select(foldersFeature.selectInitialized),
      ]),
      map(([, folder, data, initialized]) =>
        data['fileExplorer'] && initialized
          ? FilesExplorerActions.navigationToFolderRequested({ folder: folder ?? HOME_FOLDER })
          : undefined
      ),
      filter(action => action !== undefined),
      map(action => action!) // eslint does not understant the statement rigth above
    );
  });

  fetchSignalsOnCurrentFolderChangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesExplorerActions.navigationToFolderRequested),
      concatLatestFrom(({ folder }) => [
        this.store.select(selectFolderIsLeaf(folder.id)),
        this.store.select(selectCachedFolderId),
      ]),
      map(([{ folder }, isLeaf, cachedFolderId]) => {
        if (isLeaf) {
          if (folder.id === cachedFolderId) {
            return FilesExplorerActions.fetchFolderSkipAlreadyCached({ folder });
          } else {
            return SignalsActions.folderSignalsFetchRequested({ folderId: folder.id });
          }
        } else {
          return FilesExplorerActions.fetchFolderSkipNoContent({ folder });
        }
      })
    );
  });

  navigateAfterFolderCacheFilled$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SignalsActions.folderSignalsFetched),
        concatLatestFrom(() => [this.store.select(selectFoldersExplorerUris), this.store.select(selectFragment)]),
        tap(([{ folderId }, foldersUri, fragment]) =>
          this.router.navigateByUrl(getFolderUrl(foldersUri, folderId, fragment))
        )
      );
    },
    { dispatch: false }
  );

  navigateAfterFolderSkipNoContent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilesExplorerActions.fetchFolderSkipNoContent),
        concatLatestFrom(() => [this.store.select(selectFoldersExplorerUris)]),
        tap(([{ folder }, foldersUri]) => this.router.navigateByUrl(getFolderUrl(foldersUri, folder.id)))
      );
    },
    { dispatch: false }
  );

  navigateAfterFolderSkipAlreadyCached$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilesExplorerActions.fetchFolderSkipAlreadyCached),
        concatLatestFrom(() => [this.store.select(selectFoldersExplorerUris), this.store.select(selectFragment)]),
        tap(([{ folder }, foldersUri, fragment]) =>
          this.router.navigateByUrl(getFolderUrl(foldersUri, folder.id, fragment))
        )
      );
    },
    { dispatch: false }
  );

  navigateOnFolderDeletion$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FoldersActions.folderDeleted),
        concatLatestFrom(() => [this.store.select(selectCurrentFolder), this.store.select(selectFoldersExplorerUris)]),
        tap(([{ parentId }, currentFolder, foldersUri]) => {
          if (currentFolder === null) {
            this.router.navigateByUrl(foldersUri.folderIdToUri[parentId]);
            throw new AStrionWebError('Current folder deleted', 'The folder you were in just got deleted.');
          }
        })
      );
    },
    { dispatch: false }
  );

  signalUploadStarts$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SignalsActions.signalsUploadStarted),
        tap(() => {
          this.dialog.open(UploadStatusDialogComponent, {
            minWidth: '30vw',
            minHeight: '50vh',
            disableClose: true,
          });
        })
      );
    },
    { dispatch: false }
  );

  fetchValidationFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesExplorerActions.validationFlagsFetchRequested),
      concatMap(({ signalId }) =>
        this.validationFlagsApiService.getValidationFlags(signalId).pipe(
          map(dto =>
            FilesExplorerActions.validationFlagsFetched({ signalId, validationFlags: validationFlagsFromDto(dto) })
          ),
          catchApiError(false, () => FilesExplorerActions.validationFlagsFetchfailed())
        )
      )
    );
  });

  flagDataValidationAsDirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesExplorerActions.flagSensorDataValidationAsDirtyRequested),
      concatMap(({ sensorId }) =>
        this.dataValidationSensorApiService.flagAsDirty(sensorId).pipe(
          map(() => FilesExplorerActions.noAction()),
          catchApiError(false, () => FilesExplorerActions.flagSensorAsDirtyFailed())
        )
      )
    );
  });

  flagPeakIdentificationAsDirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesExplorerActions.flagSensorPeakIdentificationAsDirtyRequested),
      concatMap(({ sensorId }) =>
        this.peakIdentificationSensorApiService.flagAsDirty(sensorId).pipe(
          map(() => FilesExplorerActions.noAction()),
          catchApiError(false, () => FilesExplorerActions.flagSensorAsDirtyFailed())
        )
      )
    );
  });

  flagTrackingAsDirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesExplorerActions.flagSensorTrackingAsDirtyRequested),
      concatMap(({ sensorId }) =>
        this.trajectoriesApiService.flagAsDirty(sensorId).pipe(
          map(() => FilesExplorerActions.noAction()),
          catchApiError(false, () => FilesExplorerActions.flagSensorAsDirtyFailed())
        )
      )
    );
  });

  dirtyFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilesExplorerActions.flagSensorAsDirtyFailed),
        tap(() =>
          this.errorSnackbar.open('Impossible to recompute data', 'Dismiss', {
            duration: 5000,
          })
        )
      );
    },
    { dispatch: false }
  );
}
