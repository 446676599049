import { Folder, FolderId, FolderStatus } from '../interface/folder.interface';
import { FoldersState } from '../store/folders.state';

export function updateFolderStatusById(
  state: FoldersState,
  folderId: FolderId,
  status: FolderStatus | undefined
): FoldersState {
  const existingFolder = state.flattenedFoldersTree.folders[folderId];

  if (!existingFolder) {
    return state;
  }

  return updateFolderStatus(state, existingFolder, status);
}

export function updateFolderStatus(
  state: FoldersState,
  folder: Folder,
  status: FolderStatus | undefined
): FoldersState {
  const existingFolder = state.flattenedFoldersTree.folders[folder.id];
  const newFolders = {
    ...state.flattenedFoldersTree.folders,
    [folder.id]: { ...existingFolder, name: folder.name, status },
  };

  return {
    ...state,
    flattenedFoldersTree: {
      ...state.flattenedFoldersTree,
      folders: newFolders,
    },
  };
}

export function updateFolder(state: FoldersState, folder: Folder): FoldersState {
  const newFolders = {
    ...state.flattenedFoldersTree.folders,
    [folder.id]: folder,
  };

  return {
    ...state,
    flattenedFoldersTree: {
      ...state.flattenedFoldersTree,
      folders: newFolders,
    },
  };
}
