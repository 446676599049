import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SignalsEffects } from './shared/store/signals.effects';
import { signalsFeature } from './shared/store/signals.feature';

@NgModule({
  imports: [StoreModule.forFeature(signalsFeature), EffectsModule.forFeature([SignalsEffects])],
})
export class SignalsModule {}
