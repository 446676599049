import { ChangeDetectionStrategy, Component } from '@angular/core';
import { signalsFeature } from '@features/signals/shared/store/signals.feature';
import { Store } from '@ngrx/store';

import { SignalReportSignalIdComponent } from './signal-report-signal-id/signal-report-signal-id.component';

@Component({
  selector: 'app-signal-report',
  standalone: true,
  imports: [SignalReportSignalIdComponent],
  templateUrl: './signal-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalReportComponent {
  signalId = this.store.selectSignal(signalsFeature.selectPageSignalId);

  constructor(private store: Store) {}
}
