import { Injectable } from '@angular/core';
import { SignalReportsApiService } from '@features/signal-report/shared/services/signal-reports-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { mapCyclesFromDto } from '../utils/cycles-mapping';
import { mapFusionFromDto } from '../utils/fusion-mapping';
import { mapPeakIdentificationFromDto } from '../utils/peak-identification-mappings';
import { PeakIdentificationActions } from './peak-identification.actions';

@Injectable()
export class PeakIdentificationEffects {
  constructor(
    private actions$: Actions,
    private signalReportsApi: SignalReportsApiService
  ) {}

  tryFetchSignalPeakIdentificationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.peakIdentificationFetchRequested),
      switchMap(({ signalId }) =>
        this.signalReportsApi.getPeakIdentification(signalId).pipe(
          map(peakIdentificationDto =>
            PeakIdentificationActions.peakIdentificationFetched({
              signalId,
              peakIdentification: mapPeakIdentificationFromDto(peakIdentificationDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? PeakIdentificationActions.peakIdentificationNotFound({ signalId })
              : PeakIdentificationActions.peakIdentificationFetchFailed({ signalId });
          })
        )
      )
    );
  });

  tryFetchCyclesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.cyclesFetchRequested),
      switchMap(({ signalId }) =>
        this.signalReportsApi.getCyclesData(signalId).pipe(
          map(cyclesDto =>
            PeakIdentificationActions.cyclesFetched({
              signalId,
              cycles: mapCyclesFromDto(cyclesDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? PeakIdentificationActions.cyclesNotFound({ signalId })
              : PeakIdentificationActions.cyclesFetchFailed({ signalId });
          })
        )
      )
    );
  });

  tryFetchFusionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PeakIdentificationActions.fusionFetchRequested),
      switchMap(({ signalId }) =>
        this.signalReportsApi.getFusionData(signalId).pipe(
          map(fusionDto =>
            PeakIdentificationActions.fusionFetched({
              signalId,
              fusion: mapFusionFromDto(fusionDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? PeakIdentificationActions.fusionNotFound({ signalId })
              : PeakIdentificationActions.fusionFetchFailed({ signalId });
          })
        )
      )
    );
  });
}
