<div class="mat-elevation-z2 h-full w-full overflow-clip rounded-xl">
  <div #tableDiv class="flex h-full w-full flex-col">
    <table
      mat-table
      matSort
      matSortDisableClear
      [matSortActive]="activeSortColumnId()"
      matSortDirection="asc"
      class="mat-elevation-z3 w-full select-none px-1">
      @for (column of columns(); track column.propertyName) {
        <ng-container [matColumnDef]="column.propertyName">
          <th #header_column mat-header-cell *matHeaderCellDef mat-sort-header class="py-2">{{ column.name }}</th>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="columnIds()" class="bg-gray-300"></tr>
    </table>

    @if (dataSource().filteredData.length !== 0) {
      <cdk-virtual-scroll-viewport
        #scroll_viewport
        [itemSize]="itemSize()"
        [minBufferPx]="minBufferPx()"
        [maxBufferPx]="maxBufferPx()"
        class="flex-grow">
        <div
          *cdkVirtualFor="let peak of dataSource(); let even = even; let last = last; let index = index"
          [class]="rowDependentClass(even, last)"
          [ngStyle]="dataDependentStyle()"
          class="items-center">
          @for (column of columns(); track $index) {
            <span class="w-full text-center text-sm">{{ column.formattedPropertyValue(peak) || 'Loading...' }}</span>
          }
        </div>
      </cdk-virtual-scroll-viewport>
    } @else {
      <div class="flex flex-grow items-center justify-center bg-gray-100">
        <span class="text-xl">No peak matches the current criteria.</span>
      </div>
    }
  </div>
</div>
