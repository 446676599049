import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { GlGraphComponent, GraphInput, Line, LineStyle, LineType, Scaling } from '@astrion-webtools/graph';
import { ShannonTestCriterion } from '@features/data-validation/shared/interface/sampling-test';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

@Component({
  selector: 'app-shannon-test-graph',
  standalone: true,
  imports: [GlGraphComponent],
  templateUrl: './shannon-test-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShannonTestGraphComponent {
  linearTest = input.required<ShannonTestCriterion>();
  dbTest = input.required<ShannonTestCriterion>();
  noiseTest = input.required<ShannonTestCriterion>();
  samplingFrequency = input.required<number>();

  dbScale = Scaling.dB;
  linearScale = Scaling.None;

  data: Signal<GraphInput> = computed(() => {
    const linearTest = this.linearTest();
    const noiseTest = this.noiseTest();
    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.samplingTests,
      curves: [
        {
          name: 'linear',
          color: '#0A6847',
          data: {
            indexedDb: {
              id: linearTest.spectrumAmplitudes,
              valuesField: 'amplitudes',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
        {
          name: 'noise',
          color: '#952020',
          data: {
            indexedDb: {
              id: noiseTest.spectrumAmplitudes,
              valuesField: 'amplitudes',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
      ],
    } as GraphInput;
  });

  lines: Signal<Line[]> = computed(() => {
    const linearTest = this.linearTest();
    const dbTest = this.dbTest();
    const noiseTest = this.noiseTest();

    return [
      {
        type: LineType.Horizontal,
        value: 10.0 * Math.log10(linearTest.result.amplitude),
        style: LineStyle.Dash,
        color: '#0A6847',
      } as Line,
      {
        type: LineType.Vertical,
        value: linearTest.result.frequency,
        style: LineStyle.Dash,
        color: '#0A6847',
        legend: `Linear: ${linearTest.result.frequency.toFixed(2)} Hz`,
      } as Line,
      {
        type: LineType.Horizontal,
        value: dbTest.result.amplitude,
        style: LineStyle.DashLong,
        color: '#3050ff',
      } as Line,
      {
        type: LineType.Vertical,
        value: dbTest.result.frequency,
        style: LineStyle.DashLong,
        color: '#3050ff',
        legend: `Decibel: ${dbTest.result.frequency.toFixed(2)} Hz`,
      } as Line,
      {
        type: LineType.Horizontal,
        value: 10.0 * Math.log10(noiseTest.result.amplitude),
        style: LineStyle.DashDot,
        color: '#952020',
      } as Line,
      {
        type: LineType.Vertical,
        value: noiseTest.result.frequency,
        style: LineStyle.DashDot,
        color: '#952020',
        legend: `Morpho: ${noiseTest.result.frequency.toFixed(2)} Hz`,
      } as Line,
    ];
  });
}
