import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, props } from '@ngrx/store';

import { SignalContent } from '../interface/signal-content';

export const SignalOverviewActions = createActionGroup({
  source: 'SignalOverview',
  events: {
    signalContentReset: props<{ signalId: AStrionSignalId | null }>(),

    signalContentFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalContentFetched: props<{ signalId: AStrionSignalId; signalContent: SignalContent }>(),
    signalContentNotFound: props<{ signalId: AStrionSignalId }>(),
    signalContentFetchFailed: props<{ signalId: AStrionSignalId }>(),

    signalBytesFetchRequested: props<{ signalId: AStrionSignalId; contentPath: string }>(),
    signalBytesFetched: props<{ signalId: AStrionSignalId; samples: IndexedDbRowId }>(),
    signalBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    signalBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),
  },
});
