import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

import { FooterInfoComponent } from './footer-info/footer-info.component';

@Component({
  selector: 'app-footer-button',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './footer-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterButtonComponent {
  canSeeFooter = this.store.selectSignal(authFeature.selectIsAstriisUser);

  constructor(
    private _sheet: MatBottomSheet,
    private store: Store
  ) {}

  openSheet() {
    this._sheet.open(FooterInfoComponent);
  }
}
