import { Injectable } from '@angular/core';
import { signalOverviewFeature } from '@features/signal-overview/shared/store/signal-overview.feature';
import { SignalReportsApiService } from '@features/signal-report/shared/services/signal-reports-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, of, switchMap } from 'rxjs';

import { mapDataValidationFromDto } from '../utils/data-validation-mapping';
import { mapFundamentalPeriodicityFromDto } from '../utils/fundamental-periodicity-mapping';
import { mapSamplingTestFromDto } from '../utils/sampling-test-mapping';
import { mapStationarityFromDto } from '../utils/stationarity-mapping';
import { mapTimeSaturationFromDto } from '../utils/time-saturation-mapping';
import { DataValidationActions } from './data-validation.actions';

@Injectable()
export class DataValidationEffects {
  constructor(
    private actions$: Actions,
    private signalReportsApi: SignalReportsApiService,
    private store: Store
  ) {}

  tryFetchSignalDataValidationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetchRequested),
      concatLatestFrom(() => this.store.select(signalOverviewFeature.selectSignalMetadata)),
      switchMap(([{ signalId }, reportSignalMetadata]) =>
        reportSignalMetadata === undefined
          ? of(DataValidationActions.signalSamplingTestFetchFailed({ signalId }))
          : this.signalReportsApi.getDataValidation(signalId, reportSignalMetadata.samplingFrequency).pipe(
              map(dataValidationDto =>
                DataValidationActions.dataValidationFetched({
                  signalId,
                  dataValidation: mapDataValidationFromDto(dataValidationDto, reportSignalMetadata.samplingFrequency),
                })
              ),
              catchApiError(false, apiError => {
                return apiError.httpError.status == 404
                  ? DataValidationActions.dataValidationNotFound({ signalId })
                  : DataValidationActions.dataValidationFetchFailed({ signalId });
              })
            )
      )
    );
  });

  tryFetchSignalTimeSaturationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.signalTimeSaturationTestFetchRequested),
      switchMap(({ signalId }) =>
        this.signalReportsApi.getTimeSaturationTestData(signalId).pipe(
          map(timeSaturationDto =>
            DataValidationActions.signalTimeSaturationTestFetched({
              signalId,
              saturationTest: mapTimeSaturationFromDto(timeSaturationDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.signalTimeSaturationTestNotFound({ signalId })
              : DataValidationActions.signalTimeSaturationTestFetchFailed({ signalId });
          })
        )
      )
    );
  });

  tryFetchSignalSamplingTestEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.signalSamplingTestFetchRequested),
      concatLatestFrom(() => this.store.select(signalOverviewFeature.selectSignalMetadata)),
      switchMap(([{ signalId }, reportSignalMetadata]) =>
        reportSignalMetadata === undefined
          ? of(DataValidationActions.signalSamplingTestFetchFailed({ signalId }))
          : this.signalReportsApi.getSamplingTestData(signalId, reportSignalMetadata.samplingFrequency).pipe(
              map(samplingTestDto =>
                DataValidationActions.signalSamplingTestFetched({
                  signalId,
                  samplingTest: mapSamplingTestFromDto(samplingTestDto),
                })
              ),
              catchApiError(false, apiError => {
                return apiError.httpError.status == 404
                  ? DataValidationActions.signalSamplingTestNotFound({ signalId })
                  : DataValidationActions.signalSamplingTestFetchFailed({ signalId });
              })
            )
      )
    );
  });

  tryFetchStationarityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.signalStationarityTestFetchRequested),
      concatLatestFrom(() => this.store.select(signalOverviewFeature.selectSignalMetadata)),
      switchMap(([{ signalId }, reportSignalMetadata]) =>
        reportSignalMetadata === null
          ? of(DataValidationActions.signalStationarityTestFetchFailed({ signalId }))
          : this.signalReportsApi.getStationarityTestData(signalId).pipe(
              map(stationarityDto =>
                DataValidationActions.signalStationarityTestFetched({
                  signalId,
                  stationarityTest: mapStationarityFromDto(stationarityDto),
                })
              ),
              catchApiError(false, apiError => {
                return apiError.httpError.status == 404
                  ? DataValidationActions.signalStationarityTestNotFound({ signalId })
                  : DataValidationActions.signalStationarityTestFetchFailed({ signalId });
              })
            )
      )
    );
  });

  tryFetchFundamentalPeriodicityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.signalFundamentalPeriodicityTestFetchRequested),
      concatLatestFrom(() => this.store.select(signalOverviewFeature.selectSignalMetadata)),
      switchMap(([{ signalId }, reportSignalMetadata]) =>
        reportSignalMetadata === undefined
          ? of(DataValidationActions.signalFundamentalPeriodicityTestFetchFailed({ signalId }))
          : this.signalReportsApi
              .getFundamentalPeriodicityTestData(signalId, reportSignalMetadata.samplingFrequency)
              .pipe(
                map(fundamentalPeriodicityDto =>
                  DataValidationActions.signalFundamentalPeriodicityTestFetched({
                    signalId,
                    periodicity: mapFundamentalPeriodicityFromDto(
                      fundamentalPeriodicityDto,
                      reportSignalMetadata.samplingFrequency
                    ),
                  })
                ),
                catchApiError(false, apiError => {
                  return apiError.httpError.status == 404
                    ? DataValidationActions.signalFundamentalPeriodicityTestNotFound({ signalId })
                    : DataValidationActions.signalFundamentalPeriodicityTestFetchFailed({ signalId });
                })
              )
      )
    );
  });
}
