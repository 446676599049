import { SignalMetadata, SignalMetadataDto } from '../interface/signal-metadata';

export function mapSignalMetadataFromDto(dto: SignalMetadataDto): SignalMetadata {
  const lowerFrequencyBoundRatio = dto.frequencyBand.lowerBoundPercentage / 100;
  const upperFrequencyBoundRatio = dto.frequencyBand.upperBoundPercentage / 100;

  return {
    ...dto,
    duration: dto.samplesCount / dto.samplingFrequency,
    frequencyBand: {
      ...dto.frequencyBand,
      lowerBound: dto.frequencyBand.theoreticalMax * lowerFrequencyBoundRatio,
      upperBound: dto.frequencyBand.theoreticalMax * upperFrequencyBoundRatio,
    },
    createdAt: new Date(dto.createdAt),
    date: dto.date ? new Date(dto.date) : undefined,
  };
}
