import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ReportAnchorDirective } from '@tools/directives/report-anchor.directive';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-report-page-base',
  standalone: true,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class ReportPageBaseComponent implements OnDestroy {
  @ViewChildren(ReportAnchorDirective, { read: ElementRef, emitDistinctChangesOnly: true })
  set childrenAnchors(children: QueryList<ElementRef>) {
    this.anchors$.next(children);
  }

  public anchors$ = new BehaviorSubject<QueryList<ElementRef>>(new QueryList());

  ngOnDestroy(): void {
    this.anchors$.complete();
  }
}
