import { Injectable } from '@angular/core';
import { HttpWrapperService } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { map, Observable } from 'rxjs';

import { FolderDto, FolderId, FolderPayload } from '../interface/folder.interface';

@Injectable({
  providedIn: 'root',
})
export class FoldersApiService {
  constructor(private api: HttpWrapperService) {}

  getAllFolders(): Observable<FolderDto[]> {
    return this.api.get<FolderDto[]>(`${environment.BASE_API_URL}/signal-management/folders`);
  }

  deleteFolder(folderId: FolderId, force: boolean): Observable<FolderId> {
    return this.api
      .delete<FolderDto>(`${environment.BASE_API_URL}/signal-management/folders/${folderId}?force=${force}`)
      .pipe(map((folder: FolderDto) => folder.id));
  }

  createFolder(folder: FolderPayload): Observable<FolderDto> {
    return this.api.post<FolderDto>(`${environment.BASE_API_URL}/signal-management/folders`, folder);
  }

  public updateFolder(folderId: FolderId, folder: FolderPayload): Observable<void> {
    return this.api.put<void>(`${environment.BASE_API_URL}/signal-management/folders/${folderId}`, folder);
  }
}
