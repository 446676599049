import { Project } from '../interfaces/project';
import { User } from '../interfaces/user';

export const featureName = 'auth';

export interface AuthState {
  isLoggedIn: boolean | null;
  projects: Project[];
  chosenProject: Project | null;
  user: User | null;
}

export const AUTH_INITIAL_STATE: AuthState = {
  isLoggedIn: null,
  projects: [],
  chosenProject: null,
  user: null,
};
