import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';
import { base64DataStringToArray, base64PngStringToUrl } from '@tools/utilities/base64-translation-utilities';

import { Stationarity, StationarityDto } from '../interface/stationarity';
import { ValidationStepStatus } from '../interface/validation-step-status';

export function mapStationarityFromDto(dto: StationarityDto): Stationarity {
  const stationarity: Stationarity = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (stationarity.progress !== ComputationStepProgress.Completed) {
    return stationarity;
  }

  const dtoData = dto.data!;

  const nonStationarityPercentage = dtoData.stationarityData.detectionIndex;
  const thresholds = dtoData.thresholds;

  const status =
    nonStationarityPercentage < thresholds.stationarity
      ? ValidationStepStatus.Success
      : nonStationarityPercentage < thresholds.mediumNonStationarity
        ? ValidationStepStatus.Warning
        : ValidationStepStatus.Failure;

  stationarity.data = {
    ...dtoData,
    status,
    spectrogram: {
      ...dtoData.spectrogram,
      base64EncodedImage: base64PngStringToUrl(dtoData.spectrogram.base64EncodedImage),
    },
    stationarityData: {
      ...dtoData.stationarityData,
      variance: base64DataStringToArray(dtoData.stationarityData.variance, 'Float64'),
      projection: base64DataStringToArray(dtoData.stationarityData.projection, 'Float64'),
      base64EncodedImage: base64PngStringToUrl(dtoData.stationarityData.base64EncodedImage),
    },
    thresholds: {
      ...dtoData.thresholds,
    },
  };

  return stationarity;
}
