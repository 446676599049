import { createSelector } from '@ngrx/store';
import { selectRouteParam } from '@store/router.selectors';

import { AStrionSignalId } from '../interface/astrion-signal.interface';

export const extraSelectors = () => ({
  selectPageSignalId: createSelector(
    selectRouteParam('id'),
    (signalId: string | undefined): AStrionSignalId | undefined =>
      signalId ? (signalId as AStrionSignalId) : undefined
  ),
});
