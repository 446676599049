import { ChangeDetectionStrategy, Component } from '@angular/core';
import environment from '@environments/environment.json';
import { MaterialModule } from '@modules/material.module';
import packageJson from '@root/../package.json';

@Component({
  selector: 'app-footer-info',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './footer-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterInfoComponent {
  environment = environment.ENV;
  baseUrl = environment.BASE_URL;
  version: string = packageJson.version;
}
