import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, Inject, signal, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CsvExportOptions } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-trajectory-graph-export-dialog',
  standalone: true,
  imports: [MaterialModule, FormsModule, CommonModule],
  templateUrl: './trajectory-graph-export-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrajectoryGraphExportDialogComponent {
  xTitle = signal<string>('Timestamp');
  separator = signal<string>(',');

  public exportButton = viewChild('exportButton', { read: HTMLButtonElement });

  constructor(
    private dialogRef: MatDialogRef<TrajectoryGraphExportDialogComponent, CsvExportOptions>,
    @Inject(MAT_DIALOG_DATA) public options: { xTitle: string | undefined }
  ) {
    if (options.xTitle) {
      this.xTitle.set(options.xTitle);
    }
    effect(() => {
      this.exportButton()?.focus();
    });
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  export() {
    const xTitle = this.xTitle();
    const separator = this.separator();
    this.dialogRef.close({ xTitle, separator, onlyHighlighted: true });
  }
}
