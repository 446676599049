import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ResultUnavailableComponent } from '@components/result-unavailable/result-unavailable.component';
import { FundamentalPeriodicityComponent } from '@features/data-validation/components/fundamental-periodicity/fundamental-periodicity.component';
import { ShannonSamplingTestComponent } from '@features/data-validation/components/shannon-sampling-test/shannon-sampling-test.component';
import { StationarityComponent } from '@features/data-validation/components/stationarity/stationarity.component';
import { TimeSaturationComponent } from '@features/data-validation/components/time-saturation/time-saturation.component';
import { dataValidationFeature } from '@features/data-validation/shared/store/data-validation.feature';
import { signalOverviewFeature } from '@features/signal-overview/shared/store/signal-overview.feature';
import { ReportPageBaseComponent } from '@features/signal-report/components/report-page-base/report-page-base';
import { ReportAnchorTitleDirective } from '@features/signal-report/shared/directives/report-anchor-title.directive';
import {
  computationCompleted,
  ComputationStep,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { REPORT_NAMES } from '@features/signal-report/shared/store/report-pages';
import { Store } from '@ngrx/store';
import { ReportAnchorDirective } from '@tools/directives/report-anchor.directive';

@Component({
  selector: 'app-report-data-validation',
  standalone: true,
  imports: [
    ReportAnchorDirective,
    TimeSaturationComponent,
    ShannonSamplingTestComponent,
    StationarityComponent,
    FundamentalPeriodicityComponent,
    ReportAnchorTitleDirective,
    ResultUnavailableComponent,
  ],
  templateUrl: './report-data-validation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDataValidationComponent extends ReportPageBaseComponent {
  REPORT_NAMES = REPORT_NAMES;

  signalData = this.store.selectSignal(signalOverviewFeature.selectSignalData);

  samplesId = computed(() => this.signalData()?.content.data);
  metadata = computed(() => this.signalData()?.metadata);

  public ready = (step: ComputationStep): boolean => computationCompleted(step);

  public timeSaturation = this.store.selectSignal(dataValidationFeature.selectSaturationTest);
  public shannonTest = this.store.selectSignal(dataValidationFeature.selectSamplingTest);
  public stationarity = this.store.selectSignal(dataValidationFeature.selectStationarityTest);

  constructor(private store: Store) {
    super();
  }
}
