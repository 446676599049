export enum LoadingState {
  Unknown,
  Loading,
  Loaded,
  NotFound,
  Error,
}

export interface LoadableItem {
  loadingState: LoadingState;
}

export function loaded(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.Loaded;
}

export function loading(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.Loading;
}

export function loadingError(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.Error;
}

export function notFound(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.NotFound;
}
