import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { ValidationStepStatus } from './validation-step-status';

export interface Color {
  a: number;
  r: number;
  g: number;
  b: number;
}

export interface StationaritySpectrogramDto {
  base64EncodedImage: string;
  colorMap: Color[];
  segmentsCount: number;
  segmentLowerBound: number;
  segmentUpperBound: number;
  psdMin: number;
  psdMax: number;
}

export interface StationaritySpectrogram {
  base64EncodedImage: string;
  colorMap: Color[];
  segmentsCount: number;
  segmentLowerBound: number;
  segmentUpperBound: number;
  psdMin: number;
  psdMax: number;
}

export interface StationarityResultDto {
  kappa: number;
  variance: string;
  detectionIndex: number;
  base64EncodedImage: string;
  colorMap: Color[];
  minValue: number;
  maxValue: number;
  projection: string;
}

export interface StationarityResult {
  kappa: number;
  variance: number[];
  detectionIndex: number;
  base64EncodedImage: string;
  colorMap: Color[];
  minValue: number;
  maxValue: number;
  projection: number[];
}

export interface StationarityThresholds {
  stationarity: number;
  lowNonStationarity: number;
  mediumNonStationarity: number;
}

export interface StationarityDataDto {
  spectrogram: StationaritySpectrogramDto;
  stationarityData: StationarityResultDto;
  thresholds: StationarityThresholds;
  minFrequency: number;
  maxFrequency: number;
  duration: number;
}

export interface StationarityDto {
  progress: string;
  data?: StationarityDataDto;
}

export interface StationarityData {
  status: ValidationStepStatus;
  spectrogram: StationaritySpectrogram;
  stationarityData: StationarityResult;
  thresholds: StationarityThresholds;
  minFrequency: number;
  maxFrequency: number;
  duration: number;
}

export interface Stationarity {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: StationarityData;
}

export const defaultStationarityTestWithLoadingState = (
  loadingState: LoadingState = LoadingState.Unknown
): Stationarity => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
