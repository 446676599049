<table class="w-full text-left">
  <tbody>
    <tr class="border-b">
      <th class="px-6 py-4" scope="row">Environment</th>
      <td class="px-6 py-4">{{ environment }}</td>
    </tr>
    <tr class="border-b">
      <th class="px-6 py-4" scope="row">Base url</th>
      <td class="px-6 py-4">{{ baseUrl }}</td>
    </tr>
    <tr>
      <th class="px-6 py-4" scope="row">Version</th>
      <td class="px-6 py-4">{{ version }}</td>
    </tr>
  </tbody>
</table>
