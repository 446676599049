<div class="flex w-full flex-col">
  <app-status-banner class="w-full flex-none" [status]="timeSaturation().status" [message]="statusMessage()" />

  <lib-gl-graph
    class="mx-2 h-[60vh]"
    [data]="data()"
    id="time-saturation-graph"
    color="#A2B29F"
    xTitle="Time (ms)"
    yTitle="Ampltitude"
    [highlightIndices]="saturationIndices()" />
</div>
