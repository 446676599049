import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';

@Component({
  selector: 'app-name-edition-input',
  templateUrl: './name-edition-input.component.html',
  imports: [CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameEditionInputComponent {
  initialValue = input<string>('');
  isValidFun = input<(name: string) => boolean>(() => true);

  valueSubmitted = output<string>();
  editionCanceled = output();

  private selfRef = viewChild.required<ElementRef<HTMLInputElement>>('self');

  value = signal<string>('');
  isValid = computed(() => {
    return this.isValidFun()(this.value());
  });

  constructor() {
    effect(
      () => {
        const selfRef = this.selfRef();
        if (selfRef === undefined) {
          return;
        }

        this.value.set(this.initialValue());
        selfRef.nativeElement.focus();
        selfRef.nativeElement.select();
      },
      { allowSignalWrites: true }
    );
  }

  public handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.isValid()) {
        this.valueSubmitted.emit(this.value());
      }
    } else if (event.key === 'Escape') {
      this.editionCanceled.emit();
    } else {
      this.value.set(this.selfRef().nativeElement.value);
    }
  }

  public handleFocusLost() {
    this.editionCanceled.emit();
  }
}
