<div class="flex w-full flex-col">
  <app-status-banner class="w-full flex-none" [status]="shannonTest().status" [message]="statusMessage()">
    <table class="mx-auto my-2 table-auto">
      <thead>
        <tr>
          <th></th>
          <th class="px-6 py-3">Linear test</th>
          <th class="px-6 py-3">dB test</th>
          <th class="px-6 py-3">Morpho test</th>
          <th class="px-6 py-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Sampling quality</th>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="linearSuccess()" />
          </td>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="dbSuccess()" />
          </td>
          <td class="px-6 py-3 text-center"></td>
          <th class="px-6 py-3" [class]="signalWellSampled() ? 'text-green-700' : 'text-red-700'">
            {{ signalWellSampled() ? 'Well sampled' : 'Badly sampled' }}
          </th>
        </tr>
        <tr>
          <th>Anti-aliasing filter detection</th>
          <td class="px-6 py-3 text-center"></td>
          <td class="px-6 py-3 text-center"></td>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="noiseSuccess()" />
          </td>
          <th class="px-6 py-3" [class]="noiseSuccess() ? 'text-green-700' : 'text-red-700'">
            {{ noiseSuccess() ? 'Filter detected' : 'No filter' }}
          </th>
        </tr>
        <tr>
          <th>Aliasing detection</th>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="linearSuccess()" />
          </td>
          <td class="px-6 py-3 text-center">
            <app-shannon-table-check [success]="dbSuccess()" />
          </td>
          <td class="px-6 py-3 text-center">
            @if (!signalWellSampled()) {
              <app-shannon-table-check [success]="noiseSuccess()" />
            }
          </td>
          <th class="px-6 py-3" [class]="aliasingDetectionColor()">{{ aliasingDetectionShort() }}</th>
        </tr>
      </tbody>
    </table>
  </app-status-banner>

  <app-shannon-test-graph
    class="h-[63vh] flex-grow"
    [linearTest]="shannonTest().linearTestSummary"
    [samplingFrequency]="samplingFrequency()"
    [dbTest]="shannonTest().dbTestSummary"
    [noiseTest]="shannonTest().noiseTestSummary" />
</div>
