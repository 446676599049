import { Component, computed, effect, Input, input, output, signal, viewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDrawer } from '@angular/material/sidenav';
import { ColorGenerationService, CsvExportOptions, GlGraphComponent, GraphInput } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { intersectKeysFromImmutableMap } from '@tools/utilities/record-set-utilities';
import { firstValueFrom } from 'rxjs';

import { CurveLegend, CurveName, GroupName, GroupState, GroupStateMap } from './trajectory-graph.interface';
import { TrajectoryGraphExportDialogComponent } from './trajectory-graph-export-dialog/trajectory-graph-export-dialog.component';
import { TrajectoryGraphLegendComponent } from './trajectory-graph-legend/trajectory-graph-legend.component';
import { TrajectoryGraphSettingsComponent } from './trajectory-graph-settings/trajectory-graph-settings.component';
import { dataMerge } from './utils/data-merge';

// We completely ignore areas here
@Component({
  selector: 'app-trajectory-graph',
  standalone: true,
  imports: [GlGraphComponent, MaterialModule, TrajectoryGraphSettingsComponent, TrajectoryGraphLegendComponent],
  templateUrl: './trajectory-graph.component.html',
  styleUrl: './trajectory-graph.component.scss',
})
export class TrajectoryGraphComponent {
  id = input.required<string>();
  name = input.required<string>();
  xTitle = input.required<string>();
  yTitle = input.required<string>();
  graphHeight = input('70vh');

  @Input() set data(next: GraphInput | undefined) {
    this._data.update(prev => dataMerge(prev, next, this._defaultColors));
  }
  private _data = signal<GraphInput>({});

  removeCurve = output<string>();
  delete = output();

  expanded = signal(true);

  graph = viewChild.required<GlGraphComponent>('graph');
  drawer = viewChild.required<MatDrawer>('drawer');
  panel = viewChild.required<MatExpansionPanel>('panel');
  settings = viewChild.required<TrajectoryGraphSettingsComponent>('settings');

  focusedCurve = signal<string | undefined>(undefined);
  groupStates = signal<GroupStateMap>(new Map());

  groupIdx = 1;

  private _defaultColors: string[];

  curvesGrouped(curveNames: CurveName[]) {
    const curvesSet = new Set<CurveName>(curveNames);
    const groupName = `${this.groupIdx++}`.padStart(4, '0');
    this._data.update(data => ({
      ...data,
      curves: data.curves?.map(curve => ({
        ...curve,
        axisGroup: curvesSet.has(curve.name) ? groupName : curve.axisGroup,
      })),
    }));
  }

  curvesUngrouped(curveNames: CurveName[]) {
    const curvesSet = new Set<CurveName>(curveNames);
    this._data.update(data => ({
      ...data,
      curves: data.curves?.map(curve => ({
        ...curve,
        axisGroup: curvesSet.has(curve.name) ? undefined : curve.axisGroup,
      })),
    }));
  }

  groups = computed<GroupName[]>(() => {
    const data = this._data();
    if (!data?.curves) {
      return [];
    }
    return Array.from(
      new Set(data.curves.map(curve => curve.axisGroup).filter(group => group !== undefined) as GroupName[])
    );
  });

  visibleGroups = computed<Set<GroupName | undefined>>(() => {
    const groups = this.groups();
    const groupStates = this.groupStates();
    const hasSingles = Array.from(groupStates.values()).some(groupToggle => groupToggle === GroupState.Single);
    return new Set<GroupName | undefined>(
      [undefined, ...groups].filter(groupName => {
        const toggle: GroupState | undefined = groupStates.get(groupName);
        return hasSingles ? toggle === GroupState.Single : toggle !== GroupState.Hide;
      })
    );
  });

  visibleCurves = computed<Set<CurveName>>(() => {
    const data = this._data();
    const visibleGroups = this.visibleGroups();

    return new Set(data?.curves?.filter(curve => visibleGroups.has(curve.axisGroup))?.map(curve => curve.name) ?? []);
  });

  completedData = computed<GraphInput>(() => {
    const data = this._data();
    const focusedCurve = this.focusedCurve();
    const visibleCurves = this.visibleCurves();

    return {
      ...data,
      curves: data.curves?.map(curve => ({
        ...curve,
        drawPoints: !focusedCurve || focusedCurve === curve.name,
        display: visibleCurves.has(curve.name),
      })),
    } as GraphInput;
  });

  legend = computed<CurveLegend[]>(
    () =>
      this.completedData()
        .curves?.map(curve => ({
          name: curve.name,
          color: curve.color!,
          axisGroup: curve.axisGroup,
          display: curve.display ?? true,
        }))
        ?.sort((a, b) => a.name.localeCompare(b.name)) ?? []
  );

  showGroupAxis = computed(() => {
    const completedData = this.completedData();

    if (!completedData.curves || completedData.curves.length <= 0) {
      return undefined;
    }

    const displayedCurves = completedData.curves.filter(curve => curve.display ?? true);
    if (displayedCurves.length <= 0) {
      return undefined;
    }

    return displayedCurves
      .filter(curve => curve.display ?? true)
      .reduce(
        (prev, cur) => {
          return prev !== undefined && prev === (cur.axisGroup ?? null) ? prev : undefined;
        },
        (displayedCurves[0].axisGroup ?? null) as string | undefined | null
      );
  });

  hasData = computed(() => {
    const completedData = this.completedData();
    return completedData && completedData.curves?.length;
  });

  constructor(
    private exportDialog: MatDialog,
    colorGeneration: ColorGenerationService
  ) {
    this._defaultColors = colorGeneration.generateColors(64);
    effect(
      () => {
        const groups = new Set(this.groups());
        // remove obsolete groups
        this.groupStates.update(groupStates => intersectKeysFromImmutableMap(groupStates, groups));
      },
      { allowSignalWrites: true }
    );
  }

  onColorSelected({ name, color }: { name: CurveName; color: string }) {
    this._data.update(data => ({
      ...data,
      curves: data.curves?.map(curve => (curve.name === name ? { ...curve, color } : curve)),
    }));
  }

  onSettings(event: Event) {
    this.drawer().toggle();
    event.stopPropagation();
  }

  onDelete(event: Event) {
    this.delete.emit();
    event.stopPropagation();
  }

  onTogglePanel(event: Event) {
    this.expanded.update(e => !e);
    event.stopPropagation();
  }

  onDownload = async (event: Event) => {
    event.stopPropagation();
    const options: CsvExportOptions = await firstValueFrom(
      this.exportDialog
        .open(TrajectoryGraphExportDialogComponent, { restoreFocus: false, data: { xTitle: this.xTitle() } })
        .afterClosed()
    );
    if (options) {
      const csvText = await this.graph().exportCsv(options);
      download('export.csv', csvText);
    }
  };
}

const download = (filename: string, text: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
