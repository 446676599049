import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SignalReportEffects } from './shared/store/signal-report.effects';
import { signalReportFeature } from './shared/store/signal-report.feature';
import { SIGNAL_REPORT_ROUTES } from './signal-report.routes';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(SIGNAL_REPORT_ROUTES),
    StoreModule.forFeature(signalReportFeature),
    EffectsModule.forFeature([SignalReportEffects]),
  ],
})
export class SignalReportModule {}
