<h1 mat-dialog-title>Graph export</h1>
<mat-dialog-content>
  <div class="flex flex-col">
    <mat-form-field>
      <mat-label>X title</mat-label>
      <input matInput [(ngModel)]="xTitle" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Separator</mat-label>
      <input matInput [(ngModel)]="separator" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="error-button" (click)="cancel()"><mat-icon>close</mat-icon>Cancel</button>
  <button mat-raised-button class="primary-button" (click)="export()" #exportButton>
    <mat-icon>download</mat-icon>Export
  </button>
</mat-dialog-actions>
