<div class="flex w-full flex-col">
  <lib-gl-graph
    class="mx-2 h-[60vh]"
    [data]="fusionData()"
    xTitle="Frequency (Hz)"
    [yTitle]="yTitle()"
    id="fusion-graph"
    [yScale]="yScale()"
    [lines]="fusionPeakLines()"
    [syncWithVerticalLines]="true" />

  <app-peaks-graph-interactive-legend
    class="mx-8"
    [dbScale]="dbScale()"
    (dbScaleChange)="dbScale.set($event)"
    [curveOptions]="curveOptions"
    [peaks]="fusionPeaks()"
    (peaksPredicateChanged)="peaksFilter.set($event)" />

  <app-peaks-table
    class="mx-8 mt-5 h-[60vh]"
    [peaks]="fusionPeaks()"
    [filterPredicate]="peaksFilter()"
    [additionalColumns]="peaksTableAdditionalColumns()" />
</div>
