@if (page(); as page) {
  {{ page.name }}
  @if (page.notifications && page.notifications.count > 0) {
    <span
      class="ml-3 rounded-full bg-red-400 px-2 py-1 text-xs text-white"
      [class]="page.notifications.hasError ? 'bg-red-400' : 'bg-yellow-500'">
      {{ page.notifications.count }}
    </span>
  }
}
