<h1 mat-dialog-title>{{ options.title }}</h1>
<div mat-dialog-content>
  @if (options.question) {
    <p>{{ options.question }}</p>
  }
  <mat-form-field>
    <input
      (keyup.enter)="onEnter()"
      (keyup)="pristine = false"
      #input
      matInput
      [placeholder]="options.placeholder"
      [(ngModel)]="options.answer" />
  </mat-form-field>
  @if (!pristine && !isValid()) {
    <div>Invalid input.</div>
  }
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <span class="grow"></span>
  <button mat-raised-button class="primary-button" [disabled]="!isValid()" [mat-dialog-close]="options.answer">
    OK
  </button>
</div>
