import { displayNumber, LineType, SegmentedLine } from '@astrion-webtools/graph';

import { Peak } from '../interface/cycles';
import { PMC } from '../interface/pfa-pmc-thresholds';
import { getPeakBottomContentStyle, getPeakTopContentStyle, PeakTypes } from './peak-style';

export const peakTypeToString = (type: string) => {
  switch (type) {
    case PeakTypes.SW:
      return 'Sine Wave';
    case PeakTypes.NB:
      return 'Narrow Band';
    case PeakTypes.SWNoiseDoubt:
      return 'SW / Noise Doubt';
    case PeakTypes.NBNoiseDoubt:
      return 'NB / Noise Doubt';
    case PeakTypes.Noise:
      return 'Noise';
    default:
      return 'Unknown';
  }
};

export const peakPmcToString = (pmc: PMC) => {
  switch (pmc) {
    case PMC.High:
      return 'High';
    case PMC.Low:
      return 'Low';
    case PMC.VeryLow:
      return 'Very low';
    default:
      return 'Unknown';
  }
};

export const peakToLine = (peak: Peak): SegmentedLine => {
  const peakBottomStyle = getPeakBottomContentStyle(peak.type);
  const peakTopStyle = getPeakTopContentStyle(peak.pmc);

  const frequency = displayNumber(peak.frequency);
  const psd = displayNumber(10 * Math.log10(peak.psd));

  return {
    type: LineType.Vertical,
    value: peak.frequency,
    width: 2,
    legendOnHover: true,
    legend: [
      {
        text: `Peak ${peak.index}: ${frequency} Hz`,
        color: 'black',
      },
      {
        text: `PMC: ${peakPmcToString(peak.pmc)}`,
        color: peakTopStyle.color,
      },
      {
        text: `PSD: ${psd} dB`,
        color: 'blue',
      },
      {
        text: `Class: ${peakTypeToString(peak.type)}`,
        color: peakBottomStyle.color,
      },
    ],
    segments: [
      {
        color: peakBottomStyle.color,
        style: peakBottomStyle.dashingPattern,
        max: peak.meanField,
      },
      {
        color: peakTopStyle.color,
        style: peakTopStyle.dashingPattern,
        max: peak.psd,
      },
    ],
  } as SegmentedLine;
};
