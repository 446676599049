import { LoadableItem, loaded } from '@shared/interfaces/loading-state';

export enum ComputationStepProgress {
  Unknown,
  Completed,
  InProgress,
}

export interface ComputationStep extends LoadableItem {
  progress: ComputationStepProgress;
}

export function isComputationStep(loadableItem: LoadableItem): loadableItem is ComputationStep {
  return (loadableItem as ComputationStep).progress !== undefined;
}

export function computationCompleted(computationStep: ComputationStep): boolean {
  return loaded(computationStep) && computationStep.progress === ComputationStepProgress.Completed;
}

export function computationInProgress(computationStep: ComputationStep): boolean {
  return loaded(computationStep) && computationStep.progress === ComputationStepProgress.InProgress;
}

export const stringToComputationStepProgress = (progress: string): ComputationStepProgress => {
  switch (progress) {
    case 'Completed':
      return ComputationStepProgress.Completed;
    case 'InProgress':
      return ComputationStepProgress.InProgress;
    default:
      return ComputationStepProgress.Unknown;
  }
};
