<table class="overflow-x-hide block w-full rounded border text-left">
  <thead>
    <tr class="bg-slate-500 text-center uppercase text-white">
      <th colspan="2" class="px-6 py-4">Metadata</th>
    </tr>
  </thead>
  <tbody>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Sampling frequency</th>
      <td class="px-6 py-4 text-right">{{ metadata().samplingFrequency }} Hz</td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Duration</th>
      <td class="px-6 py-4 text-right">{{ metadata().duration }} s</td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Samples count</th>
      <td class="px-6 py-4 text-right">{{ metadata().samplesCount }}</td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Mean amplitude</th>
      <td class="px-6 py-4 text-right">{{ metadata().mean?.toFixed(4) }}</td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Rotation speed</th>
      <td class="px-6 py-4 text-right">{{ metadata().rpm ? metadata().rpm + ' RPM' : 'N/A' }}</td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Frequency band</th>
      <td class="px-6 py-4 text-right">
        {{ metadata().frequencyBand.lowerBound }} - {{ metadata().frequencyBand.upperBound }} Hz ({{
          metadata().frequencyBand.lowerBoundPercentage
        }}
        - {{ metadata().frequencyBand.upperBoundPercentage }}%)
      </td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Data type</th>
      <td class="px-6 py-4 text-right">{{ metadata().dataType }}</td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Date</th>
      <td class="px-6 py-4 text-right">{{ metadata().date | date: 'YYYY/MM/dd HH:mm:ss' }}</td>
    </tr>
    <tr class="border-b odd:bg-slate-50 even:bg-slate-100">
      <th class="px-6 py-4">Description</th>
      <td class="px-6 py-4 text-right">
        {{ metadata().description }}
      </td>
    </tr>
  </tbody>
</table>
