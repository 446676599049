import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { FoldersBarComponent } from '@features/folders/components/folders-bar/folders-bar.component';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { ReportContentContainerComponent } from '@features/signal-report/components/report-content-container/report-content-container.component';
import { ReportNavigationComponent } from '@features/signal-report/components/report-navigation/report-navigation.component';
import { ReportPages } from '@features/signal-report/shared/interface/report-page';
import { SignalReportActions } from '@features/signal-report/shared/store/signal-report.actions';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

@Component({
  selector: 'app-signal-report-display',
  standalone: true,
  imports: [MaterialModule, ReportNavigationComponent, ReportContentContainerComponent, FoldersBarComponent],
  templateUrl: './signal-report-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalReportDisplayComponent {
  signalId = input.required<AStrionSignalId>();
  reportTitle = input.required<string>();
  signalLoadingState = input.required<LoadingState>();
  pages = input.required<ReportPages>();
  folders = input<Folder[] | null>(null);

  loading = computed(() => this.signalLoadingState() === LoadingState.Loading);
  loaded = computed(() => this.signalLoadingState() === LoadingState.Loaded);
  error = computed(() => this.signalLoadingState() === LoadingState.Error);
  notFound = computed(() => this.signalLoadingState() === LoadingState.NotFound);

  downloadClicked = output();
  folderClicked = output<Folder>();

  scrolledAnchor = signal<string | undefined>(undefined);

  constructor(private store: Store) {}

  onRefreshClicked() {
    const signalId = this.signalId();
    this.store.dispatch(SignalReportActions.signalBaseDataFetchRequested({ signalId }));
  }
}
