import { Injectable, Signal } from '@angular/core';
import { GraphCurve, GraphInput } from '@astrion-webtools/graph';
import { Store } from '@ngrx/store';

import { SensorGraphsActions } from '../store/sensor-graphs.actions';
import { sensorGraphsFeature } from '../store/sensor-graphs.feature';

@Injectable({
  providedIn: 'root',
})
export class SensorGraphsService {
  constructor(private store: Store) {}

  addCurve(curveId: string, curve: GraphCurve) {
    this.store.dispatch(SensorGraphsActions.addCurve({ curveId, curve }));
  }

  clearCurves() {
    this.store.dispatch(SensorGraphsActions.clearCurves());
  }

  removeCurve(curveId: string) {
    this.store.dispatch(SensorGraphsActions.removeCurve({ curveId }));
  }

  get graphInputSignal(): Signal<GraphInput> {
    return this.store.selectSignal(sensorGraphsFeature.selectGraphInput);
  }

  get curveNameToIdSignal(): Signal<Record<string, string>> {
    return this.store.selectSignal(sensorGraphsFeature.selectCurveNameToId);
  }
}
