<div class="flex h-full flex-col" #container>
  @if (loading()) {
    <mat-progress-bar class="flex-none" mode="indeterminate" />
  }
  <app-scrollable-bordered-container
    class="flex-grow"
    id="report-content"
    (contentScrolled)="onScroll()"
    [fadeBottom]="true"
    [fadeTop]="true">
    <router-outlet (activate)="onActivate($event)" />
  </app-scrollable-bordered-container>
</div>
