import { Progress } from '@app-types/progress.interface';

import { AStrionSignalUploadResult } from '../interface/astrion-signal-upload-result.interface';

export const name = 'signals';

export interface SignalsState {
  uploadCancel: boolean;
  uploadOver: boolean;
  uploadProgress: Progress | null;
  lastUpload: AStrionSignalUploadResult[];
}

export const SIGNALS_INITIAL_STATE: SignalsState = {
  uploadCancel: false,
  uploadOver: false,
  uploadProgress: null,
  lastUpload: [],
};
