import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { createSelector } from '@ngrx/store';

import { Project } from '../interfaces/project';
import { AuthState, featureName } from './auth.state';

export const extraSelectors = ({ selectChosenProject }: BaseSelectors<typeof featureName, AuthState>) => {
  const selectIsAstriisUser = createSelector(
    selectChosenProject,
    (chosenProject: Project | null): boolean => !!chosenProject && !!chosenProject.roles?.find(r => r == 'astriis-user')
  );

  const selectCanRecompute = createSelector(selectIsAstriisUser, (isAstriisUser: boolean): boolean => isAstriisUser);

  return { selectIsAstriisUser, selectCanRecompute };
};
