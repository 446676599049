@if (page(); as page) {
  <mat-nav-list [disableRipple]="true">
    @for (anchor of page.anchors; track anchor.name; let first = $first) {
      <mat-list-item class="pl-4" (click)="forceNavigateToFragment(page.path, anchor.fragment)">
        <mat-icon matListItemIcon>{{ anchor.icon }}</mat-icon>

        <span matListItemTitle class="flex flex-row justify-between" [style.font-weight]="fontStyle(anchor, first)">
          {{ anchor.name }}

          @if (loading(anchor)) {
            <mat-progress-spinner class="ml-5 h-[24px] w-[24px] align-middle" mode="indeterminate" />
          } @else if (error(anchor)) {
            <mat-icon [style]="{ color: '#fe621d' }">error_outline</mat-icon>
          } @else if (notFound(anchor)) {
            <mat-icon>help_outline</mat-icon>
          } @else if (anchor.secondaryIcon) {
            <mat-icon [style]="{ color: anchor.secondaryIconColor }">{{ anchor.secondaryIcon }}</mat-icon>
          }
        </span>
      </mat-list-item>
    }
  </mat-nav-list>
}
