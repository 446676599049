import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TrajectoriesApiService } from './services/trajectories-api/trajectories-api.service';
import { TrajectoryService } from './services/trajectory/trajectory.service';
import { TrajectoryRealtimeService } from './shared/realtime/trajectory-realtime.service';
import { SensorTrajectoriesEffect } from './shared/store/sensor-trajectories.effect';
import { sensorTrajectoriesFeature } from './shared/store/sensor-trajectories.feature';

@NgModule({
  imports: [
    StoreModule.forFeature(sensorTrajectoriesFeature),
    EffectsModule.forFeature([SensorTrajectoriesEffect]),
    RealtimeModule.forFeature(TrajectoryRealtimeService),
  ],
  providers: [TrajectoriesApiService, TrajectoryRealtimeService, TrajectoryService],
})
export class SensorTrajectoriesModule {}
