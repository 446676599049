import { createReducer, on } from '@ngrx/store';

import { ConnectionStatus } from '../interfaces/connection-status';
import { RealtimeActions } from './realtime.actions';
import { REALTIME_INITIAL_STATE, RealtimeState } from './realtime.state';

export const reducer = createReducer(
  REALTIME_INITIAL_STATE,
  on(
    RealtimeActions.connectionStatusChanged,
    (state: RealtimeState, { status }: { status: ConnectionStatus }): RealtimeState => ({
      ...state,
      connectionStatus: status,
    })
  )
);
