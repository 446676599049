import { GraphCurve } from '@astrion-webtools/graph';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SensorGraphsActions = createActionGroup({
  source: 'SensorGraphs',
  events: {
    clearCurves: emptyProps(),
    removeCurve: props<{ curveId: string }>(),
    addCurve: props<{ curveId: string; curve: GraphCurve }>(),
  },
});
