import { ChangeDetectionStrategy, Component, computed, ElementRef, input, Signal, viewChild } from '@angular/core';
import {
  getValidationStepStatusColor,
  ValidationStepStatus,
} from '@features/data-validation/shared/interface/validation-step-status';
import { MaterialModule } from '@modules/material.module';

interface SignalBanner {
  backgroundColor: string;
  buttonBackgroundColor: string;
  buttonIcon: string;
  buttonIconColor: string;
  buttonText: string;
  buttonTextColor: string;
  messageColor: string;
}

@Component({
  selector: 'app-status-banner',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './status-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBannerComponent {
  status = input.required<ValidationStepStatus>();
  message = input.required<string>();

  public panelExpanded: boolean = false;

  public additionalContent = viewChild('additionalContent', { read: ElementRef });

  public hasAdditionalContent = computed(() => {
    const additionalContent = this.additionalContent();

    return (
      additionalContent !== undefined &&
      (additionalContent.nativeElement.children.length !== 0 ||
        (additionalContent.nativeElement.textContent !== null &&
          additionalContent.nativeElement.textContent.length !== 0))
    );
  });

  public togglePanel(): void {
    if (!this.hasAdditionalContent()) {
      return;
    }

    this.panelExpanded = !this.panelExpanded;
  }

  bannerTheme: Signal<SignalBanner> = computed(() => {
    const status = this.status();
    const backgroundColor = getValidationStepStatusColor(status);
    switch (status) {
      case ValidationStepStatus.Success: {
        return {
          backgroundColor,
          buttonBackgroundColor: 'bg-slate-200',
          buttonIconColor: '#ffffff',
          buttonIcon: 'check_box',
          buttonText: 'Success',
          buttonTextColor: 'text-white',
          messageColor: 'text-white',
        };
      }
      case ValidationStepStatus.Warning: {
        return {
          backgroundColor,
          buttonBackgroundColor: 'bg-slate-400',
          buttonIconColor: '#444444',
          buttonIcon: 'warning',
          buttonText: 'Warning',
          buttonTextColor: 'text-neutral-700',
          messageColor: 'text-neutral-700',
        };
      }
      case ValidationStepStatus.Failure: {
        return {
          backgroundColor,
          buttonBackgroundColor: 'bg-slate-300',
          buttonIconColor: '#ffffff',
          buttonIcon: 'report',
          buttonText: 'Error',
          buttonTextColor: 'text-white',
          messageColor: 'text-white',
        };
      }
      case ValidationStepStatus.Neutral: {
        return {
          backgroundColor,
          buttonBackgroundColor: 'bg-slate-600',
          buttonIconColor: '#444444',
          buttonIcon: 'info_outline',
          buttonText: 'Info',
          buttonTextColor: 'text-neutral-700',
          messageColor: 'text-neutral-700',
        };
      }
      default: {
        return {
          backgroundColor,
          buttonBackgroundColor: 'bg-neutral-500',
          buttonIconColor: '#444444',
          buttonIcon: 'help_outline',
          buttonText: 'Unknown',
          buttonTextColor: 'text-neutral-700',
          messageColor: 'text-neutral-700',
        };
      }
    }
  });
}
