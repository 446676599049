export function base64PngStringToUrl(base64EncodedImage: string): string {
  return 'data:image/png;base64,' + base64EncodedImage;
}

export type Base64EncodedDataType = 'Int32' | 'Float32' | 'Float64';

export function base64DataStringToArray(base64EncodedData: string, dataType: Base64EncodedDataType): number[] {
  const binaryString = atob(base64EncodedData);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  switch (dataType) {
    case 'Int32':
      return Array.from(new Int32Array(bytes.buffer));
    case 'Float32':
      return Array.from(new Float32Array(bytes.buffer));
    case 'Float64':
      return Array.from(new Float64Array(bytes.buffer));
  }
}
