import { CommonModule } from '@angular/common';
import { Component, computed, effect, ElementRef, input, viewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MaterialModule } from '@modules/material.module';
import { ImageService } from '@services/image.service';

@Component({
  selector: 'app-trend-miniature',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  providers: [ImageService],
  templateUrl: './trend-miniature.component.html',
  styles: [
    '.frequency-trend {filter: brightness(0) saturate(100%) invert(64%) sepia(86%) saturate(1173%) hue-rotate(281deg) brightness(103%) contrast(88%);}',
  ],
})
export class TrendMiniatureComponent {
  public contentPath = input.required<string>();
  public selected = input.required<boolean>();

  private imageElement = computed(() => this.imageElementRef()?.nativeElement as HTMLImageElement | undefined);
  private matToolTip = viewChild(MatTooltip);
  private imageElementRef = viewChild<ElementRef>('miniature');

  constructor(private imageService: ImageService) {
    effect(() => {
      const imageElement = this.imageElement();
      if (imageElement) {
        new IntersectionObserver(this.fetchImageWhenVisible).observe(imageElement);
      }
    });
  }

  private fetchImageWhenVisible = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    const targetEntry = entries.find(e => e.target === this.imageElement());
    if (targetEntry?.isIntersecting) {
      this.loadImage();
      observer.disconnect();
    }
  };

  private loadImage = () => {
    const contentPath = this.contentPath();
    if (contentPath === '') {
      return;
    }
    this.imageService.fetchImageAsBase64(contentPath).then(base64Image => {
      const imageElement = this.imageElement();
      if (imageElement) {
        imageElement.src = base64Image;
        imageElement.classList.add('frequency-trend');

        const matToolTip = this.matToolTip();
        if (matToolTip) {
          matToolTip.disabled = true;
        }
      }
    });
  };
}
