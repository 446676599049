import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

import { ErrorHandlingService } from './services/error-handling.service';
import { HttpErrorInterceptorService } from './services/http-error-interceptor.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, MaterialModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlingService },
  ],
})
export class ErrorHandlingModule {}
