<mat-toolbar class="primary flex h-14 flex-row items-center">
  <app-loading-logo [rotating]="true" speed="very-very-slow" class="mr-2 h-11 w-11" />
  <a [routerLink]="['/']"><img class="h-11" src="assets/astrion_no_logo.png" /></a>

  <div class="flex grow flex-row items-center justify-center">
    @if (chosenProject(); as chosenProject) {
      <mat-select
        class="primary m3-headline-small mat-select-right-align -ml-[20%] w-[20%]"
        [value]="chosenProject.id"
        (selectionChange)="changeProject($event)">
        @for (project of projects(); track project.id) {
          <mat-option [value]="project.id">
            {{ project.name }}
          </mat-option>
        }
      </mat-select>
    }
  </div>

  @if (isLoggedIn()) {
    @if (user(); as user) {
      <div mat-icon-button class="primary rounded-full">
        @if (isConnectedToRealtime()) {
          <mat-icon class="mt-2" matTooltip="Connected to live updates">cloud_done</mat-icon>
        } @else {
          <mat-icon
            class="fg-error mt-2"
            matTooltip="Not connected to live updates. You might need to refresh the page to see new content.">
            cloud_off
          </mat-icon>
        }
      </div>
      <div mat-icon-button class="primary ml-8 flex cursor-pointer items-center" [matMenuTriggerFor]="userMenu">
        <mat-icon>account_circle</mat-icon><span class="ml-2">{{ user.username }}</span>
      </div>
      <mat-menu class="mx-2" #userMenu="matMenu">
        <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
      </mat-menu>
    }
  }
</mat-toolbar>
