import { LineStyle } from '@astrion-webtools/graph';
import { ContentStyle } from '@features/peak-identification/components/content-toggle/content-toggle.component';

import { PMC } from '../interface/pfa-pmc-thresholds';

export const PeakTypes = {
  SW: 'Sw',
  NB: 'Nb',
  SWNoiseDoubt: 'SwNoiseDoubt',
  NBNoiseDoubt: 'NbNoiseDoubt',
  Noise: 'Noise',
};

export const getPeakBottomContentStyle = (type: string): ContentStyle => {
  switch (type) {
    case PeakTypes.SW:
      return {
        color: '#E4C72F',
        dashingPattern: LineStyle.Continuous,
      };
    case PeakTypes.SWNoiseDoubt:
      return {
        color: '#E4C72F',
        dashingPattern: LineStyle.Dash,
      };
    case PeakTypes.NB:
      return {
        color: '#E46C2F',
        dashingPattern: LineStyle.Continuous,
      };
    case PeakTypes.NBNoiseDoubt:
      return {
        color: '#E46C2F',
        dashingPattern: LineStyle.Dash,
      };
    default:
      return {
        color: '#E42F4D',
        dashingPattern: LineStyle.DashDot,
      };
  }
};

export const getPeakTopContentStyle = (pmc: PMC): ContentStyle => {
  switch (pmc) {
    case PMC.High:
      return {
        color: '#D3312C',
        dashingPattern: LineStyle.Continuous,
      };
    case PMC.Low:
      return {
        color: '#7233CC',
        dashingPattern: LineStyle.Continuous,
      };
    case PMC.VeryLow:
      return {
        color: '#2CD331',
        dashingPattern: LineStyle.Continuous,
      };
    default:
      return {
        color: '#000000',
        dashingPattern: LineStyle.DashDot,
      };
  }
};
