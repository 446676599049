import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { props } from '@ngrx/store';

import { ShannonTest } from '../../interface/sampling-test';

export const samplingTestActions = () => {
  return {
    signalSamplingTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalSamplingTestFetched: props<{ signalId: AStrionSignalId; samplingTest: ShannonTest }>(),
    signalSamplingTestNotFound: props<{ signalId: AStrionSignalId }>(),
    signalSamplingTestFetchFailed: props<{ signalId: AStrionSignalId }>(),
  };
};
