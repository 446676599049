import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { FundamentalPeriodicityTest, FundamentalPeriodicityTestDto } from '../interface/fundamental-periodicity';
import { ValidationStepStatus } from '../interface/validation-step-status';

export function mapFundamentalPeriodicityFromDto(
  dto: FundamentalPeriodicityTestDto,
  samplingFrequency: number
): FundamentalPeriodicityTest {
  const fundamentalPeriodicityTest: FundamentalPeriodicityTest = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (fundamentalPeriodicityTest.progress === ComputationStepProgress.Completed) {
    const dtoData = dto.data!;
    fundamentalPeriodicityTest.data = {
      ...dtoData,
      status: ValidationStepStatus.Neutral,
      delay: dtoData.intervalEnd / samplingFrequency,
    };
  }

  return fundamentalPeriodicityTest;
}
