import { Injectable } from '@angular/core';
import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { catchApiError, catchApiErrorNoAction } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { isValidName } from '@tools/utilities/encode-uri-strict';
import { concatMap, EMPTY, map, of } from 'rxjs';

import { Folder, FolderDto, HOME_FOLDER } from '../interface/folder.interface';
import { FoldersApiService } from '../services/folders-api.service';
import folderTreeFlattener from '../utils/folder-tree-flattener';
import { FoldersActions } from './folders.actions';
import { foldersFeature } from './folders.feature';

@Injectable()
export class FoldersEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private foldersApi: FoldersApiService
  ) {}
  fetchFoldersIfNotInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.foldersFetchRequestedIfNotInit, AuthActions.projectChanged),
      concatLatestFrom(() => this.store.select(foldersFeature.selectInitialized)),
      map(([, initilized]) =>
        initilized ? FoldersActions.foldersFetchSkipped() : FoldersActions.foldersFetchRequested()
      )
    );
  });

  fetchFoldersEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.foldersFetchRequested),
      concatMap(() =>
        this.foldersApi.getAllFolders().pipe(
          map((rootFolders: FolderDto[]) =>
            FoldersActions.foldersFetched({ flattenedFoldersTree: folderTreeFlattener(rootFolders) })
          ),
          catchApiError(false, () => FoldersActions.foldersFetchFailed())
        )
      )
    );
  });

  deleteFolderEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.folderDeletionRequested),
      concatMap(({ folderId, parentId, force }) =>
        this.foldersApi.deleteFolder(folderId, force).pipe(
          map(() => FoldersActions.folderDeleted({ folderId, parentId })),
          catchApiError(true, () => FoldersActions.folderDeletionFailed({ folderId }))
        )
      )
    );
  });

  createFolderEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.folderCreationRequested),
      concatMap(({ name, parentId }) => {
        if (!isValidName(name)) {
          return of(FoldersActions.folderCreationFailed());
        } else {
          return this.foldersApi.createFolder({ name, parentId: parentId === HOME_FOLDER.id ? null : parentId }).pipe(
            map((folder: FolderDto) => ({
              id: folder.id,
              name: folder.name,
              signalsCount: 0,
              isLeaf: true,
              parentId: folder.parentId ?? HOME_FOLDER.parentId,
            })),
            map((folder: Folder) => FoldersActions.folderCreated({ folder })),
            catchApiError(false, () => FoldersActions.folderCreationFailed())
          );
        }
      })
    );
  });

  updateFolderEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.folderUpdateRequested),
      concatMap(({ folder }: { folder: Folder }) => {
        if (folder.id === HOME_FOLDER.id) {
          return EMPTY;
        }
        if (!isValidName(folder.name)) {
          return of(FoldersActions.folderUpdateFailed({ folder }));
        }

        return this.foldersApi
          .updateFolder(folder.id, {
            name: folder.name,
            parentId: folder.parentId === HOME_FOLDER.id ? null : folder.parentId,
          })
          .pipe(
            map(() => FoldersActions.folderUpdated({ folder: { ...folder, status: undefined } })),
            catchApiErrorNoAction()
          );
      })
    );
  });
}
