import { createFeature } from '@ngrx/store';

import { reducer } from './data-validation.reducer';
import { extraSelectors } from './data-validation.selectors';
import { featureName } from './data-validation.state';

export const dataValidationFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors,
});
