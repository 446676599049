<div class="flex flex-row">
  <div class="flex flex-row hover:cursor-pointer">
    @if (contentStyle()) {
      <canvas #main class="my-auto" (click)="canvasClicked()"></canvas>
    }
    <mat-checkbox #checkbox [checked]="checked()" (change)="checked.set($event.checked)" [disabled]="disabled()">
      {{ label() }}
      @if (objectsCount(); as count) {
        ({{ count }})
      }
    </mat-checkbox>
  </div>
</div>
