import { ValidationFlags } from '@features/data-validation/shared/interface/validation-flags';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ExplorerItemId } from '../interface/explorer-item.interface';
import { SensorId } from '../interface/sensor-id.interface';

export const FilesExplorerActions = createActionGroup({
  source: 'FilesExplorer',
  events: {
    itemEditionRequested: props<{ id: ExplorerItemId }>(),
    editedItemCleared: props<{ fromId: ExplorerItemId }>(),
    noAction: emptyProps(),

    navigationToFolderRequested: props<{ folder: Folder }>(),

    fetchFolderSkipNoContent: props<{ folder: Folder }>(),
    fetchFolderSkipAlreadyCached: props<{ folder: Folder }>(),

    validationFlagsFetchRequested: props<{ signalId: AStrionSignalId }>(),
    validationFlagsFetchfailed: emptyProps(),
    validationFlagsFetched: props<{ signalId: AStrionSignalId; validationFlags: ValidationFlags }>(),

    flagSensorDataValidationAsDirtyRequested: props<{ sensorId: SensorId }>(),
    flagSensorPeakIdentificationAsDirtyRequested: props<{ sensorId: SensorId }>(),
    flagSensorTrackingAsDirtyRequested: props<{ sensorId: SensorId }>(),

    flagSensorAsDirtyFailed: emptyProps(),
  },
});
