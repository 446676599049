import { GraphCurve, GraphInput } from '@astrion-webtools/graph';

import { CurveName } from '../trajectory-graph.interface';

export function dataMerge(
  prev: GraphInput | undefined,
  next: GraphInput | undefined,
  colorsList: string[]
): GraphInput {
  if (!next) {
    return {};
  }

  // No immutable change
  if (next === prev) {
    return prev;
  }

  const prevCurveMap = new Map<CurveName, GraphCurve>();
  prev?.curves?.forEach(curve => prevCurveMap.set(curve.name, curve));

  const merged = {
    ...next,
    curves: next.curves
      ?.filter(curve => curve.display ?? true)
      ?.map(curve => {
        const prevCurve = prevCurveMap.get(curve.name);
        return { ...curve, color: prevCurve?.color ?? curve.color, axisGroup: prevCurve?.axisGroup };
      }),
    areas: undefined,
  };

  if (!merged.curves || merged.curves.length === 0) {
    return merged;
  }

  if (merged.curves.some(curve => !curve.color)) {
    const nUsedColors = new Map<string, number>();
    merged.curves?.forEach(curve => {
      if (curve.color) {
        nUsedColors.set(curve.color, (nUsedColors.get(curve.color) ?? 0) + 1);
      }
    });
    const colors = colorsList.map((color, index) => ({ color, index, nUsed: nUsedColors.get(color) ?? 0 }));
    colors.sort((a, b) => (a.nUsed === b.nUsed ? a.index - b.index : a.nUsed - b.nUsed));
    const nColors = colorsList.length;
    let colorIdx = 0;

    merged.curves?.forEach(curve => {
      if (!curve.color) {
        curve.color = colors[colorIdx].color;
        colorIdx = (colorIdx + 1) % nColors;
      }
    });
  }

  return merged;
}
