import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';

import { SignalContent } from '../interface/signal-content';

export const featureName = 'signal-overview';

export interface SignalOverviewState {
  signalId: AStrionSignalId | null;
  signalData: SignalContent | undefined;
  loadingState: LoadingState;
}

export const SIGNAL_OVERVIEW_INITIAL_STATE: SignalOverviewState = {
  signalId: null,
  signalData: undefined,
  loadingState: LoadingState.Unknown,
};
