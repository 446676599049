import { Directive, HostListener, input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Directive({
  selector: '[appTriggerMenuOnHover]',
  exportAs: 'appTriggerMenuOnHover',
  standalone: true,
})
export class TriggerMenuOnHoverDirective {
  appTriggerMenuOnHover = input.required<MatMenuTrigger>();
  triggerDisabled = input<boolean>(false);

  insideMenu = false;

  private _timer: ReturnType<typeof setTimeout> | null = null;

  @HostListener('mouseenter') onEntering() {
    if (!this.triggerDisabled()) {
      this.cancelClose();
      this.appTriggerMenuOnHover().openMenu();
    }
  }

  @HostListener('mouseleave') onLeaving() {
    this.scheduleClose();
  }

  mouseEnterMenu() {
    this.cancelClose();
  }

  mouseLeaveMenu() {
    this.scheduleClose();
  }

  cancelClose() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

  scheduleClose() {
    this.cancelClose();
    this._timer = setTimeout(() => {
      this.appTriggerMenuOnHover().closeMenu();
    }, 50);
  }
}
