import { SensorId } from '@features/files-explorer/shared/interface/sensor-id.interface';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { Trajectory } from '../interfaces/trajectory.interface';

export const name = 'sensor-Trajectories';

export interface SensorTrajectoriesState {
  sensorId: SensorId | null;
  status: ProcessingStatus;
  nextComputationDate: Date | null;
  trajectories: Trajectory[] | null;
}

export const SENSOR_TRAJECTORIES_INITIAL_STATE: SensorTrajectoriesState = {
  sensorId: null,
  status: ProcessingStatus.Unknown,
  nextComputationDate: null,
  trajectories: null,
};
