import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PeakIdentificationRealtimeRegistrationService } from './shared/realtime/peak-identification-realtime-service';
import { PeakIdentificationSensorApiService } from './shared/services/peak-identification-sensor-api.service';
import { PeakIdentificationEffects } from './shared/store/peak-identification.effects';
import { peakIdentificationFeature } from './shared/store/peak-identification.feature';

@NgModule({
  declarations: [],
  providers: [PeakIdentificationSensorApiService],
  imports: [
    StoreModule.forFeature(peakIdentificationFeature),
    EffectsModule.forFeature([PeakIdentificationEffects]),
    RealtimeModule.forFeature(PeakIdentificationRealtimeRegistrationService),
  ],
})
export class PeakIdentificationModule {}
