import { createReducer, on } from '@ngrx/store';

import { Project } from '../interfaces/project';
import { User } from '../interfaces/user';
import { AuthActions } from './auth.actions';
import { AUTH_INITIAL_STATE, AuthState } from './auth.state';

export const reducer = createReducer(
  AUTH_INITIAL_STATE,
  on(AuthActions.loggedIn, (state: AuthState, { user, projects }: { user: User; projects: Project[] }): AuthState => {
    return { ...state, user, isLoggedIn: user != null, projects };
  }),
  on(AuthActions.loginFailed, (state: AuthState): AuthState => {
    return { ...state, user: null, isLoggedIn: false };
  }),
  on(AuthActions.projectChangeRequested, (state: AuthState, { project }: { project: Project | null }): AuthState => {
    return { ...state, chosenProject: project };
  }),
  on(AuthActions.loggedOut, (state: AuthState): AuthState => {
    return { ...state, user: null, isLoggedIn: false, projects: [] };
  })
);
