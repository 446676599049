import { ChangeDetectionStrategy, Component, computed, effect, input, viewChildren } from '@angular/core';
import { StationarityData } from '@features/data-validation/shared/interface/stationarity';
import Dygraph, { dygraphs } from 'dygraphs';

import { StatusBannerComponent } from '../status-banner/status-banner.component';
import { ColorScaleComponent } from './color-scale/color-scale.component';
import { ColorMapData } from './interfaces/interfaces';
import { SynchronizableGraphComponent, SyncMode } from './synchronizable-graph/synchronizable-graph.component';
import { SynchronizeGraphs } from './utilities/graph-interaction-synchronization';

@Component({
  selector: 'app-stationarity',
  standalone: true,
  imports: [StatusBannerComponent, SynchronizableGraphComponent, ColorScaleComponent],
  templateUrl: './stationarity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationarityComponent {
  public stationarity = input.required<StationarityData>();

  public stationarityGraphs = viewChildren(SynchronizableGraphComponent);

  constructor() {
    effect(
      () => {
        SynchronizeGraphs(this.stationarityGraphs().map(graph => graph));
      },
      { allowSignalWrites: true }
    );
  }

  public spectrogramColorMapData = computed((): ColorMapData => {
    const spectrogramData = this.stationarity().spectrogram;

    return {
      minValue: Math.floor(10 * Math.log10(spectrogramData.psdMin)),
      maxValue: Math.ceil(10 * Math.log10(spectrogramData.psdMax)),
      valueUnit: 'dB',
      colorMap: spectrogramData.colorMap,
    };
  });

  public resultColorMapData = computed((): ColorMapData => {
    const stationarityResultsData = this.stationarity().stationarityData;

    return {
      minValue: stationarityResultsData.minValue,
      maxValue: stationarityResultsData.maxValue,
      colorMap: stationarityResultsData.colorMap,
    };
  });

  private imageGraph(base64Image: string, duration: number, freqMin: number, freqMax: number) {
    const axesData = {
      xAxis: {
        label: 'Time (s)',
        minValue: 0,
        maxValue: duration,
      },
      yAxis: {
        label: 'Frequency (Hz)',
        minValue: freqMin,
        maxValue: freqMax,
      },
    };

    return {
      axesData,
      syncMode: SyncMode.SyncBothAxes,
      underlayCallback: (ctx: CanvasRenderingContext2D, area: Readonly<dygraphs.Area>, graph: Readonly<Dygraph>) => {
        const img = new Image();
        img.src = base64Image!;
        img.alt = '';

        img.onload = () => {
          const xTotalAxisRange = axesData.xAxis;
          const xCurrentRange = graph.xAxisRange();

          const imgStartX = img.naturalWidth * (xCurrentRange[0] / xTotalAxisRange.maxValue);
          const imgW =
            img.naturalWidth *
            ((xCurrentRange[1] - xCurrentRange[0]) / (xTotalAxisRange.maxValue - xTotalAxisRange.minValue));

          const yTotalAxisRange = axesData.yAxis;
          const yCurrentRange = graph.yAxisRange();

          const imgStartY = img.naturalHeight * (1 - yCurrentRange[1] / yTotalAxisRange.maxValue);
          const imgH =
            img.naturalHeight *
            ((yCurrentRange[1] - yCurrentRange[0]) / (yTotalAxisRange.maxValue - yTotalAxisRange.minValue));

          ctx.drawImage(img, imgStartX, imgStartY, imgW, imgH, area.x, area.y, area.w, area.h);
        };
      },
    };
  }

  public spectrogram = computed(() => {
    const stationarity = this.stationarity();

    return this.imageGraph(
      stationarity.spectrogram.base64EncodedImage,
      stationarity.duration,
      0, // TODO - set to min frequency once the stationarity min frequency bug is resolved
      stationarity.maxFrequency
    );
  });

  public nonStationarityResult = computed(() => {
    const stationarity = this.stationarity();

    return this.imageGraph(
      stationarity.stationarityData.base64EncodedImage,
      stationarity.duration,
      0, // TODO - set to min frequency once the stationarity min frequency bug is resolved
      stationarity.maxFrequency
    );
  });

  public variance = computed(() => {
    const stationarity = this.stationarity();
    const stationarityResultsData = stationarity.stationarityData;

    const axesData = {
      xAxis: {
        label: 'Variance',
        minValue: 0,
        maxValue: Math.max(Math.max(...stationarityResultsData.variance), stationarityResultsData.kappa) * 1.1,
      },
      yAxis: {
        label: 'Frequency (Hz)',
        minValue: stationarity.minFrequency,
        maxValue: stationarity.maxFrequency,
      },
    };

    return {
      axesData,
      syncMode: SyncMode.SyncYAxis,
      underlayCallback: (ctx: CanvasRenderingContext2D, area: Readonly<dygraphs.Area>, graph: Readonly<Dygraph>) => {
        ctx.strokeStyle = '#BD1814';
        ctx.lineWidth = 1;
        ctx.beginPath();

        const varianceData = stationarityResultsData.variance;
        const valuesRange = graph.yAxisRange();

        const lastFrequency = axesData.yAxis.maxValue;
        const varianceIndexScalingFactor = varianceData.length / lastFrequency;

        const firstVarianceVisibleIndex = Math.floor(valuesRange[0] * varianceIndexScalingFactor);
        const lastVarianceVisibleIndex = Math.ceil(valuesRange[1] * varianceIndexScalingFactor);

        const freqIndexScalingFactor = lastFrequency / (varianceData.length - 1);

        const [firstValueX, firstValueY] = graph.toDomCoords(
          varianceData[firstVarianceVisibleIndex],
          firstVarianceVisibleIndex * freqIndexScalingFactor
        );

        ctx.moveTo(firstValueX, firstValueY);

        for (let index = firstVarianceVisibleIndex + 1; index < lastVarianceVisibleIndex; index++) {
          const [xValue, yValue] = graph.toDomCoords(varianceData[index], index * freqIndexScalingFactor);
          ctx.lineTo(xValue, yValue);
        }

        ctx.stroke();

        const threshold = stationarityResultsData.kappa;

        const thresholdX = graph.toDomXCoord(threshold);
        const yMin = graph.toDomYCoord(valuesRange[1]);
        const yMax = graph.toDomYCoord(valuesRange[0]);

        ctx.strokeStyle = '#379612';
        ctx.lineWidth = 1.5;
        ctx.beginPath();
        ctx.moveTo(thresholdX, yMin);
        ctx.lineTo(thresholdX, yMax);
        ctx.stroke();
      },
    };
  });

  public projection = computed(() => {
    const stationarity = this.stationarity();

    const axesData = {
      xAxis: {
        label: 'Time (s)',
        minValue: 0,
        maxValue: stationarity.duration,
      },
      yAxis: {
        label: '',
        minValue: 0,
        maxValue: Math.max(...stationarity.stationarityData.projection) * 1.02,
      },
    };

    return {
      axesData,
      syncMode: SyncMode.SyncXAxis,
      underlayCallback: (ctx: CanvasRenderingContext2D, area: Readonly<dygraphs.Area>, graph: Readonly<Dygraph>) => {
        ctx.strokeStyle = '#1F618D';
        ctx.lineWidth = 1;

        const projectionData = stationarity.stationarityData.projection;

        const [firstValueX, firstValueY] = graph.toDomCoords(0, projectionData[0]);

        const timeFactor = axesData.xAxis.maxValue / projectionData.length;

        ctx.beginPath();
        ctx.moveTo(firstValueX, firstValueY);

        for (let index = 1; index < projectionData.length; index++) {
          const time = index * timeFactor;

          const [xValue, yValue] = graph.toDomCoords(time, projectionData[index]);
          ctx.lineTo(xValue, yValue);
        }

        ctx.stroke();
      },
    };
  });

  public statusMessage = computed(() => {
    const stationarity = this.stationarity();

    const nonStationarityPercentage = stationarity.stationarityData.detectionIndex;
    const thresholds = stationarity.thresholds;

    if (nonStationarityPercentage < thresholds.stationarity) {
      return 'Signal is stationary.';
    }

    let message = 'Signal has a ';

    message +=
      nonStationarityPercentage < thresholds.lowNonStationarity
        ? 'low'
        : nonStationarityPercentage < thresholds.mediumNonStationarity
          ? 'medium'
          : 'high';

    message += ` non-stationarity rate of ${nonStationarityPercentage}% (0% corresponds to a stationary signal).`;

    return message;
  });
}
