<input
  class="border-gray-30 block w-full rounded-md border p-2 text-gray-900"
  type="text"
  [ngStyle]="{
    backgroundColor: isValid() ? 'rgb(248 250 252)' : 'rgb(254 226 226)',
  }"
  #self
  (keyup)="handleKeyUp($event)"
  (blur)="handleFocusLost()"
  [value]="value()" />
