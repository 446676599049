import { IndexedDbRowId } from '@astrion-webtools/graph';
import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { ValidationStepStatus } from './validation-step-status';

export interface FundamentalPeriodicityDataDto {
  period: number;
  frequency: number;
  confidenceCoefficient: number;
  autocorrelation: IndexedDbRowId;
  intervalStart: number;
  intervalEnd: number;
  signalPower: number;
  noisePower: number;
  signalToNoiseRatio: number;
  sigma: IndexedDbRowId;
}

export interface FundamentalPeriodicityTestDto {
  progress: string;
  data?: FundamentalPeriodicityDataDto;
}

export interface FundamentalPeriodicityData {
  status: ValidationStepStatus;
  period: number;
  frequency: number;
  confidenceCoefficient: number;
  autocorrelation: IndexedDbRowId;
  intervalStart: number;
  intervalEnd: number;
  delay: number;
  signalPower: number;
  noisePower: number;
  signalToNoiseRatio: number;
  sigma: IndexedDbRowId;
}

export interface FundamentalPeriodicityTest {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: FundamentalPeriodicityData;
}

export const defaultFundamentalPeriodicityTestWithLoadingState = (
  loadingState: LoadingState = LoadingState.Unknown
): FundamentalPeriodicityTest => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
