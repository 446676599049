import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { props } from '@ngrx/store';

import { FundamentalPeriodicityTest } from '../../interface/fundamental-periodicity';

export const fundamentalPeriodicityActions = () => {
  return {
    signalFundamentalPeriodicityTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalFundamentalPeriodicityTestFetched: props<{
      signalId: AStrionSignalId;
      periodicity: FundamentalPeriodicityTest;
    }>(),
    signalFundamentalPeriodicityTestNotFound: props<{ signalId: AStrionSignalId }>(),
    signalFundamentalPeriodicityTestFetchFailed: props<{ signalId: AStrionSignalId }>(),
  };
};
