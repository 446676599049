import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, viewChildren } from '@angular/core';
import { MatListItem } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { LoadingLogoComponent } from '@components/loading-logo/loading-logo.component';
import { ReportPage, ReportPages } from '@features/signal-report/shared/interface/report-page';
import { MaterialModule } from '@modules/material.module';
import { loaded, loading, loadingError, notFound } from '@shared/interfaces/loading-state';

import { ReportNavigationAnchorsComponent } from './report-navigation-anchors/report-navigation-anchors.component';
import { ReportNavigationMenuComponent } from './report-navigation-menu/report-navigation-menu.component';
import { ReportNavigationSectionComponent } from './report-navigation-section/report-navigation-section.component';
import { TriggerMenuOnHoverDirective } from './trigger-menu-on-hover.directive';

@Component({
  selector: 'app-report-navigation',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReportNavigationAnchorsComponent,
    ReportNavigationMenuComponent,
    ReportNavigationSectionComponent,
    LoadingLogoComponent,
    TriggerMenuOnHoverDirective,
  ],
  templateUrl: './report-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportNavigationComponent {
  pages = input.required<ReportPages>();
  scrolledAnchor = input<string | undefined>(undefined);

  public items = viewChildren(MatListItem);

  blur() {
    this.items().forEach(item => item._elementRef.nativeElement.blur());
  }

  loaded = (page: ReportPage): boolean => loaded(page);
  loading = (page: ReportPage): boolean => loading(page);
  error = (page: ReportPage): boolean => loadingError(page);
  notFound = (page: ReportPage): boolean => notFound(page);
}
