import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';

import { Cycles, defaultCyclesWithLoadingState } from '../interface/cycles';
import { defaultFusionWithLoadingState, Fusion } from '../interface/fusion';

export const featureName = 'peak-identification';

export interface PeakIdentificationState {
  signalId: AStrionSignalId | null;
  cycles: Cycles;
  fusion: Fusion;
  progress: ComputationStepProgress;
  loadingState: LoadingState;
}

export const PEAK_IDENTIFICATION_INITIAL_STATE: PeakIdentificationState = {
  signalId: null,
  cycles: defaultCyclesWithLoadingState(LoadingState.Unknown),
  fusion: defaultFusionWithLoadingState(LoadingState.Unknown),
  progress: ComputationStepProgress.Unknown,
  loadingState: LoadingState.Unknown,
};
