import { Injectable, Signal } from '@angular/core';
import { ExplorerSensorSignalItem } from '@features/files-explorer/shared/interface/explorer-item.interface';
import {
  selectExplorerSensorContent,
  selectSensorDuplicateDateSignalId,
} from '@features/files-explorer/shared/store/files-explorer.selectors';
import { SensorGraphsService } from '@features/sensor-graphs/shared/service/sensor-graphs.service';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { getNonStationnarityCurve } from '@features/signals/shared/interface/sensor-signal.interface';
import { Store } from '@ngrx/store';

import { SensorId } from '../interface/sensor-id.interface';
import { FilesExplorerActions } from '../store/files-explorer.actions';

const NON_STATIONNARITY_TREND_ID = 'non-stationnarity';

@Injectable()
export class SensorSignalsService {
  constructor(
    private store: Store,
    private sensorGraphsService: SensorGraphsService
  ) {}

  setCurrentSensorDataValidationAsDirty(sensorId: SensorId) {
    this.store.dispatch(FilesExplorerActions.flagSensorDataValidationAsDirtyRequested({ sensorId }));
  }

  setCurrentSensorPeakIdentificationAsDirty(sensorId: SensorId) {
    this.store.dispatch(FilesExplorerActions.flagSensorPeakIdentificationAsDirtyRequested({ sensorId }));
  }

  selectCurrentSensorSignals(): Signal<ExplorerSensorSignalItem[]> {
    return this.store.selectSignal(selectExplorerSensorContent);
  }

  selectCurrentSensorDuplicateDateSignalIds(): Signal<AStrionSignalId[]> {
    return this.store.selectSignal(selectSensorDuplicateDateSignalId);
  }

  visualizeNonStationnarityCurve() {
    const signals = this.selectCurrentSensorSignals()().map(item => item.object);
    const curve = getNonStationnarityCurve(signals);
    this.sensorGraphsService.addCurve(NON_STATIONNARITY_TREND_ID, curve);
  }
}
