import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { ConnectionStatus } from '../interfaces/connection-status';
import { RealtimeActions } from './realtime.actions';

@Injectable()
export class RealtimeEffects {
  constructor(
    private actions$: Actions,
    private errorSnackbar: MatSnackBar
  ) {}

  displayErrorEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RealtimeActions.connectionStatusChanged),
        tap(({ status, previousStatus }) => {
          if (!previousStatus && status == ConnectionStatus.Disconnected) {
            this.errorSnackbar.open('Impossible to connect to notification center', 'Dismiss', {
              duration: 5000,
            });
          }

          if (previousStatus == ConnectionStatus.Connected && status == ConnectionStatus.Disconnected) {
            this.errorSnackbar.open('Connection to notification center lost!', 'Dismiss', {
              duration: 5000,
            });
          }

          if (previousStatus == ConnectionStatus.Disconnected && status == ConnectionStatus.Connected) {
            this.errorSnackbar.open('Connection to notification center restored', 'Dismiss', {
              duration: 5000,
            });
          }
        })
      );
    },
    { dispatch: false }
  );
}
