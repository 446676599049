import { HttpErrorResponse } from '@angular/common/http';

export interface AStrionErrorInterface extends Error {
  displayMessage: string | null;
  forceDisplay: boolean;
}

export function isError(error: unknown): error is Error {
  return 'message' in (error as object);
}

export function isAStrionError(error: AStrionErrorInterface | unknown): error is AStrionErrorInterface {
  return 'displayMessage' in (error as object);
}

export class AStrionWebError extends Error implements AStrionErrorInterface {
  constructor(
    message: string,
    public displayMessage: string | null = null,
    public forceDisplay = false
  ) {
    super(message);
  }
}

export class AStrionApiError extends Error implements AStrionErrorInterface {
  constructor(
    public httpError: HttpErrorResponse,
    public displayMessage: string | null = null,
    public forceDisplay = false
  ) {
    super(httpError.error);
  }
}

export const isNotFoundStatus = (error: AStrionApiError) => error?.httpError?.status === 404;
