import { Component, computed, input } from '@angular/core';

export const rotationSpeeds = [
  'extremely-fast',
  'very-very-fast',
  'very-fast',
  'fast',
  'normal',
  'slow',
  'very-slow',
  'very-very-slow',
  'extremely-slow',
] as const;

export type RotationSpeed = (typeof rotationSpeeds)[number];

@Component({
  selector: 'app-loading-logo',
  standalone: true,
  imports: [],
  templateUrl: './loading-logo.component.html',
  styleUrl: 'loading-logo.component.scss',
})
export class LoadingLogoComponent {
  rotating = input<boolean>(true);
  speed = input<RotationSpeed>('normal');

  imgClass = computed(() => (this.rotating() ? `rotating-${this.speed()}` : ''));
}
