import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appReportAnchor]',
  standalone: true,
})
export class ReportAnchorDirective implements OnInit {
  @Input() appReportAnchor!: string;
  @HostBinding('id') anchorId!: string;

  ngOnInit(): void {
    this.anchorId = this.appReportAnchor;
  }
}
