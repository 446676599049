<h1 mat-dialog-title>{{ options.title }}</h1>
<mat-dialog-content>
  <p>{{ options.question }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="primary-button" #cancelButton [mat-dialog-close]="false" cdkFocusInitial>
    Cancel
  </button>
  <span class="grow"></span>
  <button mat-button class="secondary-button" [mat-dialog-close]="true">OK</button>
</mat-dialog-actions>
