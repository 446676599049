import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SignalMetadata } from '@features/signal-overview/shared/interface/signal-metadata';

@Component({
  selector: 'app-signal-overview-metadata',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signal-overview-metadata.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalOverviewMetadataComponent {
  metadata = input.required<SignalMetadata>();
}
