import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { GlGraphComponent } from '@astrion-webtools/graph';
import { TrajectoryGraphComponent } from '@components/trajectory-graph/trajectory-graph.component';
import { SensorGraphsService } from '@features/sensor-graphs/shared/service/sensor-graphs.service';

@Component({
  selector: 'app-sensor-graphs',
  standalone: true,
  imports: [TrajectoryGraphComponent, GlGraphComponent, TitleCasePipe],
  providers: [],
  templateUrl: './sensor-graphs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorGraphsComponent {
  id = input.required<string>();
  name = input.required<string>();
  data = this.sensorGraphsService.graphInputSignal;
  curveNameToId = this.sensorGraphsService.curveNameToIdSignal;

  constructor(private sensorGraphsService: SensorGraphsService) {}

  onDelete() {
    this.sensorGraphsService.clearCurves();
  }

  onRemoveCurve(curveName: string) {
    this.sensorGraphsService.removeCurve(this.curveNameToId()[curveName]);
  }
}
