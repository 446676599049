import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import Dygraph, { dygraphs } from 'dygraphs';

import {
  AxesData,
  InteractionCallbacks,
  Point2d,
  RangeRatios,
  SelectionAreaDimensions,
} from '../interfaces/interfaces';

export type GraphOptions = dygraphs.Options;

export type UnderlayCallback = (
  context: CanvasRenderingContext2D,
  area: Readonly<dygraphs.Area>,
  dygraph: Dygraph
) => void;

export enum SyncMode {
  SyncNone,
  SyncXAxis,
  SyncYAxis,
  SyncBothAxes,
}

const defaultOptions: Partial<GraphOptions> = {
  strokeWidth: 1,
  legend: 'follow',
  showRangeSelector: false,
  axisLabelWidth: 60,
};

function mouseInCanvasAlongX(mouseX: number, area: dygraphs.Area): boolean {
  return mouseX >= area.x && mouseX <= area.x + area.w;
}

function mouseInCanvasAlongY(mouseY: number, area: dygraphs.Area): boolean {
  return mouseY >= area.y && mouseY <= area.y + area.h;
}

function mouseInCanvas(event: MouseEvent, graph: Dygraph): boolean {
  const area = graph.getArea();
  const xCoord = event.offsetX;
  const yCoord = event.offsetY;

  return mouseInCanvasAlongX(xCoord, area) && mouseInCanvasAlongY(yCoord, area);
}

@Component({
  selector: 'app-synchronizable-graph',
  standalone: true,
  imports: [],
  templateUrl: './synchronizable-graph.component.html',
  styleUrls: ['./synchronizable-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SynchronizableGraphComponent {
  public graphTitle = input.required<string>();
  public axesData = input.required<AxesData>();
  public underlayCallback = input.required<UnderlayCallback>();
  public syncMode = input<SyncMode>(SyncMode.SyncNone);
  public zoomMouseMovementThreshold = input<number>(10);
  public options = input<GraphOptions>({});

  public syncAlongX = computed(() => this.syncMode() == SyncMode.SyncBothAxes || this.syncMode() == SyncMode.SyncXAxis);
  public syncAlongY = computed(() => this.syncMode() == SyncMode.SyncBothAxes || this.syncMode() == SyncMode.SyncYAxis);

  public initializeSelectionArea = (): void => {
    const selectionArea = this._selectionArea()!;

    if (this.zoomStart !== null) {
      selectionArea.nativeElement.style.border = '1px solid rgba(0, 63, 255, 0.8)';
      selectionArea.nativeElement.style.backgroundColor = 'rgba(0, 0, 255, 0.2)';
    } else {
      selectionArea.nativeElement.style.border = '1px dashed rgba(16, 16, 16, 1)';
      selectionArea.nativeElement.style.backgroundColor = 'rgba(192, 192, 192, 0.2)';
    }
  };

  public showSelectionArea = (show: boolean): void => {
    const selectionArea = this._selectionArea()!;

    if (show) {
      selectionArea.nativeElement.style.display = 'block';
    } else {
      selectionArea.nativeElement.style.display = 'none';
    }
  };

  public updateSelectionArea = (selectionDimensions: SelectionAreaDimensions): void => {
    const graphArea = this._graph()!.getArea();
    const selectionArea = this._selectionArea()!;

    if (this.syncAlongX()) {
      const x =
        selectionDimensions.xRatio === null ? graphArea.x : graphArea.x + selectionDimensions.xRatio * graphArea.w;
      selectionArea.nativeElement.style.left = x + 'px';

      const w = selectionDimensions.wRatio === null ? graphArea.w : graphArea.w * selectionDimensions.wRatio;
      selectionArea.nativeElement.style.width = w + 'px';
    } else {
      selectionArea.nativeElement.style.left = graphArea.x + 'px';
      selectionArea.nativeElement.style.width = graphArea.w + 'px';
    }

    if (this.syncAlongY()) {
      const y =
        selectionDimensions.yRatio === null ? graphArea.y : graphArea.y + selectionDimensions.yRatio * graphArea.h;
      selectionArea.nativeElement.style.top = y + 'px';

      const h = selectionDimensions.hRatio === null ? graphArea.h : graphArea.h * selectionDimensions.hRatio;
      selectionArea.nativeElement.style.height = h + 'px';
    } else {
      selectionArea.nativeElement.style.top = graphArea.y + 'px';
      selectionArea.nativeElement.style.height = graphArea.h + 'px';
    }
  };

  public updateRanges = (rangeRatios: RangeRatios) => {
    const graph = this._graph()!;

    const xRangeRatios = rangeRatios.xRange;

    const xAxisData = this.axesData().xAxis;
    const [currentXMin, currentXMax] = graph.xAxisRange();

    const xMin = xAxisData.minValue;
    const xSpan = xAxisData.maxValue - xMin;

    const hasXRatios = xRangeRatios !== null && this.syncAlongX();

    const xMinRatio = hasXRatios ? xRangeRatios[0] : currentXMin / xSpan;
    const xMaxRatio = hasXRatios ? xRangeRatios[1] : currentXMax / xSpan;

    const xRange: [number, number] = [xMin + xMinRatio * xSpan, xMin + xMaxRatio * xSpan];

    const yRangeRatios = rangeRatios.yRange;

    const yAxisData = this.axesData().yAxis;
    const [currentYMin, currentYMax] = graph.yAxisRange();

    const yMin = yAxisData.minValue;
    const ySpan = yAxisData.maxValue - yMin;

    const hasYRatios = yRangeRatios !== null && this.syncAlongY();

    const yMinRatio = hasYRatios ? yRangeRatios[0] : currentYMin / ySpan;
    const yMaxRatio = hasYRatios ? yRangeRatios[1] : currentYMax / ySpan;

    const yRange: [number, number] = [yMin + yMinRatio * ySpan, yMin + yMaxRatio * ySpan];

    this._graph()!.updateOptions({ dateWindow: xRange, valueRange: yRange });
  };

  private defaultInteractionCallbacks = {
    initializeSelectionArea: () => this.initializeSelectionArea(),
    showSelectionArea: (show: boolean) => this.showSelectionArea(show),
    updateSelectionArea: (dimensions: SelectionAreaDimensions) => this.updateSelectionArea(dimensions),
    updateRanges: (rangeRatios: RangeRatios) => this.updateRanges(rangeRatios),
  };

  public set interactionCallbacks(callbacks: Partial<InteractionCallbacks>) {
    this._interactionCallbacks.set({
      ...this.defaultInteractionCallbacks,
      ...callbacks,
    });
  }

  public _interactionCallbacks = signal(this.defaultInteractionCallbacks);

  public interactionModel = computed(() => {
    const interactionCallbacks = this._interactionCallbacks();

    const useDefault = this.syncMode() == SyncMode.SyncNone;

    return useDefault
      ? Dygraph.defaultInteractionModel
      : {
          mousedown: (event: MouseEvent, g: Dygraph) => {
            if (!mouseInCanvas(event, g)) {
              return;
            }

            this.zoomStart = {
              x: this.syncAlongX() ? event.offsetX : 0.0,
              y: this.syncAlongY() ? event.offsetY : 0.0,
            };

            interactionCallbacks.initializeSelectionArea();

            const area = g.getArea();
            const xRatio = this.syncAlongX() ? (this.zoomStart.x - area.x) / area.w : 0.0;
            const yRatio = this.syncAlongY() ? (this.zoomStart.y - area.y) / area.h : 0.0;

            interactionCallbacks.updateSelectionArea({ xRatio, yRatio, wRatio: 0, hRatio: 0 });

            event.preventDefault();

            window.addEventListener('mousemove', this.mouseMoveHandler);
            window.addEventListener('mouseup', this.mouseUpHandler);
          },
          click: () => {},
          dblclick: () => {
            interactionCallbacks.updateRanges({ xRange: [0, 1], yRange: [0, 1] });
          },
        };
  });

  private graphOptions = computed(() => {
    return {
      ...this.options(),
      underlayCallback: this.underlayCallback(),
    } as GraphOptions;
  });

  constructor() {
    effect(
      () => {
        const graphDiv = this._graphDiv();
        const selectionArea = this._selectionArea();
        const title = this.graphTitle();
        const axesData = this.axesData();
        const interactionModel = this.interactionModel();
        const underlayCallback = this.underlayCallback();

        if (
          this._graph() === null &&
          graphDiv !== null &&
          selectionArea !== null &&
          interactionModel !== null &&
          underlayCallback !== null
        ) {
          selectionArea.nativeElement.style.pointerEvents = 'none';

          // this will happen only once
          const options = this.options();
          const graph = new Dygraph(graphDiv.nativeElement, [[0]], {
            ...defaultOptions,
            ...options,
            labels: [''],
            title,
            titleHeight: 48,
            xlabel: axesData.xAxis.label,
            ylabel: axesData.yAxis.label,
            dateWindow: [axesData.xAxis.minValue, axesData.xAxis.maxValue],
            valueRange: [axesData.yAxis.minValue, axesData.yAxis.maxValue],
            axes: {
              x: {
                pixelsPerLabel: 48,
              },
            },
            interactionModel: interactionModel,
            underlayCallback: underlayCallback,
          });

          this._graph.set(graph);
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const graphOptions = this.graphOptions();
      const graph = this._graph();
      if (graph !== null) {
        graph.updateOptions(graphOptions);
      }
    });
  }

  private _graphDiv = signal<ElementRef<HTMLDivElement> | null>(null);
  @ViewChild('graph', { static: true }) set graphDiv(value: ElementRef<HTMLDivElement>) {
    this._graphDiv.set(value);
  }

  private _graph = signal<Dygraph | null>(null);

  private _selectionArea = signal<ElementRef<HTMLDivElement> | null>(null);
  @ViewChild('overlay', { static: true }) set selectionArea(value: ElementRef<HTMLDivElement>) {
    this._selectionArea.set(value);
  }

  private zoomStart: Point2d | null = null;

  private mouseMoveHandler = (event: MouseEvent): void => {
    this._interactionCallbacks().showSelectionArea(true);

    const graphArea = this._graph()!.getArea();
    const graphDiv = this._graphDiv()!;

    const graphDivRect = graphDiv.nativeElement.getBoundingClientRect();
    const mouseX = Math.min(graphArea.x + graphArea.w, Math.max(graphArea.x, event.clientX - graphDivRect.x));
    const mouseY = Math.min(graphArea.y + graphArea.h, Math.max(graphArea.y, event.clientY - graphDivRect.y));

    const mouseInCanvasX = mouseInCanvasAlongX(mouseX, graphArea);
    const mouseInCanvasY = mouseInCanvasAlongY(mouseY, graphArea);

    if (!mouseInCanvasX && !mouseInCanvasY) {
      return;
    }

    let xRatio = null;
    let yRatio = null;
    let wRatio = null;
    let hRatio = null;

    if (mouseInCanvasX && this.syncAlongX()) {
      xRatio = (Math.min(this.zoomStart!.x, mouseX) - graphArea.x) / graphArea.w;
      wRatio = Math.abs(this.zoomStart!.x - mouseX) / graphArea.w;
    }

    if (mouseInCanvasY && this.syncAlongY()) {
      yRatio = (Math.min(this.zoomStart!.y, mouseY) - graphArea.y) / graphArea.h;
      hRatio = Math.abs(this.zoomStart!.y - mouseY) / graphArea.h;
    }

    this._interactionCallbacks().updateSelectionArea({ xRatio, yRatio, wRatio, hRatio });
  };

  private mouseUpHandler = (event: MouseEvent): void => {
    window.removeEventListener('mousemove', this.mouseMoveHandler);
    window.removeEventListener('mouseup', this.mouseUpHandler);

    this._interactionCallbacks().showSelectionArea(false);

    const graphDivRect = this._graphDiv()!.nativeElement.getBoundingClientRect();
    const graph = this._graph()!;
    const area = graph.getArea();

    const mouseX = Math.min(area.x + area.w, Math.max(area.x, event.clientX - graphDivRect.x));
    const mouseY = Math.min(area.y + area.h, Math.max(area.y, event.clientY - graphDivRect.y));

    const zoomX = this.zoomStart!.x;
    const zoomY = this.zoomStart!.y;

    this.zoomStart = null;

    if (this.syncAlongY() && Math.abs(zoomY - mouseY) < this.zoomMouseMovementThreshold()) {
      return;
    }

    if (this.syncAlongX() && Math.abs(zoomX - mouseX) < this.zoomMouseMovementThreshold()) {
      return;
    }

    const [graphZoomStartX, graphZoomStartY] = graph.toDataCoords(zoomX, zoomY);
    const [graphZoomStopX, graphZoomStopY] = graph.toDataCoords(mouseX, mouseY);

    let xRange: [number, number] | null = null;

    if (this.syncAlongX()) {
      const xRangeMin = Math.min(graphZoomStartX, graphZoomStopX);
      const xRangeMax = Math.max(graphZoomStartX, graphZoomStopX);

      const xAxisData = this.axesData().xAxis;

      const xMin = xAxisData.minValue;
      const xSpan = xAxisData.maxValue - xMin;

      xRange = [(xRangeMin - xMin) / xSpan, (xRangeMax - xMin) / xSpan];
    }

    let yRange: [number, number] | null = null;

    if (this.syncAlongY()) {
      const valueRangeMin = Math.min(graphZoomStartY, graphZoomStopY);
      const valueRangeMax = Math.max(graphZoomStartY, graphZoomStopY);

      const yAxisData = this.axesData().yAxis;

      const yMin = yAxisData.minValue;
      const ySpan = yAxisData.maxValue - yMin;

      yRange = [(valueRangeMin - yMin) / ySpan, (valueRangeMax - yMin) / ySpan];
    }

    this._interactionCallbacks().updateRanges({ xRange, yRange });
  };
}
