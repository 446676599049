import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SensorGraphsEffect } from './shared/store/sensor-graphs.effects';
import { sensorGraphsFeature } from './shared/store/sensor-graphs.feature';

@NgModule({
  imports: [StoreModule.forFeature(sensorGraphsFeature), EffectsModule.forFeature([SensorGraphsEffect])],
})
export class SensorGraphsModule {}
