import { DataType, makeBase64Field } from '@astrion-webtools/graph';
import { JSONFieldStorage } from '@astrion-webtools/graph/lib/http-wrapper/interface/field-storage';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

import { DataValidationDto } from '../interface/data-validation';
import { FundamentalPeriodicityTestDto } from '../interface/fundamental-periodicity';

export const fromDataValidation = (signalId: string, samplingFrequency: number) =>
  storage(
    signalId,
    samplingFrequency,
    'fundamentalPeriodicityTest.data',
    {
      xMax: (data: DataValidationDto, additionalFields: { samplingFrequency: number }) =>
        data.fundamentalPeriodicityTest.data!.intervalEnd / additionalFields.samplingFrequency,
    },
    {
      xMax: (data: DataValidationDto, additionalFields: { samplingFrequency: number }) =>
        (data.fundamentalPeriodicityTest.data!.intervalEnd / additionalFields.samplingFrequency) * 0.8,
    }
  );

export const fromFundamentalPeriodicity = (signalId: string, samplingFrequency: number) =>
  storage(
    signalId,
    samplingFrequency,
    'data',
    {
      xMax: (data: FundamentalPeriodicityTestDto, additionalFields: { samplingFrequency: number }) =>
        data.data!.intervalEnd / additionalFields.samplingFrequency,
    },
    {
      xMax: (data: FundamentalPeriodicityTestDto, additionalFields: { samplingFrequency: number }) =>
        (data.data!.intervalEnd / additionalFields.samplingFrequency) * 0.8,
    }
  );

type ComputationFunction<T> = (data: T, additionalFields: { samplingFrequency: number }) => number;

function storage<T>(
  signalId: string,
  samplingFrequency: number,
  pathPrefix: string,
  computedFields?: Record<string, ComputationFunction<T>>,
  sigmaComputedField?: Record<string, ComputationFunction<T>>
): JSONFieldStorage[] {
  return [
    makeBase64Field({
      id: `${signalId}_autocorrelation`,
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.fundamentalPediodicity,
      dataType: DataType.Float64,
      path: `${pathPrefix}.autocorrelation`,
      dstName: 'amplitudes',
      additionalFields: {
        samplingFrequency,
        xMin: 0.0,
      },
      computedFields,
    }),
    makeBase64Field({
      id: `${signalId}_sigma`,
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.fundamentalPediodicity,
      dataType: DataType.Float64,
      path: `${pathPrefix}.sigma`,
      dstName: 'amplitudes',
      additionalFields: {
        samplingFrequency,
        xMin: 0.0,
      },
      computedFields: sigmaComputedField,
    }),
  ];
}
