import { Component, computed, input } from '@angular/core';
import {
  getPercentageLabel,
  getShannonLabel,
  getTimeSaturationLabel,
  ValidationFlags,
} from '@features/data-validation/shared/interface/validation-flags';
import { getValidationStepStatusColor } from '@features/data-validation/shared/interface/validation-step-status';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-validation-flags-color-headband',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './validation-flags-color-headband.component.html',
  styles: ``,
})
export class ValidationFlagsColorHeadbandComponent {
  flags = input.required<ValidationFlags>();

  nonStationnarity = computed(() => {
    const flag = this.flags().nonStationnarity;
    const value = getPercentageLabel(flag);
    return { color: getValidationStepStatusColor(flag.status), tooltip: `Non stationnarity ${value}` };
  });

  timeSaturation = computed(() => {
    const flag = this.flags().timeSaturation;
    return {
      color: getValidationStepStatusColor(flag.status),
      tooltip: getTimeSaturationLabel(flag),
    };
  });

  shannon = computed(() => {
    const flag = this.flags().shannon;
    return {
      color: getValidationStepStatusColor(flag.status),
      tooltip: getShannonLabel(flag),
    };
  });
}
