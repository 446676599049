import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';

import { AuthActions } from './shared/store/auth.actions';
import { authFeature } from './shared/store/auth.feature';

export const authGuardFunction: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store);
  return store.select(authFeature.selectIsLoggedIn).pipe(
    first(),
    map((isLoggedIn: boolean | null) => {
      if (isLoggedIn === null) {
        store.dispatch(AuthActions.loginRequested({ redirectUri: window.location.origin + state.url }));
        return false;
      } else {
        return isLoggedIn === true;
      }
    })
  );
};
