import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { ExplorerContentComponent } from '@features/files-explorer/components/explorer-content/explorer-content.component';
import { ExplorerTopBarComponent } from '@features/files-explorer/components/explorer-top-bar/explorer-top-bar.component';
import { SensorComponent } from '@features/files-explorer/components/sensor/sensor.component';
import {
  ExplorerFolderItem,
  ExplorerItem,
  ExplorerSensorSignalItem,
  isFolderItem,
  isSignalItem,
} from '@features/files-explorer/shared/interface/explorer-item.interface';
import { FilesExplorerActions } from '@features/files-explorer/shared/store/files-explorer.actions';
import { filesExplorerFeature } from '@features/files-explorer/shared/store/files-explorer.feature';
import {
  selectCurrentFolder,
  selectCurrentFolderId,
  selectExplorerContent,
  selectHasSignals,
  selectUriFoldersPath,
} from '@features/files-explorer/shared/store/files-explorer.selectors';
import { Folder, FolderId, isFolderNotEmpty } from '@features/folders/shared/interface/folder.interface';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './files-explorer.component.html',
  standalone: true,
  imports: [ExplorerTopBarComponent, ExplorerContentComponent, SensorComponent, ConfirmDialogComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class FilesExplorerComponent {
  content: Signal<ExplorerItem[][]> = this.store.selectSignal(selectExplorerContent);
  folderId = this.store.selectSignal(selectCurrentFolderId);

  path = this.store.selectSignal(selectUriFoldersPath);
  folder = this.store.selectSignal(selectCurrentFolder);
  hasSignals = this.store.selectSignal(selectHasSignals);
  isLoading = this.store.selectSignal(filesExplorerFeature.selectIsLoading);

  currentlyEditedItemId: Signal<FolderId | AStrionSignalId | null> = this.store.selectSignal(
    filesExplorerFeature.selectCurrentlyEditedItemId
  );

  constructor(
    private store: Store,
    private router: Router,
    private dialogService: DialogService
  ) {
    this.store.dispatch(FoldersActions.foldersFetchRequestedIfNotInit());
  }

  itemClicked(item: ExplorerItem) {
    if (isFolderItem(item)) {
      this.store.dispatch(FilesExplorerActions.navigationToFolderRequested({ folder: item.object }));
    } else if (isSignalItem(item)) {
      this.router.navigateByUrl(`/signals/report/${item.object.id}`);
    }
  }

  openInNew(item: ExplorerSensorSignalItem) {
    window.open(`/signals/report/${item.object.id}`, '_blank');
  }

  renameClicked(item: ExplorerItem) {
    this.store.dispatch(FilesExplorerActions.itemEditionRequested({ id: item.object.id }));
  }

  downloadClicked(item: ExplorerItem) {
    this.store.dispatch(SignalsActions.signalDownloadRequested({ signalId: item.object.id }));
  }

  renameCanceled(item: ExplorerItem) {
    this.store.dispatch(FilesExplorerActions.editedItemCleared({ fromId: item.object.id }));
  }

  async deleteItem(item: ExplorerItem) {
    if (isFolderItem(item)) {
      if (isFolderNotEmpty(item.object)) {
        if (
          await this.dialogService.confirm({
            title: 'Delete folder',
            question: 'Folder is not empty, contents will be deleted recursively',
          })
        ) {
          this.deleteFolder(item, true);
        }
      } else {
        this.deleteFolder(item, false);
      }
    } else if (isSignalItem(item)) {
      this.store.dispatch(SignalsActions.signalDeletionRequested({ signal: item.object }));
    }
  }

  updateItem(item: ExplorerItem) {
    if (isFolderItem(item)) {
      this.store.dispatch(FoldersActions.folderUpdateRequested({ folder: item.object }));
    } else if (isSignalItem(item)) {
      this.store.dispatch(SignalsActions.signalUpdateRequested({ signal: item.object }));
    }
  }

  navigateToFolder(folder: Folder) {
    this.store.dispatch(FilesExplorerActions.navigationToFolderRequested({ folder }));
  }

  refresh() {
    if (this.folder()!.isLeaf) {
      const folder = this.folder();
      if (folder) {
        this.store.dispatch(SignalsActions.folderSignalsFetchRequested({ folderId: folder.id }));
      }
    } else {
      this.store.dispatch(FoldersActions.foldersFetchRequested());
    }
  }

  createFolder(name: string) {
    this.store.dispatch(FoldersActions.folderCreationRequested({ name, parentId: this.folder()!.id }));
  }

  uploadFiles(files: File[]) {
    this.store.dispatch(SignalsActions.signalsUploadRequested({ folderId: this.folder()!.id, files }));
  }

  private deleteFolder(item: ExplorerFolderItem, force: boolean) {
    this.store.dispatch(
      FoldersActions.folderDeletionRequested({
        folderId: item.object.id,
        parentId: item.object.parentId,
        force,
      })
    );
  }
}
