import { Pipe, PipeTransform } from '@angular/core';
import { Progress } from '@shared/types/progress.interface';

const convert = (value: number): string => value.toFixed(2);

@Pipe({
  name: 'filesizeProgress',
  standalone: true,
})
export class FilesizeProgressPipe implements PipeTransform {
  transform(progress: Progress): string {
    const k = 1024;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    const power = Math.floor(Math.log(progress.total) / Math.log(k));
    const divisor = Math.pow(k, power);

    return `${convert(progress.progress / divisor)} / ${convert(progress.total / divisor)} ${sizes[power]}`;
  }
}
