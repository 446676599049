import { Component, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingLogoComponent } from '@components/loading-logo/loading-logo.component';
import { ReportAnchor, ReportPage } from '@features/signal-report/shared/interface/report-page';
import { MaterialModule } from '@modules/material.module';
import { loaded, loading, loadingError, notFound } from '@shared/interfaces/loading-state';

@Component({
  selector: 'app-report-navigation-anchors',
  standalone: true,
  imports: [MaterialModule, LoadingLogoComponent],
  templateUrl: './report-navigation-anchors.component.html',
})
export class ReportNavigationAnchorsComponent {
  page = input.required<ReportPage>();
  scrolledAnchor = input.required<string | undefined>();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  forceNavigateToFragment(path: string, fragment: string) {
    this.router.navigate([path], { relativeTo: this.route, skipLocationChange: true }).then(() => {
      this.router.navigate([path], { relativeTo: this.route, fragment });
    });
  }

  fontStyle = (anchor: ReportAnchor, first: boolean) =>
    (!this.scrolledAnchor() && first) || anchor.fragment === this.scrolledAnchor() ? 'bold' : 'normal';

  loaded = (anchor: ReportAnchor): boolean => loaded(anchor);
  loading = (anchor: ReportAnchor): boolean => loading(anchor);
  error = (anchor: ReportAnchor): boolean => loadingError(anchor);
  notFound = (anchor: ReportAnchor): boolean => notFound(anchor);
}
