<div class="stationarity flex w-full flex-col">
  <app-status-banner class="mb-3 w-full flex-none" [status]="stationarity().status" [message]="statusMessage()" />

  <div class="mx-3 grid grid-cols-5 gap-5">
    <app-synchronizable-graph
      class="col-span-2 h-[57vh]"
      graphTitle="Time-Frequency"
      [axesData]="spectrogram().axesData"
      [syncMode]="spectrogram().syncMode"
      [underlayCallback]="spectrogram().underlayCallback" />

    <app-synchronizable-graph
      class="col-span-2 h-[57vh]"
      graphTitle="Time-Frequency detection"
      [axesData]="nonStationarityResult().axesData"
      [syncMode]="nonStationarityResult().syncMode"
      [underlayCallback]="nonStationarityResult().underlayCallback" />

    <app-synchronizable-graph
      class="col-span-1 h-[57vh]"
      graphTitle="Normalized variance"
      [axesData]="variance().axesData"
      [syncMode]="variance().syncMode"
      [underlayCallback]="variance().underlayCallback" />

    <app-color-scale class="col-span-2 h-[1.5vh]" [colorMapData]="spectrogramColorMapData()" />

    <app-color-scale class="col-span-2 h-[1.5vh]" [colorMapData]="resultColorMapData()" />

    <app-synchronizable-graph
      class="col-span-2 col-start-3 h-[20vh]"
      graphTitle="Fault detection projection"
      [axesData]="projection().axesData"
      [syncMode]="projection().syncMode"
      [underlayCallback]="projection().underlayCallback" />
  </div>
</div>
