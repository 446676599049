<mat-expansion-panel class="w-full py-4" disabled [hideToggle]="true" [expanded]="panelExpanded">
  <mat-expansion-panel-header class="cursor-default">
    <div class="flex w-full flex-row items-center justify-between text-base text-black">
      <div>
        <span class="mr-3">Scale:</span>
        <div class="mx-2 inline-block font-normal">
          <span>Linear</span>
          <mat-slide-toggle class="mx-1 my-auto" [checked]="dbScale()" (change)="dbScale.set($event.checked)" />
          <span>dB</span>
        </div>
      </div>

      <ng-content />

      <div>
        <button mat-stroked-button (click)="togglePanel()">
          @if (panelExpanded) {
            Collapse graph options
          } @else {
            Expand graph options
          }
        </button>
      </div>
    </div>
  </mat-expansion-panel-header>
  <div class="grid w-full grid-cols-9 gap-x-3 gap-y-1 py-5">
    <div class="col-span-3"><span>Curves</span></div>

    @if (curveOptions().first; as firstCurve) {
      <app-content-toggle
        class="col-span-2"
        [label]="firstCurve.name"
        [contentStyle]="firstCurve.style"
        [uncheckDisabled]="curveToggleDisabled()"
        [checked]="firstCurve.state()"
        (checkedChange)="firstCurve.state.set($event)" />
    }

    @if (hasSecondCurve()) {
      @if (additionalCurves()[0]; as secondCurve) {
        <app-content-toggle
          class="col-span-2"
          [label]="secondCurve.name"
          [contentStyle]="secondCurve.style"
          [uncheckDisabled]="curveToggleDisabled()"
          [checked]="secondCurve.state()"
          (checkedChange)="secondCurve.state.set($event)" />
      }
    }

    @if (hasThirdCurve()) {
      @if (additionalCurves()[1]; as thirdCurve) {
        <app-content-toggle
          class="col-span-2"
          [label]="thirdCurve.name"
          [contentStyle]="thirdCurve.style"
          [uncheckDisabled]="curveToggleDisabled()"
          [checked]="thirdCurve.state()"
          (checkedChange)="thirdCurve.state.set($event)" />
      }
    }

    <mat-divider class="col-span-full" />

    <div class="col-span-3">
      <span matTooltip="PMC: Probability of MisClassifying" [matTooltipShowDelay]="400"
        >Peak Top - PMC <mat-icon class="scale-75">info_outline</mat-icon></span
      >
    </div>
    <app-content-toggle
      class="col-span-2"
      label="High - 1e-4"
      [contentStyle]="highPmcStyle"
      [objectsCount]="highPmcCount()"
      [checked]="showHighPMC()"
      (checkedChange)="showHighPMC.set($event)" />
    <app-content-toggle
      class="col-span-2"
      label="Low - 1e-6"
      [contentStyle]="lowPmcStyle"
      [objectsCount]="lowPmcCount()"
      [checked]="showLowPMC()"
      (checkedChange)="showLowPMC.set($event)" />
    <app-content-toggle
      class="col-span-2"
      label="Very low - 1e-8"
      [contentStyle]="veryLowPmcStyle"
      [objectsCount]="veryLowPmcCount()"
      [checked]="showVeryLowPMC()"
      (checkedChange)="showVeryLowPMC.set($event)" />

    <mat-divider class="col-span-full" />

    <div class="col-span-3"><span>Peak Bottom - Peak class</span></div>
    <div class="col-span-2 flex flex-col gap-1">
      <app-content-toggle
        label="Sine Wave"
        [contentStyle]="swStyle"
        [objectsCount]="swCount()"
        [checked]="showSW()"
        (checkedChange)="showSW.set($event)" />
      <app-content-toggle
        label="Narrow Band"
        [contentStyle]="nbStyle"
        [objectsCount]="nbCount()"
        [checked]="showNB()"
        (checkedChange)="showNB.set($event)" />
    </div>
    <div class="col-span-2 flex flex-col gap-1">
      <app-content-toggle
        label="Sine Wave/Noise doubt"
        [contentStyle]="swNoiseDoubtStyle"
        [objectsCount]="swNoiseDoubtCount()"
        [checked]="showSWNoiseDoubt()"
        (checkedChange)="showSWNoiseDoubt.set($event)" />
      <app-content-toggle
        label="Narrow Band/Noise doubt"
        [contentStyle]="nbNoiseDoubtStyle"
        [objectsCount]="nbNoiseDoubtCount()"
        [checked]="showNBNoiseDoubt()"
        (checkedChange)="showNBNoiseDoubt.set($event)" />
    </div>
    <app-content-toggle
      class="col-span-2"
      label="Noise"
      [contentStyle]="noisePeaksStyle"
      [objectsCount]="noisePeaksCount()"
      [checked]="showNoise()"
      (checkedChange)="showNoise.set($event)" />
  </div>
</mat-expansion-panel>
