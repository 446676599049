import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { createSelector } from '@ngrx/store';

import { DataValidationState, featureName } from './data-validation.state';

export const extraSelectors = ({ selectProgress }: BaseSelectors<typeof featureName, DataValidationState>) => ({
  selectDataValidationComputed: createSelector(
    selectProgress,
    (progress: ComputationStepProgress): boolean => progress === ComputationStepProgress.Completed
  ),
});
