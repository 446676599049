export interface Progress {
  progress: number;
  total: number;
}

export interface ProgressState {
  uploading: boolean;
  processing: boolean;
  finished: boolean;
}

export function getProgressState(progress: Progress | null): ProgressState {
  const state: ProgressState = {
    uploading: false,
    processing: false,
    finished: false,
  };
  if (progress === null) {
    state.finished = true;
  } else {
    if (progress.total <= 0 || progress.progress < progress.total) {
      state.uploading = true;
    } else {
      state.processing = true;
    }
  }
  return state;
}

export function isProgress<T extends object>(response: Progress | T): response is Progress {
  return 'progress' in response;
}
