import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private jwtHelper: JwtHelperService;

  constructor() {
    this.jwtHelper = new JwtHelperService();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public decodeToken(token: string): any {
    return this.jwtHelper.decodeToken(token);
  }

  public getStringClaim(token: string, claimKey: string): string {
    const decodedToken = this.decodeToken(token);
    return decodedToken ? decodedToken[claimKey] : null;
  }

  public getArrayClaim(token: string, claimKey: string): string[] {
    const decodedToken = this.decodeToken(token);
    return decodedToken ? decodedToken[claimKey] : null;
  }
}
