import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments/environment.json';
import { SensorId } from '@features/files-explorer/shared/interface/sensor-id.interface';

@Injectable()
export class DataValidationSensorApiService {
  private base_url: string;
  constructor(private httpClient: HttpClient) {
    this.base_url = `${environment.BASE_API_URL}/data-validation/sensors`;
  }

  flagAsDirty(sensorId: SensorId) {
    return this.httpClient.put(`${this.base_url}/flag-dirty/${sensorId}`, null);
  }
}
