import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router, RouterLink } from '@angular/router';
import { LoadingLogoComponent } from '@components/loading-logo/loading-logo.component';
import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { realtimeFeature } from '@features/realtime/shared/store/realtime.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  standalone: true,
  imports: [MaterialModule, RouterLink, LoadingLogoComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBarComponent {
  isLoggedIn = this.store.selectSignal(authFeature.selectIsLoggedIn);
  user = this.store.selectSignal(authFeature.selectUser);
  chosenProject = this.store.selectSignal(authFeature.selectChosenProject);
  isConnectedToRealtime = this.store.selectSignal(realtimeFeature.selectIsConnected);
  projects = this.store.selectSignal(authFeature.selectProjects);

  constructor(
    private store: Store,
    private router: Router
  ) {}

  changeProject(selectChange: MatSelectChange) {
    const project = this.projects().find(p => p.id === selectChange.value);
    if (project) {
      this.store.dispatch(AuthActions.projectChangeRequested({ project, redirectTo: '/' }));
    }
  }

  logout() {
    this.store.dispatch(AuthActions.logoutRequested({ redirectUri: window.location.origin }));
  }
}
