import { ExplorerItemId } from '../interface/explorer-item.interface';
import { FolderContentCache } from '../interface/folder-content-cache.interface';

export const name = 'files-explorer';

export interface FilesExplorersState {
  currentlyEditedItemId: ExplorerItemId | null;
  isLoading: boolean;
  cachedFolder: FolderContentCache;
}

export const FILES_EXPLORER_INITIAL_STATE: FilesExplorersState = {
  currentlyEditedItemId: null,
  isLoading: true,
  cachedFolder: {
    folderId: null,
    signals: [],
  },
};
