import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, ElementRef, input, signal, viewChild, viewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PMC } from '@features/peak-identification/shared/interface/pfa-pmc-thresholds';
import { peakPmcToString, peakTypeToString } from '@features/peak-identification/shared/utils/peak-utils';
import { MaterialModule } from '@modules/material.module';
import { toDb, toPercent } from '@tools/utilities/units';

export interface TablePeak {
  index: number;
  frequency: number;
  amplitude: number;
  rms: number;
  psd: number;
  type: string;
  pmc: PMC;
  localSNR: number;
  relativeBandWidth3dBRatio: number;
  totalQuadraticError: number;
  quadraticError3dB: number;
}

export interface Column {
  name: string;
  propertyName: string;
  formattedPropertyValue: (peak: TablePeak) => string;
}

const baseColumns: Column[] = [
  {
    name: 'Peak',
    propertyName: 'index',
    formattedPropertyValue: (peak: TablePeak): string => peak.index.toString(),
  },
  {
    name: 'Frequency (Hz)',
    propertyName: 'frequency',
    formattedPropertyValue: (peak: TablePeak): string => peak.frequency.toFixed(2),
  },
  {
    name: 'Time Amplitude',
    propertyName: 'amplitude',
    formattedPropertyValue: (peak: TablePeak): string => peak.amplitude.toFixed(4),
  },
  {
    name: 'RMS Amplitude (dB)',
    propertyName: 'rms',
    formattedPropertyValue: (peak: TablePeak): string => toDb(peak.rms).toFixed(3),
  },
  {
    name: 'PSD Amplitude (dB)',
    propertyName: 'psd',
    formattedPropertyValue: (peak: TablePeak): string => toDb(peak.psd).toFixed(3),
  },
  {
    name: 'Class',
    propertyName: 'type',
    formattedPropertyValue: (peak: TablePeak): string => peakTypeToString(peak.type),
  },
  {
    name: 'Probability of misclassifying (PMC)',
    propertyName: 'pmc',
    formattedPropertyValue: (peak: TablePeak): string => peakPmcToString(peak.pmc),
  },
  {
    name: 'Local SNR (dB)',
    propertyName: 'localSNR',
    formattedPropertyValue: (peak: TablePeak): string => toDb(peak.localSNR).toFixed(3),
  },
  {
    name: 'Bandwidth % spectral window',
    propertyName: 'relativeBandWidth3dBRatio',
    formattedPropertyValue: (peak: TablePeak): string => toPercent(peak.relativeBandWidth3dBRatio).toFixed(0),
  },
  {
    name: 'Total quad. error % spectral window',
    propertyName: 'totalQuadraticError',
    formattedPropertyValue: (peak: TablePeak): string => toPercent(peak.totalQuadraticError).toExponential(4),
  },
  {
    name: '-3dB quad. error % spectral window',
    propertyName: 'quadraticError3dB',
    formattedPropertyValue: (peak: TablePeak): string => toPercent(peak.quadraticError3dB).toExponential(4),
  },
];

@Component({
  selector: 'app-peaks-table',
  standalone: true,
  imports: [MaterialModule, ScrollingModule, CommonModule],
  templateUrl: './peaks-table.component.html',
})
export class PeaksTableComponent {
  public peaks = input.required<TablePeak[]>();
  public additionalColumns = input<Column[]>([]);

  public filterPredicate = input<(peak: TablePeak) => boolean>(() => true);

  public itemSize = input<number>(60);
  public minBufferedItems = input<number>(30);
  public maxBufferedItems = input<number>(35);

  public scrollViewport = viewChild<CdkVirtualScrollViewport>('scroll_viewport');
  public tableDiv = viewChild<ElementRef<HTMLDivElement>>('tableDiv');
  public headerColumns = viewChildren('header_column', { read: ElementRef });

  public minBufferPx = computed(() => this.itemSize() * this.minBufferedItems());
  public maxBufferPx = computed(() => this.itemSize() * this.maxBufferedItems());

  public sort = viewChild.required(MatSort);

  private _resizeObserver: ResizeObserver;
  private _columnWidths = signal<number[]>([]);

  constructor() {
    effect(
      () => {
        const viewport = this.scrollViewport();
        // Needed in order for the viewport to have time for its clientWidth to be set properly.
        // Otherwise, we cannot account for the scrollbar width when computing the viewport and column widths.
        setTimeout(() => {
          this.viewportInitialized.set(viewport !== undefined);
        }, 0);
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      this.dataSource().sort = this.sort();
    });

    effect(() => {
      const dataSource = this.dataSource();

      dataSource.filterPredicate = this.filterPredicate();

      // Filtering is only triggered if a filter string is present, even if the
      // string is not taken into account during filtering.
      // Therefore, for simplicity, we set the string every time we reset the predicate.
      dataSource.filter = 'a';
    });

    effect(
      () => {
        const headerColumns = this.headerColumns();

        this._columnWidths.set(headerColumns.map(column => column.nativeElement.offsetWidth));
      },
      { allowSignalWrites: true }
    );

    this._resizeObserver = new ResizeObserver(() => {
      this._columnWidths.set(this.headerColumns().map(column => column.nativeElement.offsetWidth));
    });

    effect(() => {
      const tableDiv = this.tableDiv();

      if (tableDiv === undefined) {
        return;
      }

      this._resizeObserver.observe(tableDiv.nativeElement);
    });
  }

  public columns = computed(() => {
    return [...baseColumns, ...this.additionalColumns()];
  });

  public columnIds = computed(() => {
    return this.columns().map(column => column.propertyName);
  });

  public activeSortColumnId = computed(() => {
    return this.columns()[0].propertyName;
  });

  public dataSource = computed(() => {
    const source = new MatTableDataSource(this.peaks());

    return source;
  });

  public rowDependentClass = (isEven: boolean, isLast: boolean) => {
    let classes = isEven ? 'bg-slate-200' : 'bg-slate-100';

    if (!isLast) {
      classes = `${classes} border-b border-b-slate-300`;
    }

    return classes;
  };

  private viewportInitialized = signal<boolean>(false);

  public dataDependentStyle = computed(() => {
    // As tailwind seems to be treeshaking and therefore removing implementations of classes that are not
    // in the template, we cannot use tailwind classes along with ngClass to set classes
    // dynamically, and therefore need to specify style directly with ngStyle.

    const viewport = this.scrollViewport();
    const initialized = this.viewportInitialized();

    if (viewport === undefined || !initialized) {
      return undefined;
    }

    const columnWidths = this._columnWidths();

    const viewportClientWidth = viewport.measureViewportSize('horizontal');

    const style = {
      display: 'grid',
      width: `${viewportClientWidth}px`,
      height: `${this.itemSize()}px`,
      'grid-template-columns': '',
    };

    const viewportOffsetWidth = viewport.elementRef.nativeElement.offsetWidth;

    const scrollbarWidth = viewportOffsetWidth - viewportClientWidth;

    columnWidths[columnWidths.length - 1] = columnWidths[columnWidths.length - 1] - scrollbarWidth;

    style['grid-template-columns'] = columnWidths.map(width => `${width}px`).join(' ');

    return style;
  });
}
