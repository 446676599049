<div class="relative h-full w-full">
  <div class="absolute inset-0 overflow-auto" (scroll)="onContentScrolled($event)">
    <ng-content />
  </div>
  @if (!isTop() && fadeTop()) {
    <app-border-gradient position="top" />
  }
  @if (!isBottom() && fadeBottom()) {
    <app-border-gradient position="bottom" />
  }
</div>
