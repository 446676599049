import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of, switchMap } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(private keycloakService: KeycloakService) {}

  public login(redirectUri: string | undefined): Observable<void> {
    return from(this.keycloakService.login({ redirectUri: redirectUri }));
  }

  public fetchToken(): Observable<string | null> {
    return of(this.keycloakService.isLoggedIn()).pipe(
      switchMap((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return from(this.keycloakService.getToken());
        } else {
          return of(null);
        }
      })
    );
  }

  public logout(redirectUri: string): Observable<void> {
    localStorage.removeItem('chosenProject'); // ugly
    return from(this.keycloakService.logout(redirectUri));
  }
}
