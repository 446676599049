import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RealtimeRegistrationInterface } from './shared/interfaces/realtime-registration-service';
import { RealtimeClientService } from './shared/services/realtime-client.service';
import { RealtimeEffects } from './shared/store/realtime.effects';
import { realtimeFeature } from './shared/store/realtime.feature';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(realtimeFeature), EffectsModule.forFeature([RealtimeEffects])],
})
export class RealtimeRootModule {}

@NgModule({
  declarations: [],
  imports: [],
})
export class RealtimeFeatureModule {}

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(realtimeFeature)],
})
export class RealtimeModule {
  static forRoot(): ModuleWithProviders<RealtimeRootModule> {
    return {
      ngModule: RealtimeRootModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          useFactory: (realtime: RealtimeClientService) => {
            return () => {};
          },
          deps: [RealtimeClientService],
          multi: true,
        },
      ],
    };
  }

  static forFeature<T extends RealtimeRegistrationInterface>(
    realtimeRegistrationServiceType: Type<T>
  ): ModuleWithProviders<RealtimeFeatureModule> {
    return {
      ngModule: RealtimeFeatureModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (client: RealtimeClientService, injector: Injector) => {
            return () => {
              const registrationService = injector.get(realtimeRegistrationServiceType);
              registrationService.registerMessageHandlers(client);
            };
          },
          deps: [RealtimeClientService, Injector],
          multi: true,
        },
      ],
    };
  }
}
