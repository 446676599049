@for (anchor of page().anchors; track anchor.name) {
  <button mat-menu-item routerLink="{{ page().path }}" fragment="{{ anchor.fragment }}">
    <mat-icon matListItemIcon>{{ anchor.icon }}</mat-icon>

    <span matListItemTitle class="flex flex-row justify-between">
      {{ anchor.name }}

      @if (loading(anchor)) {
        <mat-progress-spinner class="ml-5 h-[24px] w-[24px] align-middle" mode="indeterminate" />
      } @else if (error(anchor)) {
        <mat-icon class="ml-5" [style]="{ color: '#fe621d' }">error_outline</mat-icon>
      } @else if (notFound(anchor)) {
        <mat-icon class="ml-5">help_outline</mat-icon>
      } @else if (anchor.secondaryIcon) {
        <mat-icon class="ml-5" [style]="{ color: anchor.secondaryIconColor }">{{ anchor.secondaryIcon }}</mat-icon>
      }
    </span>
  </button>
}
