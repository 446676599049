import { AuthEffects } from '@features/auth/shared/store/auth.effects';
import { routerReducer, RouterState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  router: RouterState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  router: routerReducer,
};

export const ROOT_EFFECTS = [AuthEffects];
