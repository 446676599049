import { UNKNOWN_FLAGS } from '@features/data-validation/shared/interface/validation-flags';
import { FolderId } from '@features/folders/shared/interface/folder.interface';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import {
  AStrionSignalUploadResult,
  AStrionSignalUploadStatus,
} from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SensorSignal } from '@features/signals/shared/interface/sensor-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { createReducer, on } from '@ngrx/store';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { FilesExplorerActions } from './files-explorer.actions';
import { FILES_EXPLORER_INITIAL_STATE, FilesExplorersState } from './files-explorer.state';

function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const reducer = createReducer(
  FILES_EXPLORER_INITIAL_STATE,
  on(
    FoldersActions.foldersFetchRequested,
    SignalsActions.folderSignalsFetchRequested,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    SignalsActions.folderSignalsFetched,
    (
      state: FilesExplorersState,
      { folderId, signals }: { folderId: FolderId; signals: SensorSignal[] }
    ): FilesExplorersState => ({
      ...state,
      isLoading: false,
      cachedFolder: {
        folderId,
        signals,
      },
    })
  ),
  on(
    SignalsActions.signalDeleted,
    (state: FilesExplorersState, { signalId }): FilesExplorersState => ({
      ...state,
      cachedFolder: {
        ...state.cachedFolder,
        signals: state.cachedFolder.signals.filter(s => s.id != signalId),
      },
    })
  ),
  on(
    SignalsActions.signalUpdated,
    (state: FilesExplorersState, { signal }): FilesExplorersState => ({
      ...state,
      cachedFolder: {
        ...state.cachedFolder,
        signals: state.cachedFolder.signals.map(s =>
          s.id === signal.id ? { ...signal, validationFlags: s.validationFlags } : s
        ),
      },
    })
  ),

  on(
    SignalsActions.signalsUploaded,
    (state: FilesExplorersState, { uploads }: { uploads: AStrionSignalUploadResult[] }): FilesExplorersState => ({
      ...state,
      cachedFolder: {
        ...state.cachedFolder,
        signals: [
          ...state.cachedFolder.signals,
          ...uploads
            .filter(upload => upload.status === AStrionSignalUploadStatus.Success)
            .map(upload => upload.signal)
            .filter(isDefined)
            .filter(signal => signal.folderId === state.cachedFolder.folderId)
            .filter(signal => !state.cachedFolder.signals.some(cachedSignal => cachedSignal.id === signal.id))
            .map(signal => ({ ...signal, status: ProcessingStatus.InProgress, validationFlags: UNKNOWN_FLAGS })),
        ],
      },
    })
  ),
  on(
    FilesExplorerActions.fetchFolderSkipAlreadyCached,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FilesExplorerActions.fetchFolderSkipNoContent,
    (state: FilesExplorersState, { folder }): FilesExplorersState => ({
      ...state,
      isLoading: false,
      cachedFolder: {
        folderId: folder.id,
        signals: [],
      },
    })
  ),
  on(
    FoldersActions.foldersFetchFailed,
    SignalsActions.folderSignalsFetchFailed,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FilesExplorerActions.itemEditionRequested,
    (state: FilesExplorersState, { id }): FilesExplorersState => ({
      ...state,
      currentlyEditedItemId: id,
    })
  ),
  on(
    FilesExplorerActions.editedItemCleared,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      currentlyEditedItemId: null,
    })
  ),
  on(
    SignalsActions.signalsStatusFetched,
    (state: FilesExplorersState, { statuses }): FilesExplorersState => ({
      ...state,
      cachedFolder: {
        ...state.cachedFolder,
        signals: state.cachedFolder.signals.map(signal => ({
          ...signal,
          status: statuses[signal.id] ?? signal.status,
        })),
      },
    })
  ),
  on(
    FilesExplorerActions.validationFlagsFetched,
    (state: FilesExplorersState, action): FilesExplorersState => ({
      ...state,
      cachedFolder: {
        ...state.cachedFolder,
        signals: state.cachedFolder.signals.map(signal =>
          action.signalId === signal.id ? { ...signal, validationFlags: action.validationFlags } : signal
        ),
      },
    })
  )
);
