import { UNKNOWN_FLAGS, ValidationFlags } from '@features/data-validation/shared/interface/validation-flags';
import { validationFlagsFromDto } from '@features/data-validation/shared/utils/validation-flags-mapping';
import { HOME_FOLDER } from '@features/folders/shared/interface/folder.interface';

import { AStrionSignal, AStrionSignalDto, AStrionSignalPayload } from './astrion-signal.interface';
import { SensorSignal, SensorSignalsDto } from './sensor-signal.interface';

export const signal2payload = (signal: AStrionSignal): AStrionSignalPayload => ({
  name: signal.name,
  folderId: signal.folderId === HOME_FOLDER.id ? null : signal.folderId,
});

export const dto2signal = (signalDto: AStrionSignalDto): AStrionSignal => ({
  ...signalDto,
  folderId: signalDto.folderId ?? HOME_FOLDER.id,
  createdAt: new Date(signalDto.createdAt),
  date: signalDto.date ? new Date(signalDto.date) : undefined,
});

export const dto2SensorSignals = (sensorSignalsDto: SensorSignalsDto): SensorSignal[] =>
  sensorSignalsDto.signals.map(signal => {
    let flags: ValidationFlags = UNKNOWN_FLAGS;
    const flagsDto = sensorSignalsDto.validationFlags.find(f => f.signalId === signal.id);
    if (flagsDto) {
      flags = validationFlagsFromDto(flagsDto);
    }
    return {
      ...dto2signal(signal),
      validationFlags: flags,
    };
  });
