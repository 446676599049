import { Component, input } from '@angular/core';
import { ReportPage } from '@features/signal-report/shared/interface/report-page';

@Component({
  selector: 'app-report-navigation-section',
  standalone: true,
  imports: [],
  templateUrl: './report-navigation-section.component.html',
})
export class ReportNavigationSectionComponent {
  page = input.required<ReportPage>();
}
