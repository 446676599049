@if (color(); as color) {
  <span
    class="cursor-pointer"
    [colorPicker]="color"
    cpPosition="left"
    cpWidth="150px"
    cpAlphaChannel="disabled"
    [cpOKButton]="true"
    [cpCancelButton]="true"
    (colorPickerSelect)="onChange($event)"
    (click)="$event.stopPropagation()">
    <span
      [style.backgroundColor]="color"
      class="mr-1 inline-block h-[12px] w-[12px] rounded-sm ring-gray-500 hover:ring-2"></span>
  </span>
}
