import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class InputDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: InputDialogOptions
  ) {}
  pristine = true;

  onEnter(): void {
    if (this.isValid()) {
      this.dialogRef.close(this.options.answer);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  isValid() {
    return this.options.answer && (this.options.isValid === undefined || this.options.isValid(this.options.answer));
  }
}

export interface InputDialogOptions {
  title: string;
  question?: string;
  placeholder: string;
  answer?: string;
  isValid?: (name: string) => boolean;
}
