export function deleteFromImmutableRecord<K extends string | number, T>(
  record: Record<K, T>,
  keyToRemove: K
): Record<K, T> {
  return Object.fromEntries(Object.entries(record).filter(([k]) => k !== keyToRemove)) as Record<K, T>;
}

export function deleteMultipleFromImmutableRecord<K extends string | number, T>(
  record: Record<K, T>,
  keysToRemove: Set<K>
): Record<K, T> {
  return Object.fromEntries(Object.entries(record).filter(([k]) => !keysToRemove.has(k as K))) as Record<K, T>;
}

export function deleteFromImmutableRecordIfExists<K extends string | number, T>(
  record: Record<K, T>,
  keyToRemove: K
): Record<K, T> {
  return record[keyToRemove] !== undefined
    ? (Object.fromEntries(Object.entries(record).filter(([k]) => k !== keyToRemove)) as Record<K, T>)
    : record;
}

export function intersectKeysFromImmutableRecord<K extends string | number, T>(
  record: Record<K, T>,
  newKeys: Set<K>
): Record<K, T> {
  return Object.fromEntries(Object.entries(record).filter(([k]) => newKeys.has(k as K))) as Record<K, T>;
}

export function intersectKeysFromImmutableMap<K, T>(map: Map<K, T>, newKeys: Set<K>): Map<K, T> {
  const toRemove = Array.from(map.keys()).filter(k => !newKeys.has(k));
  if (toRemove.length === 0) {
    return map;
  } else {
    const result = new Map<K, T>(map);
    toRemove.forEach(k => result.delete(k));
    return result;
  }
}

export function deleteFromImmutableSet<T>(set: Set<T>, value: T): Set<T> {
  const newSet = new Set(set);
  newSet.delete(value);
  return newSet;
}

export function deleteFromImmutableSetIfExists<T>(set: Set<T>, value: T): Set<T> {
  if (set.has(value)) {
    const newSet = new Set(set);
    newSet.delete(value);
    return newSet;
  } else {
    return set;
  }
}

export function toggleFromImmutableSet<T>(set: Set<T>, value: T): Set<T> {
  const newSet = new Set(set);
  if (newSet.has(value)) {
    newSet.delete(value);
  } else {
    newSet.add(value);
  }
  return newSet;
}

export function intersectImmutableSets<T>(set1: Set<T>, set2: Set<T>): Set<T> {
  return new Set<T>(Array.from(set1).filter(value => set2.has(value)));
}
