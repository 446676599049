import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataValidationActions } from '@features/data-validation/shared/store/data-validation.actions';
import { selectFoldersExplorerUris } from '@features/files-explorer/shared/store/files-explorer.selectors';
import { PeakIdentificationActions } from '@features/peak-identification/shared/store/peak-identification.actions';
import { SignalOverviewActions } from '@features/signal-overview/shared/store/signal-overview.actions';
import { dto2signal } from '@features/signals/shared/interface/astrion-signal.mappers';
import { SignalsApiService } from '@features/signals/shared/services/signals-api.service';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { AStrionWebError } from '@modules/error-handling/app-error/app-error.class';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs';

import { SignalReportActions } from './signal-report.actions';
import { signalReportFeature } from './signal-report.feature';

@Injectable()
export class SignalReportEffects {
  constructor(
    private actions$: Actions,
    private signalsApi: SignalsApiService,
    private store: Store,
    private router: Router
  ) {}

  tryFetchSignalBaseDataEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      switchMap(({ signalId }) =>
        this.signalsApi.getSignal(signalId).pipe(
          map(dto => SignalReportActions.signalBaseDataFetched({ signalId, signal: dto2signal(dto) })),
          catchApiError(false, apiError =>
            apiError.httpError.status == 404
              ? SignalReportActions.signalBaseDataNotFound({ signalId })
              : SignalReportActions.signalBaseDataFetchFailed({ signalId })
          )
        )
      )
    );
  });

  requestSignalContentResetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      map(({ signalId }) => SignalOverviewActions.signalContentReset({ signalId }))
    );
  });

  requestDataValidationResetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      map(({ signalId }) => DataValidationActions.dataValidationReset({ signalId }))
    );
  });

  requestPeakIdentificationResetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      map(({ signalId }) => PeakIdentificationActions.peakIdentificationReset({ signalId }))
    );
  });

  requestSignalContentFetchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetched),
      map(({ signalId }) => SignalOverviewActions.signalContentFetchRequested({ signalId }))
    );
  });

  requestPeakIdentificationFetchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetched),
      map(({ signalId }) => PeakIdentificationActions.peakIdentificationFetchRequested({ signalId }))
    );
  });

  navigateOnSignalDeletionEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SignalsActions.signalDeleted),
        concatLatestFrom(() => [
          this.store.select(signalReportFeature.selectSignalId),
          this.store.select(selectFoldersExplorerUris),
        ]),
        tap(([{ signalId, folderId }, reportSignalId, foldersUri]) => {
          if (signalId === reportSignalId) {
            this.router.navigateByUrl(foldersUri.folderIdToUri[folderId]);
            throw new AStrionWebError(
              'Current report signal deleted',
              'The signal of the report you were in just got deleted.'
            );
          }
        })
      );
    },
    { dispatch: false }
  );
}
