@if (progress(); as progress) {
  <div class="flex h-full w-full flex-row content-center items-center justify-center">
    <mat-progress-spinner
      [value]="progress.total > 0 ? (100.0 * progress.progress) / progress.total : undefined"
      [mode]="progress.total > 0 && progress.progress < progress.total ? 'determinate' : 'indeterminate'"
      diameter="250" />
    @if (progress.progress < progress.total) {
      <span class="align-center absolute flex flex-col items-center justify-center font-semibold">
        {{ progress | filesizeProgress }}
      </span>
    }
  </div>
}
