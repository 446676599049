import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments/environment.json';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ImageService {
  constructor(private httpClient: HttpClient) {}

  async fetchImageAsBase64(contentPath: string) {
    return lastValueFrom(
      this.httpClient.request('get', `${environment.BASE_API_URL}/proxy/image/${contentPath}`, {
        responseType: 'text',
      })
    );
  }
}
