import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphInput, IndexedDbRowId } from '@astrion-webtools/graph';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

@Component({
  selector: 'app-report-signal-graph',
  standalone: true,
  imports: [GlGraphComponent],
  templateUrl: './report-signal-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSignalGraphComponent {
  samplesId = input.required<IndexedDbRowId>();

  graphData = computed(
    () =>
      ({
        dbName: ASTRION_INDEXEDDB_NAME,
        storeName: ASTRION_INDEXEDDB_TABLES.signals,
        curves: [
          {
            name: 'signal',
            data: {
              indexedDb: {
                id: this.samplesId(),
                valuesField: 'samples',
                xMinField: 'xMin',
                xMaxField: 'xMax',
              },
            },
          },
        ],
      }) as GraphInput
  );
}
