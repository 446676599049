import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { createSelector } from '@ngrx/store';

import { ConnectionStatus } from '../interfaces/connection-status';
import { name, RealtimeState } from './realtime.state';

export const extraSelectors = ({ selectConnectionStatus }: BaseSelectors<typeof name, RealtimeState>) => ({
  selectIsConnected: createSelector(
    selectConnectionStatus,
    (status: ConnectionStatus | undefined): boolean => status === ConnectionStatus.Connected
  ),
});
