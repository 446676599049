import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { createSelector } from '@ngrx/store';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

import { name, SensorGraphsState } from './sensor-graphs.state';

export const extraSelectors = ({ selectCurves }: BaseSelectors<typeof name, SensorGraphsState>) => ({
  selectGraphInput: createSelector(selectCurves, curves => ({
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.trajectoryTrend,
    curves: Object.values(curves).sort((a, b) => a.name.localeCompare(b.name)),
  })),
  selectCurveNameToId: createSelector(
    selectCurves,
    (curves): Record<string, string> =>
      Object.fromEntries(Object.entries(curves).map(([key, curve]) => [curve.name, key]))
  ),
  selectCurveIds: createSelector(selectCurves, curves => Object.keys(curves)),
  selectCurveIdsSet: createSelector(selectCurves, curves => new Set(Object.keys(curves))),
});
