import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, props } from '@ngrx/store';

import { Cycles } from '../interface/cycles';
import { Fusion } from '../interface/fusion';
import { PeakIdentification } from '../interface/peak-identification';

export const PeakIdentificationActions = createActionGroup({
  source: 'PeakIdentification',
  events: {
    peakIdentificationReset: props<{ signalId: AStrionSignalId | null }>(),

    peakIdentificationFetchRequested: props<{ signalId: AStrionSignalId }>(),
    peakIdentificationFetched: props<{ signalId: AStrionSignalId; peakIdentification: PeakIdentification }>(),
    peakIdentificationNotFound: props<{ signalId: AStrionSignalId }>(),
    peakIdentificationFetchFailed: props<{ signalId: AStrionSignalId }>(),

    cyclesFetchRequested: props<{ signalId: AStrionSignalId }>(),
    cyclesFetched: props<{ signalId: AStrionSignalId; cycles: Cycles }>(),
    cyclesNotFound: props<{ signalId: AStrionSignalId }>(),
    cyclesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    fusionFetchRequested: props<{ signalId: AStrionSignalId }>(),
    fusionFetched: props<{ signalId: AStrionSignalId; fusion: Fusion }>(),
    fusionNotFound: props<{ signalId: AStrionSignalId }>(),
    fusionFetchFailed: props<{ signalId: AStrionSignalId }>(),
  },
});
