import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphInput, HightlightIndices, IndexedDbRowId } from '@astrion-webtools/graph';
import { TimeSaturationData } from '@features/data-validation/shared/interface/time-saturation';
import { ValidationStepStatus } from '@features/data-validation/shared/interface/validation-step-status';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

import { StatusBannerComponent } from '../status-banner/status-banner.component';

@Component({
  selector: 'app-time-saturation',
  standalone: true,
  imports: [StatusBannerComponent, GlGraphComponent],
  templateUrl: './time-saturation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSaturationComponent {
  timeSaturation = input.required<TimeSaturationData>();
  samplesId = input.required<IndexedDbRowId>();

  data = computed(
    () =>
      ({
        dbName: ASTRION_INDEXEDDB_NAME,
        storeName: ASTRION_INDEXEDDB_TABLES.signals,
        curves: [
          {
            data: {
              indexedDb: {
                id: this.samplesId(),
                valuesField: 'samples',
                xMinField: 'xMin',
                xMaxField: 'xMax',
              },
            },
          },
        ],
      }) as GraphInput
  );

  public statusMessage = computed(() => {
    return this.timeSaturation().status === ValidationStepStatus.Success
      ? 'No saturation detected in the current signal.'
      : 'Saturation detected in the current signal.';
  });

  public saturationIndices = computed(() => {
    const indices = this.timeSaturation().saturationIndices;
    return {
      indices,
      color: '#DD5746',
    } as HightlightIndices;
  });
}
