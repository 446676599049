import { Component, input, OnDestroy, OnInit, Signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FilesExplorerActions } from '@features/files-explorer/shared/store/files-explorer.actions';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import { ReportPages } from '@features/signal-report/shared/interface/report-page';
import { SignalReportActions } from '@features/signal-report/shared/store/signal-report.actions';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { Store } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { SignalReportDisplayComponent } from './signal-report-display/signal-report-display.component';

@Component({
  selector: 'app-signal-report-signal-id',
  standalone: true,
  imports: [SignalReportDisplayComponent, RouterModule],
  templateUrl: './signal-report-signal-id.component.html',
})
export class SignalReportSignalIdComponent implements OnInit, OnDestroy {
  signalId = input.required<AStrionSignalId>();

  reportTitle: Signal<string> = this.store.selectSignal(signalReportFeature.selectName);
  loadingState: Signal<LoadingState> = this.store.selectSignal(signalReportFeature.selectLoadingState);
  pages: Signal<ReportPages> = this.store.selectSignal(signalReportFeature.selectReportPages);
  folders: Signal<Folder[] | null> = this.store.selectSignal(signalReportFeature.selectSignalFoldersFromHome);

  constructor(private store: Store) {
    this.store.dispatch(FoldersActions.foldersFetchRequestedIfNotInit());
  }
  ngOnInit(): void {
    this.store.dispatch(SignalReportActions.signalBaseDataFetchRequested({ signalId: this.signalId() }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(SignalReportActions.navigatedOut());
  }

  downloadSignal() {
    this.store.dispatch(SignalsActions.signalDownloadRequested({ signalId: this.signalId() }));
  }

  navigateToFolder(folder: Folder) {
    this.store.dispatch(FilesExplorerActions.navigationToFolderRequested({ folder }));
  }
}
