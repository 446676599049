import { Injectable } from '@angular/core';
import {
  DataType,
  HttpWrapperService,
  IndexedDbRowId,
  makeArrayOfField,
  makeBase64Field,
  makeBinaryField,
  makeMultipleFields,
} from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { DataValidationDto } from '@features/data-validation/shared/interface/data-validation';
import { FundamentalPeriodicityTestDto } from '@features/data-validation/shared/interface/fundamental-periodicity';
import { ShannonTestDto } from '@features/data-validation/shared/interface/sampling-test';
import { StationarityDto } from '@features/data-validation/shared/interface/stationarity';
import { TimeSaturationDto } from '@features/data-validation/shared/interface/time-saturation';
import * as fundamentalPediodicityStorage from '@features/data-validation/shared/utils/fundamental-periodicity-storage';
import { CyclesDto } from '@features/peak-identification/shared/interface/cycles';
import { FusionDto } from '@features/peak-identification/shared/interface/fusion';
import { PeakIdentificationDto } from '@features/peak-identification/shared/interface/peak-identification';
import { SignalMetadata } from '@features/signal-overview/shared/interface/signal-metadata';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalReportsApiService {
  constructor(private api: HttpWrapperService) {}

  getSignalBytes(signalId: AStrionSignalId, contentPath: string, metadata: SignalMetadata): Observable<IndexedDbRowId> {
    return this.api.get<IndexedDbRowId>(
      `${environment.BASE_API_URL}/signal-management/signals/data-proxy/${contentPath}`,
      {
        storage: makeBinaryField({
          id: signalId,
          dbName: ASTRION_INDEXEDDB_NAME,
          storeName: ASTRION_INDEXEDDB_TABLES.signals,
          dstName: 'samples',
          dataType: DataType.Float64,
          additionalFields: {
            xMin: 0.0,
            samplingFrequency: metadata.samplingFrequency,
            samplesCount: metadata.samplesCount,
          },
          computedFields: {
            xMax: (_: unknown, additionalFields: { samplingFrequency: number; samplesCount: number }) =>
              (1000.0 * additionalFields.samplesCount) / additionalFields.samplingFrequency,
          },
        }),
      }
    );
  }

  getDataValidation(signalId: AStrionSignalId, samplingFrequency: number): Observable<DataValidationDto> {
    return this.api.get<DataValidationDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/${signalId}`,
      {
        storage: [
          ...fundamentalPediodicityStorage.fromDataValidation(signalId, samplingFrequency),
          makeMultipleFields({
            id: signalId,
            dbName: ASTRION_INDEXEDDB_NAME,
            storeName: ASTRION_INDEXEDDB_TABLES.samplingTests,
            path: 'samplingTest.data',
            additionalFields: {
              freqMin: 0.0,
              freqMax: samplingFrequency / 2.0,
            },
            fields: [
              {
                path: 'linearTestSummary',
                dstName: 'linear',
              },
              {
                path: 'dbTestSummary',
                dstName: 'db',
              },
              {
                path: 'noiseTestSummary',
                dstName: 'noise',
              },
            ],
            subfields: [
              makeBase64Field({
                path: 'spectrumAmplitudes',
                dstName: 'amplitudes',
                dataType: DataType.Float64,
              }),
            ],
          }),
        ],
      }
    );
  }

  getTimeSaturationTestData(signalId: AStrionSignalId): Observable<TimeSaturationDto> {
    return this.api.get<TimeSaturationDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/time-saturation-test/${signalId}`
    );
  }

  getSamplingTestData(signalId: AStrionSignalId, samplingFrequency: number): Observable<ShannonTestDto> {
    return this.api.get<ShannonTestDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/sampling-test/${signalId}`,
      {
        storage: [
          makeMultipleFields({
            id: signalId,
            dbName: ASTRION_INDEXEDDB_NAME,
            storeName: ASTRION_INDEXEDDB_TABLES.samplingTests,
            path: 'data',
            additionalFields: {
              freqMin: 0.0,
              freqMax: samplingFrequency / 2.0,
            },
            fields: [
              {
                path: 'linearTestSummary',
                dstName: 'linear',
              },
              {
                path: 'dbTestSummary',
                dstName: 'db',
              },
              {
                path: 'noiseTestSummary',
                dstName: 'noise',
              },
            ],
            subfields: [
              makeBase64Field({
                path: 'spectrumAmplitudes',
                dstName: 'amplitudes',
                dataType: DataType.Float64,
              }),
            ],
          }),
        ],
      }
    );
  }

  getStationarityTestData(signalId: AStrionSignalId): Observable<StationarityDto> {
    return this.api.get<StationarityDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/stationarity-test/${signalId}`
    );
  }

  getFundamentalPeriodicityTestData(
    signalId: AStrionSignalId,
    samplingFrequency: number
  ): Observable<FundamentalPeriodicityTestDto> {
    return this.api.get<FundamentalPeriodicityTestDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/fundamental-periodicity-test/${signalId}`,
      {
        storage: fundamentalPediodicityStorage.fromFundamentalPeriodicity(signalId, samplingFrequency),
      }
    );
  }

  getPeakIdentification(signalId: AStrionSignalId): Observable<PeakIdentificationDto> {
    return this.api.get<PeakIdentificationDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/peak-identification/${signalId}`,
      {
        storage: [
          makeArrayOfField({
            id: signalId,
            path: 'cycles.data',
            dbName: ASTRION_INDEXEDDB_NAME,
            storeName: ASTRION_INDEXEDDB_TABLES.cycles,
            subfields: [
              makeBase64Field({
                id: 'cycle',
                path: 'spectrum.amplitudes',
                dataType: DataType.Float64,
                copyWithFields: [
                  {
                    path: 'spectrum.freqMin',
                    dstName: 'freqMin',
                  },
                  {
                    path: 'spectrum.freqMax',
                    dstName: 'freqMax',
                  },
                ],
              }),
              makeBase64Field({
                id: 'noise',
                path: 'noise.amplitudes',
                dataType: DataType.Float64,
                copyWithFields: [
                  {
                    path: 'noise.freqMin',
                    dstName: 'freqMin',
                  },
                  {
                    path: 'noise.freqMax',
                    dstName: 'freqMax',
                  },
                ],
              }),
            ],
          }),
          makeMultipleFields({
            id: signalId,
            path: 'fusion.data',
            dbName: ASTRION_INDEXEDDB_NAME,
            storeName: ASTRION_INDEXEDDB_TABLES.fusion,
            fields: ['noiseArea'],
            subfields: [
              makeBase64Field({
                id: 'min',
                path: 'minAmplitudes',
                dstName: 'amplitudes',
                dataType: DataType.Float64,
                copyWithFields: ['freqMin', 'freqMax'],
              }),
              makeBase64Field({
                id: 'max',
                path: 'maxAmplitudes',
                dstName: 'amplitudes',
                dataType: DataType.Float64,
                copyWithFields: ['freqMin', 'freqMax'],
              }),
            ],
          }),
        ],
      }
    );
  }

  getCyclesData(signalId: AStrionSignalId): Observable<CyclesDto> {
    return this.api.get<CyclesDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/peak-identification/cycles/${signalId}`,
      {
        storage: [
          makeArrayOfField({
            id: signalId,
            path: 'data',
            dbName: ASTRION_INDEXEDDB_NAME,
            storeName: ASTRION_INDEXEDDB_TABLES.cycles,
            subfields: [
              makeBase64Field({
                id: 'cycle',
                path: 'spectrum.amplitudes',
                dataType: DataType.Float64,
                copyWithFields: [
                  {
                    path: 'spectrum.freqMin',
                    dstName: 'freqMin',
                  },
                  {
                    path: 'spectrum.freqMax',
                    dstName: 'freqMax',
                  },
                ],
              }),
              makeBase64Field({
                id: 'noise',
                path: 'noise.amplitudes',
                dataType: DataType.Float64,
                copyWithFields: [
                  {
                    path: 'noise.freqMin',
                    dstName: 'freqMin',
                  },
                  {
                    path: 'noise.freqMax',
                    dstName: 'freqMax',
                  },
                ],
              }),
            ],
          }),
        ],
      }
    );
  }

  getFusionData(signalId: AStrionSignalId): Observable<FusionDto> {
    return this.api.get<FusionDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/peak-identification/fusion/${signalId}`,
      {
        storage: [
          makeMultipleFields({
            id: signalId,
            path: 'data',
            dbName: ASTRION_INDEXEDDB_NAME,
            storeName: ASTRION_INDEXEDDB_TABLES.fusion,
            fields: ['noiseArea'],
            subfields: [
              makeBase64Field({
                id: 'min',
                path: 'minAmplitudes',
                dstName: 'amplitudes',
                dataType: DataType.Float64,
                copyWithFields: ['freqMin', 'freqMax'],
              }),
              makeBase64Field({
                id: 'max',
                path: 'maxAmplitudes',
                dstName: 'amplitudes',
                dataType: DataType.Float64,
                copyWithFields: ['freqMin', 'freqMax'],
              }),
            ],
          }),
        ],
      }
    );
  }
}
