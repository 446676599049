import { Folder, FolderId } from '@features/folders/shared/interface/folder.interface';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { SensorSignal } from '@features/signals/shared/interface/sensor-signal.interface';

export enum ExplorerItemType {
  Folder,
  Signal,
  SensorSignal,
}

export type ExplorerItemId = FolderId | AStrionSignalId;

export interface ExplorerItem {
  type: ExplorerItemType;
  object: Folder | SensorSignal;
}

export interface ExplorerFolderItem extends ExplorerItem {
  type: ExplorerItemType.Folder;
  object: Folder;
}

export interface ExplorerSensorSignalItem extends ExplorerItem {
  type: ExplorerItemType.SensorSignal;
  object: SensorSignal;
}

export function isSignalItem(explorerItem: ExplorerItem): explorerItem is ExplorerSensorSignalItem {
  return explorerItem.type == ExplorerItemType.SensorSignal;
}

export function isFolderItem(explorerItem: ExplorerItem): explorerItem is ExplorerFolderItem {
  return explorerItem.type == ExplorerItemType.Folder;
}
