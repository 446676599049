import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardFunction } from '@features/auth/auth.guard';
import { AUTH_ROUTES } from '@features/auth/auth.routes';
import { projectGuardFunction } from '@features/auth/project.guard';
import { FILES_EXPLORER_ROUTES } from '@features/files-explorer/files-explorer.routes';
import { FILES_EXPLORER_ROOT } from '@features/files-explorer/shared/utils/files-explorer-root';
import { SIGNAL_REPORT_ROUTES } from '@features/signal-report/signal-report.routes';

const APP_ROUTES: Routes = [
  {
    path: 'auth',
    children: AUTH_ROUTES,
  },
  {
    path: '',
    canActivate: [authGuardFunction, projectGuardFunction],
    pathMatch: 'prefix',
    children: [
      {
        path: FILES_EXPLORER_ROOT,
        children: FILES_EXPLORER_ROUTES,
      },
      {
        path: 'signals/report',
        children: SIGNAL_REPORT_ROUTES,
      },
      {
        path: '**',
        redirectTo: 'explore',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
