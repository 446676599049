import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingLogoComponent } from '@components/loading-logo/loading-logo.component';
import { ReportAnchor, ReportPage } from '@features/signal-report/shared/interface/report-page';
import { MaterialModule } from '@modules/material.module';
import { loaded, loading, loadingError, notFound } from '@shared/interfaces/loading-state';

@Component({
  selector: 'app-report-navigation-menu',
  standalone: true,
  imports: [RouterModule, MaterialModule, LoadingLogoComponent],
  templateUrl: './report-navigation-menu.component.html',
})
export class ReportNavigationMenuComponent {
  page = input.required<ReportPage>();

  loaded = (anchor: ReportAnchor): boolean => loaded(anchor);
  loading = (anchor: ReportAnchor): boolean => loading(anchor);
  error = (anchor: ReportAnchor): boolean => loadingError(anchor);
  notFound = (anchor: ReportAnchor): boolean => notFound(anchor);
}
