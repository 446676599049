import { Injectable } from '@angular/core';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { HOME_FOLDER } from '../interface/folder.interface';
import { FoldersActions } from '../store/folders.actions';
import { selectFolder } from '../store/folders.selectors';
import { FolderCreatedMessage, FolderDeletedMessage, FolderEditedMessage } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class FoldersRealtimeRegistrationService {
  constructor(private store: Store) {}

  public registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'FolderCreated', callback: this.folderCreatedHandler },
      { messageType: 'FolderDeleted', callback: this.folderDeletedHandler },
      { messageType: 'FolderEdited', callback: this.folderEditedHandler },
    ]);
  }

  private folderCreatedHandler = (msg: unknown): void => {
    const folderData = msg as FolderCreatedMessage;

    const newFolder = {
      id: folderData.folderId,
      name: folderData.name,
      signalsCount: 0,
      isLeaf: true,
      parentId: folderData.parentId ?? HOME_FOLDER.parentId,
    };

    this.store.dispatch(FoldersActions.folderCreated({ folder: newFolder }));
  };

  private folderDeletedHandler = async (msg: unknown): Promise<void> => {
    const folderData = msg as FolderDeletedMessage;

    const existingFolder = await firstValueFrom(this.store.select(selectFolder(folderData.folderId)));

    if (!existingFolder) {
      return;
    }

    const parentId = folderData.parentId ?? HOME_FOLDER.id;

    this.store.dispatch(FoldersActions.folderDeleted({ folderId: folderData.folderId, parentId }));
  };

  private folderEditedHandler = async (msg: unknown): Promise<void> => {
    const folderData = msg as FolderEditedMessage;

    const folder = await firstValueFrom(this.store.select(selectFolder(folderData.folderId)));

    if (folder === undefined) {
      return;
    }

    const folderFromMessage = {
      id: folderData.folderId,
      name: folderData.name,
      signalsCount: folderData.signalsCount,
      isLeaf: folderData.childrenCount === 0,
      parentId: folderData.parentId ?? HOME_FOLDER.parentId,
    };

    if (
      folder.name === folderFromMessage.name &&
      folder.signalsCount === folderFromMessage.signalsCount &&
      folder.parentId === folderFromMessage.parentId &&
      folder.isLeaf === folderFromMessage.isLeaf
    ) {
      return;
    }

    this.store.dispatch(FoldersActions.folderUpdated({ folder: folderFromMessage }));
  };
}
