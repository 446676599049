import { FlattenedFolderTree, FolderId, FoldersMap } from '../interface/folder.interface';
import { getDescentSet } from '../testing/tree-paths';
import { addSignalsToFolder } from './add-signals-to-folder';

export const deleteFolder = (flattenedFoldersTree: FlattenedFolderTree, folderId: FolderId): FlattenedFolderTree => {
  if (!flattenedFoldersTree.folders[folderId]) {
    return flattenedFoldersTree;
  }

  return deleteFolderWithDescendance(flattenedFoldersTree, folderId, getDescentSet(flattenedFoldersTree, folderId));
};

export const deleteFolderWithDescendance = (
  flattenedFoldersTree: FlattenedFolderTree,
  folderId: FolderId,
  descendance: Set<FolderId>
): FlattenedFolderTree => {
  const folder = flattenedFoldersTree.folders[folderId];

  if (!folder) {
    return flattenedFoldersTree;
  }

  const parentId = folder.parentId;
  if (parentId === folderId) {
    throw new Error('Cannot delete root folder');
  }

  // Filter record and update signalsCount in ancestors
  const signalsCount = folder.signalsCount;
  const folders: FoldersMap = addSignalsToFolder(
    filterRecordKeys(flattenedFoldersTree.folders, descendance),
    parentId,
    -signalsCount
  );
  const children: Record<FolderId, FolderId[]> = filterChildren(
    flattenedFoldersTree.children,
    descendance,
    folderId,
    parentId
  );

  // Update parent isLeaf if necessary
  if (children[parentId].length === 0) {
    folders[parentId].isLeaf = true;
  }

  return {
    folders,
    children,
  };
};

function filterRecordKeys<T>(record: Record<FolderId, T>, toRemove: Set<FolderId>): Record<FolderId, T> {
  return Object.fromEntries(Object.entries(record).filter(([key]) => !toRemove.has(key as FolderId)));
}

function filterChildren(
  children: Record<FolderId, FolderId[]>,
  toRemove: Set<FolderId>,
  deletedId: FolderId,
  deletedParentId: FolderId
): Record<FolderId, FolderId[]> {
  const filtered = filterRecordKeys(children, toRemove);
  filtered[deletedParentId] = filtered[deletedParentId].filter(id => id != deletedId);
  return filtered;
}
