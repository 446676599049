import { IndexedDbRowId } from '@astrion-webtools/graph';

export interface TrajectoryTrendDto {
  name: string;
  interlacedValues: IndexedDbRowId;
}

export interface TrajectoryTrend {
  name: string;
  interlacedValues: IndexedDbRowId;
}

export enum TrendType {
  Trajectory = 0,
}
