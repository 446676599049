import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import {
  ExplorerItem,
  isFolderItem,
  isSignalItem,
} from '@features/files-explorer/shared/interface/explorer-item.interface';
import { folderHasSignals } from '@features/files-explorer/shared/utils/folder-has-signals';
import { FolderStatus } from '@features/folders/shared/interface/folder.interface';
import { MaterialModule } from '@modules/material.module';
import { ItemDescription } from '@shared/interfaces/item-description';
import { parseProcessingState } from '@shared/interfaces/processing-status';

@Component({
  selector: 'app-item-icon',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './item-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemIconComponent {
  item = input.required<ExplorerItem>();

  itemDescription: Signal<ItemDescription> = computed(() => {
    const item = this.item();
    if (isFolderItem(item)) {
      switch (item.object.status) {
        case FolderStatus.Updating:
          return {
            icon: 'refresh',
            itemClass: 'animate-spin',
            color: 'gray',
            tooltip: 'Updating',
          };
        default:
          if (folderHasSignals(item.object)) {
            return {
              icon: 'source',
              color: '#fdc630',
              tooltip: 'Folder with signals',
            };
          } else {
            return {
              icon: 'folder_special',
              color: '#fdc630',
              tooltip: 'Node folder',
            };
          }
      }
    } else if (isSignalItem(item)) {
      return parseProcessingState(item.object.status);
    } else {
      return {
        icon: 'description',
        color: 'black',
        tooltip: 'Unknown file format',
      };
    }
  });
}
