import { createReducer, on } from '@ngrx/store';

import { SensorGraphsActions } from './sensor-graphs.actions';
import { SENSOR_GRAPHS_INITIAL_STATE, SensorGraphsState } from './sensor-graphs.state';

export const reducer = createReducer(
  SENSOR_GRAPHS_INITIAL_STATE,
  on(
    SensorGraphsActions.clearCurves,
    (state): SensorGraphsState => ({
      ...state,
      curves: {},
    })
  ),
  on(
    SensorGraphsActions.addCurve,
    (state, { curveId, curve }): SensorGraphsState => ({
      ...state,
      curves: {
        ...state.curves,
        [curveId]: curve,
      },
    })
  ),
  on(
    SensorGraphsActions.removeCurve,
    (state, { curveId }): SensorGraphsState => ({
      ...state,
      curves: Object.fromEntries(Object.entries(state.curves).filter(([key]) => key !== curveId)),
    })
  )
);
