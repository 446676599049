import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { AStrionSignalsStatusDto, AStrionSignalsStatusMap } from './astrion-signals-status.interface';

export const string2Status = (s: string): ProcessingStatus => {
  switch (s) {
    case 'Completed':
      return ProcessingStatus.Completed;
    case 'InProgress':
      return ProcessingStatus.InProgress;
    case 'Degraded':
      return ProcessingStatus.Degraded;
    case 'Dirty':
      return ProcessingStatus.Dirty;
    default:
      return ProcessingStatus.Unknown;
  }
};

const combineStatuses = (statuses: ProcessingStatus[]): ProcessingStatus =>
  statuses.reduce((prev, cur) => Math.min(prev, cur), ProcessingStatus.Completed);

export const dto2Statuses = (dtos: AStrionSignalsStatusDto[]): AStrionSignalsStatusMap => {
  const statuses: AStrionSignalsStatusMap[] = dtos.map(dto =>
    Object.fromEntries(dto.statuses.map(s => [s.signalId, string2Status(s.status)]))
  );

  const ids: AStrionSignalId[] = [
    ...new Set<AStrionSignalId>(statuses.map(s => Object.keys(s) as AStrionSignalId[]).flat()),
  ];

  return Object.fromEntries(
    ids.map((id: AStrionSignalId) =>
      statuses.every(s => id in s)
        ? [id as AStrionSignalId, combineStatuses(statuses.map(s => s[id]))]
        : [id as AStrionSignalId, ProcessingStatus.Degraded]
    )
  );
};
