import { JwtHelperService } from '@auth0/angular-jwt';
import environment from '@environments/environment.json';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { Store } from '@ngrx/store';
import { KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';

import { AuthActions } from './shared/store/auth.actions';

export function initializer(
  keycloak: KeycloakService,
  store: Store,
  realtimeClient: RealtimeClientService
): () => Promise<boolean> {
  const options: KeycloakOptions = {
    config: {
      url: environment.OAUTH_URL,
      realm: environment.OAUTH_REALM,
      clientId: environment.OAUTH_CLIENT_ID,
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
  };

  keycloak.keycloakEvents$.subscribe({
    async next(event) {
      if (event.type == KeycloakEventType.OnAuthSuccess) {
        store.dispatch(AuthActions.tokenFetchRequested());
        await planUpdateToken(keycloak);
      } else if (event.type == KeycloakEventType.OnAuthRefreshSuccess) {
        realtimeClient.reconnect();
        await planUpdateToken(keycloak);
      } else if (event.type == KeycloakEventType.OnTokenExpired) {
        keycloak.updateToken(20);
        realtimeClient.disconnect();
      }
    },
  });

  return () => keycloak.init(options);
}

export async function planUpdateToken(keycloak: KeycloakService) {
  const token = await keycloak.getToken();
  const jwtHelperService = new JwtHelperService();
  const expiresIn = jwtHelperService.decodeToken(token).exp;
  const margin = 10; // number of seconds before expiration to refresh
  const expirationMinusMargin = expiresIn - Math.ceil(new Date().getTime() / 1000) - margin;

  // we plan the update of the token in (expiresIn - 10 seconds)
  setTimeout(() => {
    keycloak.updateToken(20);
  }, expirationMinusMargin * 1000);
}
