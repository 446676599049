import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DataValidationRealtimeRegistrationService } from './shared/realtime/data-validation-realtime-service';
import { DataValidationSensorApiService } from './shared/services/data-validation-sensor-api.service';
import { ValidationFlagsApiService } from './shared/services/validation-flags-api.service';
import { DataValidationEffects } from './shared/store/data-validation.effects';
import { dataValidationFeature } from './shared/store/data-validation.feature';

@NgModule({
  declarations: [],
  providers: [ValidationFlagsApiService, DataValidationSensorApiService],
  imports: [
    StoreModule.forFeature(dataValidationFeature),
    EffectsModule.forFeature([DataValidationEffects]),
    RealtimeModule.forFeature(DataValidationRealtimeRegistrationService),
  ],
})
export class DataValidationModule {}
