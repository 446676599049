import { RetryContext } from '@microsoft/signalr';

export class AStrionRealtimeRetryPolicy implements signalR.IRetryPolicy {
  private _retryDurationsSeconds = [0, 2, 10, 30];

  public nextRetryDelayInMilliseconds = (retryContext: RetryContext) => {
    const retryDurationIndex = Math.min(retryContext.previousRetryCount, this._retryDurationsSeconds.length - 1);

    return this._retryDurationsSeconds[retryDurationIndex];
  };
}
