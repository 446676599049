import { Component, computed, input, InputSignal } from '@angular/core';
import {
  Flag,
  getPercentageLabel,
  getShannonLabel,
  getTimeSaturationLabel,
  PercentageFlag,
  ShannonFlag,
} from '@features/data-validation/shared/interface/validation-flags';
import {
  getValidationStepStatusColor,
  getValidationStepStatusTextColor,
} from '@features/data-validation/shared/interface/validation-step-status';

const colors = (flag: InputSignal<Flag>) =>
  computed(() => `${getValidationStepStatusColor(flag().status)} ${getValidationStepStatusTextColor(flag().status)}`);

@Component({
  selector: 'app-percentage-validation-flag',
  standalone: true,
  imports: [],
  templateUrl: './validation-flag.component.html',
  styles: ``,
})
export class PercentageValidationFlagComponent {
  flag = input.required<PercentageFlag>();
  colors = colors(this.flag);
  message = computed(() => getPercentageLabel(this.flag()));
}

@Component({
  selector: 'app-shannon-validation-flag',
  standalone: true,
  imports: [],
  templateUrl: './validation-flag.component.html',
  styles: ``,
})
export class ShannonValidationFlagComponent {
  flag = input.required<ShannonFlag>();
  colors = colors(this.flag);
  message = computed(() => getShannonLabel(this.flag()));
}

@Component({
  selector: 'app-time-saturation-validation-flag',
  standalone: true,
  imports: [],
  templateUrl: './validation-flag.component.html',
  styles: ``,
})
export class TimeSaturationValidationFlagComponent {
  flag = input.required<Flag>();
  colors = colors(this.flag);
  message = computed(() => getTimeSaturationLabel(this.flag()));
}
