import { Progress } from '@app-types/progress.interface';
import { FolderId } from '@features/folders/shared/interface/folder.interface';
import { AStrionSignalsStatusMap } from '@features/signals-status/shared/interface/astrion-signals-status.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AStrionSignal, AStrionSignalId } from '../interface/astrion-signal.interface';
import { AStrionSignalUploadResult } from '../interface/astrion-signal-upload-result.interface';
import { SensorSignal } from '../interface/sensor-signal.interface';

export const SignalsActions = createActionGroup({
  source: 'Signals',
  events: {
    folderSignalsFetchRequested: props<{ folderId: FolderId }>(),
    folderSignalsFetchFailed: emptyProps(),
    folderSignalsFetched: props<{ folderId: FolderId; signals: SensorSignal[] }>(),

    signalFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalFetchFailed: emptyProps(),
    signalFetched: props<{ signal: AStrionSignal }>(),

    signalDeletionRequested: props<{ signal: AStrionSignal }>(),
    signalDeleted: props<{ signalId: AStrionSignalId; folderId: FolderId }>(),
    signalDeletionFailed: emptyProps(),

    signalUpdateRequested: props<{ signal: AStrionSignal }>(),
    signalUpdated: props<{ signal: AStrionSignal }>(),
    signalUpdateFailed: props<{ signal: AStrionSignal }>(),

    signalsUploadRequested: props<{ folderId: FolderId; files: File[] }>(),
    signalsUploadStarted: emptyProps(),
    signalsUploadMadeProgress: props<Progress>(),
    signalsUploaded: props<{ uploads: AStrionSignalUploadResult[] }>(),
    signalsUploadFailed: emptyProps(),
    signalsUploadCancellationRequested: emptyProps(),
    signalsUploadCanceled: emptyProps(),

    signalDataFetchRequested: props<{ signalId: AStrionSignalId }>(),

    signalDownloadRequested: props<{ signalId: AStrionSignalId }>(),
    signalDownloaded: emptyProps(),
    signalDownloadFailed: emptyProps(),

    signalsStatusFetchRequested: props<{ signalIds: AStrionSignalId[] }>(),
    signalsStatusFetched: props<{ statuses: AStrionSignalsStatusMap }>(),
    signalsStatusFetchFailed: emptyProps(),
  },
});
