import { Routes } from '@angular/router';

import { FilesExplorerComponent } from './views/files-explorer/files-explorer.component';

export const FILES_EXPLORER_ROUTES: Routes = [
  {
    path: '**',
    component: FilesExplorerComponent,
    data: {
      fileExplorer: true,
    },
  },
];
