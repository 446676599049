import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments/environment.json';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import {
  AStrionSignalsStatusDto,
  AStrionSignalsStatusPayload,
} from '@features/signals-status/shared/interface/astrion-signals-status.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalsStatusApiService {
  constructor(private httpClient: HttpClient) {}

  getDataValidationSignalsStatus(signalIds: AStrionSignalId[]): Observable<AStrionSignalsStatusDto> {
    return this.httpClient.post<AStrionSignalsStatusDto>(`${environment.BASE_API_URL}/signals/status/data-validation`, {
      signalIds,
    } as AStrionSignalsStatusPayload);
  }

  getPeakIdentificationSignalsStatus(signalIds: AStrionSignalId[]): Observable<AStrionSignalsStatusDto> {
    return this.httpClient.post<AStrionSignalsStatusDto>(
      `${environment.BASE_API_URL}/signals/status/peak-identification`,
      {
        signalIds,
      } as AStrionSignalsStatusPayload
    );
  }
}
