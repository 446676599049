import { effect, Injectable } from '@angular/core';
import environment from '@environments/environment.json';
import { Project } from '@features/auth/shared/interfaces/project';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';

import { ConnectionStatus } from '../interfaces/connection-status';
import { RealtimeActions } from '../store/realtime.actions';
import { realtimeFeature } from '../store/realtime.feature';
import { AStrionRealtimeRetryPolicy } from '../utilities/realtime-reconnect-policy';

export type MessageCallback = (msg: unknown) => void | Promise<void>;
export type MultipleMessagesCallback = (msg: unknown[]) => void | Promise<void>;

export interface MessageHandler {
  messageType: string;
  callback: MessageCallback;
}

export interface MultipleMessagesHandler {
  messageType: string;
  callback: MultipleMessagesCallback;
}

interface IRealtimeMessage {
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class RealtimeClientService {
  private _hubConnection?: signalR.HubConnection;
  private _chosenProject = this.store.selectSignal(authFeature.selectChosenProject);
  private _currentStatus = this.store.selectSignal(realtimeFeature.selectConnectionStatus);
  private _withCredentials: boolean;
  private _messageHandlersMap: Map<string, MessageCallback[]> = new Map<string, MessageCallback[]>();
  private _multipleMessagesHandlersMap: Map<string, MultipleMessagesCallback[]> = new Map<
    string,
    MultipleMessagesCallback[]
  >();
  private _internalStatus = ConnectionStatus.Disconnected;

  constructor(
    private store: Store,
    private keycloak: KeycloakService
  ) {
    this._withCredentials = environment.ENV !== 'development';

    effect(() => {
      const chosenProject = this._chosenProject();

      this.disconnect();

      if (chosenProject === null) {
        return;
      }

      this.connect(chosenProject);
    });
  }

  // handlers that consumes ONE message
  public registerMessageHandlers(messageHandlers: MessageHandler[]): void {
    messageHandlers.forEach((handler: MessageHandler) => {
      const messageCallbacks = this._messageHandlersMap.get(handler.messageType);

      if (messageCallbacks === undefined) {
        this._messageHandlersMap.set(handler.messageType, [handler.callback]);
      } else {
        messageCallbacks.push(handler.callback);
      }
    });
  }

  // handlers that consume MULTIPLE messages
  public registerMultipleMessagesHandlers(messagesHandlers: MultipleMessagesHandler[]): void {
    messagesHandlers.forEach((handler: MultipleMessagesHandler) => {
      const messageCallbacks = this._multipleMessagesHandlersMap.get(handler.messageType);

      if (messageCallbacks === undefined) {
        this._multipleMessagesHandlersMap.set(handler.messageType, [handler.callback]);
      } else {
        messageCallbacks.push(handler.callback);
      }
    });
  }

  private async connect(chosenProject: Project): Promise<void> {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        `${environment.BASE_API_URL}/notification-center/notifications?Chosenproject=${chosenProject.fullname}`,
        {
          withCredentials: this._withCredentials,
          accessTokenFactory: () => this.keycloak.getToken(),
        }
      )
      .withAutomaticReconnect(new AStrionRealtimeRetryPolicy())
      .configureLogging(signalR.LogLevel.None)
      .build();

    this._hubConnection.on('ReceiveMessage', this.handleReceivedMessage);

    this._hubConnection.on('ReceiveMessages', async msgs => {
      // in priority we find a multiple messages callback (all messages have the same type, forced in backend)
      const msgType = msgs[0].type;
      const multipleMessageCallbacks = this._multipleMessagesHandlersMap.get(msgType);

      if (multipleMessageCallbacks !== undefined) {
        multipleMessageCallbacks.forEach(async (callback: MultipleMessagesCallback) => {
          await callback(msgs);
        });
      } else {
        // if not found, we use a unique message handler multiple times
        for (const msg of msgs) {
          await this.handleReceivedMessage(msg);
        }
      }
    });

    this._hubConnection.onclose(() => {
      this.setConnectionStatus(ConnectionStatus.Disconnected, 500);
    });

    this._hubConnection.onreconnecting(() => {
      // we dispatch Disconnected because this means the connection has been lost
      // avoid sending the event if a reconnection happened before 1s
      this.setConnectionStatus(ConnectionStatus.Disconnected, 1000);
    });

    this._hubConnection.onreconnected(() => {
      this.setConnectionStatus(ConnectionStatus.Connected);
    });

    await this.startHubConnection(this._hubConnection);
  }

  private handleReceivedMessage = async (msg: IRealtimeMessage) => {
    const messageCallbacks = this._messageHandlersMap.get(msg.type);

    if (messageCallbacks === undefined) {
      return;
    }

    messageCallbacks.forEach(async (callback: MessageCallback) => {
      await callback(msg);
    });
  };

  private async startHubConnection(hubConnection: signalR.HubConnection, forceDisconnect: boolean = false) {
    if (forceDisconnect) {
      await this.disconnect();
    }

    // avoid starting a connection that has already been started
    if (hubConnection.state != signalR.HubConnectionState.Connected) {
      try {
        await hubConnection.start();
        this.setConnectionStatus(ConnectionStatus.Connected);
      } catch (err) {
        this.setConnectionStatus(ConnectionStatus.Disconnected, 500);
        setTimeout(() => this.startHubConnection(hubConnection), 5000);
      }
    }
  }

  public async disconnect(): Promise<void> {
    if (
      this._hubConnection &&
      this._hubConnection.state !== signalR.HubConnectionState.Disconnected &&
      this._hubConnection.state !== signalR.HubConnectionState.Disconnecting
    ) {
      try {
        await this._hubConnection.stop();
      } catch (err) {
        // ignore all errors on disconnect
      }
    }
  }

  public reconnect(): void {
    this.startHubConnection(this._hubConnection!, true);
  }

  private setConnectionStatus(status: ConnectionStatus, delay?: number | undefined): void {
    this._internalStatus = status;
    const previousStatus = this._currentStatus();
    if (delay !== undefined && delay > 0) {
      setTimeout(() => {
        if (this._internalStatus == status) {
          // we only dispatch the connection status changed if the internal status is still the same
          this.store.dispatch(RealtimeActions.connectionStatusChanged({ status, previousStatus }));
        }
      }, delay);
    } else {
      this.store.dispatch(RealtimeActions.connectionStatusChanged({ status, previousStatus }));
    }
  }
}
