<div class="mb-6 flex w-full flex-col gap-8">
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.peakIdentification.anchors.cycles">
    <h3 appReportAnchorTitle>Cycles</h3>
    @if (ready(cycles())) {
      <app-cycles [cycles]="cycles().data!" />
    } @else {
      <app-result-unavailable [loadableData]="cycles()" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.peakIdentification.anchors.fusion">
    <h3 appReportAnchorTitle>Fusion</h3>
    @if (ready(fusion())) {
      <app-fusion [fusion]="fusion().data!" />
    } @else {
      <app-result-unavailable [loadableData]="fusion()" class="h-32 w-full" />
    }
  </div>
</div>
