import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, signal, viewChild, WritableSignal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DragAndDropComponent } from '@components/drag-and-drop/drag-and-drop.component';
import { MaterialModule } from '@modules/material.module';
import { FileTypeInfoService } from '@services/file-type-info.service';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MaterialModule, DragAndDropComponent],
})
export class UploadFilesComponent {
  public choiceButton = viewChild('choiceButton', { read: HTMLButtonElement });
  public uploadButton = viewChild('uploadButton', { read: HTMLButtonElement });

  constructor(
    public fileInfo: FileTypeInfoService,
    private dialogRef: MatDialogRef<UploadFilesComponent>
  ) {
    effect(() => {
      if (!this.isEmpty()) {
        const uploadButton = this.uploadButton();

        if (uploadButton === undefined) {
          return;
        }

        uploadButton.disabled = false;
        uploadButton.focus();
      }
    });

    effect(() => {
      const choiceButton = this.choiceButton();

      if (choiceButton !== undefined) {
        choiceButton.focus();
      }
    });
  }

  public selectedFiles: WritableSignal<File[]> = signal<File[]>([]);
  public isDragging = signal<boolean>(false);

  showDragAndDrop = computed(() => {
    return this.isDragging() || this.selectedFiles().length <= 0;
  });

  isEmpty = computed(() => this.selectedFiles().length === 0);

  public onFileSelected(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList && fileList.length > 0) {
      const files: File[] = Array.from(fileList);
      this.addFiles(files);
    }
    element.value = '';
  }

  public addFiles(newFiles: File[]) {
    this.selectedFiles.update(files => [...files, ...newFiles]);
  }

  removeFile(index: number) {
    this.selectedFiles.update(files => [...files.slice(0, index), ...files.slice(index + 1)]);
  }

  uploadFiles() {
    this.dialogRef.close(this.selectedFiles());
  }

  onDragStart() {
    this.isDragging.set(true);
  }

  onDragEnd() {
    this.isDragging.set(false);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
