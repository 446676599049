import { Injectable } from '@angular/core';
import { isProgress, Progress } from '@app-types/progress.interface';
import { FolderId, HOME_FOLDER } from '@features/folders/shared/interface/folder.interface';
import { dto2Statuses } from '@features/signals-status/shared/interface/astrion-signals-status.mapper';
import { SignalsStatusApiService } from '@features/signals-status/shared/services/signals-status-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concat, concatMap, forkJoin, map, of, takeWhile, withLatestFrom } from 'rxjs';

import { AStrionSignalDto, UploadResponseAStrionSignalDto } from '../interface/astrion-signal.interface';
import { dto2SensorSignals, dto2signal, signal2payload } from '../interface/astrion-signal.mappers';
import { AStrionSignalUploadResult, code2status } from '../interface/astrion-signal-upload-result.interface';
import { SensorSignalsDto } from '../interface/sensor-signal.interface';
import { SignalsApiService } from '../services/signals-api.service';
import { SignalsActions } from './signals.actions';
import { signalsFeature } from './signals.feature';

const dbFolderId = (folderId: FolderId): FolderId | null => (folderId === HOME_FOLDER.id ? null : folderId);

@Injectable()
export class SignalsEffects {
  constructor(
    private actions$: Actions,
    private signalsApi: SignalsApiService,
    private signalsStatusApi: SignalsStatusApiService,
    private store: Store
  ) {}

  fetchFolderSignalsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.folderSignalsFetchRequested),
      concatMap(({ folderId }) => {
        return this.signalsApi.getSensorSignals(dbFolderId(folderId)).pipe(
          map((signalsDto: SensorSignalsDto) =>
            SignalsActions.folderSignalsFetched({
              folderId: folderId,
              signals: dto2SensorSignals(signalsDto),
            })
          ),
          catchApiError(false, () => SignalsActions.folderSignalsFetchFailed())
        );
      })
    );
  });

  fetchFolderSignalsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.folderSignalsFetched),
      map(({ signals }) => SignalsActions.signalsStatusFetchRequested({ signalIds: signals.map(s => s.id) }))
    );
  });

  fetchSignalsStatusEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalsStatusFetchRequested),
      concatMap(({ signalIds }) => {
        return forkJoin([
          this.signalsStatusApi.getDataValidationSignalsStatus(signalIds),
          this.signalsStatusApi.getPeakIdentificationSignalsStatus(signalIds),
        ]).pipe(
          map(dto2Statuses),
          map(statuses => SignalsActions.signalsStatusFetched({ statuses })),
          catchApiError(false, () => SignalsActions.signalsStatusFetchFailed())
        );
      })
    );
  });

  tryDeleteSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalDeletionRequested),
      concatMap(({ signal }) =>
        this.signalsApi.deleteSignal(signal.id).pipe(
          map(signalId => SignalsActions.signalDeleted({ signalId, folderId: signal.folderId })),
          catchApiError(false, () => SignalsActions.signalDeletionFailed())
        )
      )
    );
  });

  tryUpdateSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalUpdateRequested),
      concatMap(({ signal }) => {
        return this.signalsApi.updateSignal(signal.id, signal2payload(signal)).pipe(
          map(() => SignalsActions.signalUpdated({ signal })),
          catchApiError(false, () => SignalsActions.signalUpdateFailed({ signal }))
        );
      })
    );
  });

  tryUploadSignalsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalsUploadRequested),
      concatMap(({ folderId, files }: { folderId: FolderId; files: File[] }) =>
        concat(
          of(SignalsActions.signalsUploadStarted()),
          this.signalsApi.uploadSignals(dbFolderId(folderId), files).pipe(
            withLatestFrom(this.store.select(signalsFeature.selectUploadCancel)),
            map(([value, cancel]: [Progress | UploadResponseAStrionSignalDto[], boolean]) => {
              if (isProgress(value)) {
                if (cancel && value.progress < value.total) {
                  return SignalsActions.signalsUploadCanceled();
                } else {
                  return SignalsActions.signalsUploadMadeProgress(value);
                }
              } else {
                const uploads: AStrionSignalUploadResult[] = value.map(
                  upload =>
                    ({
                      name: upload.name,
                      status: code2status(upload.status),
                      signal: upload.data ? dto2signal(upload.data) : null,
                    }) as AStrionSignalUploadResult
                );
                return SignalsActions.signalsUploaded({ uploads });
              }
            }),
            takeWhile(action => action.type != SignalsActions.signalsUploadCanceled.type, true),
            catchApiError(false, () => SignalsActions.signalsUploadFailed())
          )
        )
      )
    );
  });

  tryFetchSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalFetchRequested, SignalsActions.signalDataFetchRequested),
      concatMap(({ signalId }) =>
        this.signalsApi.getSignal(signalId).pipe(
          map((signalDto: AStrionSignalDto) => SignalsActions.signalFetched({ signal: dto2signal(signalDto) })),
          catchApiError(false, () => SignalsActions.signalFetchFailed())
        )
      )
    );
  });

  tryDownloadSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalDownloadRequested),
      concatMap(({ signalId }) =>
        this.signalsApi.downloadSignal(signalId).pipe(
          map(([name, content]: [name: string, content: Blob]) => {
            const contentUrl = URL.createObjectURL(content);

            const virtualLink = document.createElement('a');

            virtualLink.href = contentUrl;
            virtualLink.download = name;

            virtualLink.click();

            URL.revokeObjectURL(contentUrl);

            return SignalsActions.signalDownloaded();
          }),
          catchApiError(false, () => SignalsActions.signalDownloadFailed())
        )
      )
    );
  });
}
