import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  ExplorerItem,
  ExplorerSensorSignalItem,
} from '@features/files-explorer/shared/interface/explorer-item.interface';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { SensorGraphsComponent } from '@features/sensor-graphs/components/sensor-graphs/sensor-graphs.component';
import { SensorTrajectoriesComponent } from '@features/sensor-trajectories/components/sensor-trajectories/sensor-trajectories.component';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { MaterialModule } from '@modules/material.module';

import { SensorSignalsComponent } from '../sensor-signals/sensor-signals.component';

enum SensorTabId {
  Signals,
  Trajectories,
  Graphs,
}

const signalTab = { id: SensorTabId.Signals, name: 'Signals', fragment: undefined };
const trajectoryTab = { id: SensorTabId.Trajectories, name: 'Trajectories', fragment: 'trajectories' };
const graphTab = { id: SensorTabId.Graphs, name: 'Graph', fragment: 'graph' };

@Component({
  selector: 'app-sensor',
  standalone: true,
  imports: [
    MaterialModule,
    RouterModule,
    CommonModule,
    SensorSignalsComponent,
    SensorGraphsComponent,
    SensorTrajectoriesComponent,
  ],
  templateUrl: './sensor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorComponent {
  folder = input.required<Folder>();
  content = input.required<ExplorerItem[][]>();
  currentlyEditedItemId = input<AStrionSignalId | null>(null);
  loading = input<boolean>(false);

  itemClicked = output<ExplorerItem>();
  deleteClicked = output<ExplorerItem>();
  renameClicked = output<ExplorerItem>();
  downloadClicked = output<ExplorerItem>();
  refreshClicked = output();
  renameCanceled = output<ExplorerItem>();
  itemNameChanged = output<ExplorerItem>();
  openInNew = output<ExplorerSensorSignalItem>();
  filesDropped = output<File[]>();

  tabs = [signalTab, trajectoryTab, graphTab];
  name = computed(() => this.folder().name);
  signalCount = computed(() => this.folder().signalsCount);
  signalsTabSelected = this.isSelected(SensorTabId.Signals);
  trajectoriesTabSelected = this.isSelected(SensorTabId.Trajectories);
  graphsTabSelected = this.isSelected(SensorTabId.Graphs);

  private selectedTab = computed(() => {
    const fragment = this.fragment();
    const tab = this.tabs.find(t => t.fragment === fragment);
    return tab ?? signalTab;
  });
  private fragment = toSignal(this.activatedRoute.fragment);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  navigateToGraph() {
    this.router.navigate([], { fragment: graphTab.fragment });
  }

  isSelected(tabId: SensorTabId) {
    return computed(() => this.selectedTab().id === tabId);
  }
}
