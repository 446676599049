import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { DataValidation } from '../interface/data-validation';
import {
  defaultFundamentalPeriodicityTestWithLoadingState,
  FundamentalPeriodicityTest,
} from '../interface/fundamental-periodicity';
import { defaultSamplingTestWithLoadingState, ShannonTest } from '../interface/sampling-test';
import { defaultStationarityTestWithLoadingState, Stationarity } from '../interface/stationarity';
import { defaultSaturationTestWithLoadingState, TimeSaturation } from '../interface/time-saturation';
import { DataValidationActions } from './data-validation.actions';
import { DATA_VALIDATION_INITIAL_STATE, DataValidationState } from './data-validation.state';

export const reducer = createReducer(
  DATA_VALIDATION_INITIAL_STATE,
  on(
    DataValidationActions.dataValidationReset,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId | null }): DataValidationState => ({
      ...DATA_VALIDATION_INITIAL_STATE,
      signalId,
    })
  ),
  on(
    DataValidationActions.dataValidationFetched,
    (
      state: DataValidationState,
      { signalId, dataValidation }: { signalId: AStrionSignalId; dataValidation: DataValidation }
    ): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        ...dataValidation,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        loadingState: LoadingState.Error,
      };
    }
  ),
  on(
    DataValidationActions.signalTimeSaturationTestFetched,
    (
      state: DataValidationState,
      { signalId, saturationTest }: { signalId: AStrionSignalId; saturationTest: TimeSaturation }
    ): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        saturationTest,
      };
    }
  ),
  on(
    DataValidationActions.signalTimeSaturationTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        saturationTest: defaultSaturationTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.signalTimeSaturationTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        saturationTest: defaultSaturationTestWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    DataValidationActions.signalSamplingTestFetched,
    (
      state: DataValidationState,
      { signalId, samplingTest }: { signalId: AStrionSignalId; samplingTest: ShannonTest }
    ): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        samplingTest,
      };
    }
  ),
  on(
    DataValidationActions.signalSamplingTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        samplingTest: defaultSamplingTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.signalSamplingTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        samplingTest: defaultSamplingTestWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    DataValidationActions.signalStationarityTestFetched,
    (
      state: DataValidationState,
      { signalId, stationarityTest }: { signalId: AStrionSignalId; stationarityTest: Stationarity }
    ): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        stationarityTest,
      };
    }
  ),
  on(
    DataValidationActions.signalStationarityTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.signalStationarityTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    DataValidationActions.signalFundamentalPeriodicityTestFetched,
    (
      state: DataValidationState,
      { signalId, periodicity }: { signalId: AStrionSignalId; periodicity: FundamentalPeriodicityTest }
    ): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        fundamentalPeriodicityTest: periodicity,
      };
    }
  ),
  on(
    DataValidationActions.signalFundamentalPeriodicityTestNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        fundamentalPeriodicityTest: defaultFundamentalPeriodicityTestWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    DataValidationActions.signalFundamentalPeriodicityTestFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId || state.loadingState !== LoadingState.Loaded) {
        return state;
      }

      return {
        ...state,
        fundamentalPeriodicityTest: defaultFundamentalPeriodicityTestWithLoadingState(LoadingState.Error),
      };
    }
  )
);
