import { ErrorHandler } from '@angular/core';
import { Action } from '@ngrx/store';
import { RootInjector } from '@root/root.injector';
import { catchError, EMPTY, Observable, of, OperatorFunction } from 'rxjs';

import { AStrionApiError } from './app-error/app-error.class';

export function catchApiError(
  display: boolean,
  callback: (apiError: AStrionApiError) => Action
): OperatorFunction<Action, Action> {
  return catchError((error: AStrionApiError): Observable<Action> => {
    if (display || error.forceDisplay) {
      const errorHandler = RootInjector.get(ErrorHandler);
      errorHandler?.handleError(error);
    }
    return of(callback(error));
  });
}

export function catchApiErrorNoAction(): OperatorFunction<Action, Action> {
  return catchError(() => EMPTY);
}
