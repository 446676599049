import { InputSignalWithTransform } from '@angular/core';

import { InteractionCallbacks, RangeRatios, SelectionAreaDimensions } from '../interfaces/interfaces';
import { SynchronizableGraphComponent } from '../synchronizable-graph/synchronizable-graph.component';

export interface SynchronizableGraph {
  interactionCallbacks: InputSignalWithTransform<InteractionCallbacks, Partial<InteractionCallbacks>>;
  initializeSelectionArea: () => void;
  showSelectionArea: (show: boolean) => void;
  updateSelectionArea: (selectionDimensions: SelectionAreaDimensions) => void;
  updateRanges: (rangeRatios: RangeRatios) => void;
}

export function SynchronizeGraphs(graphs: SynchronizableGraphComponent[]): void {
  graphs.forEach(child => {
    child._interactionCallbacks.set({
      initializeSelectionArea: () => {
        graphs.forEach(child => child.initializeSelectionArea());
      },
      showSelectionArea: (show: boolean) => {
        graphs.forEach(child => child.showSelectionArea(show));
      },
      updateSelectionArea: (selectionDimensions: SelectionAreaDimensions) => {
        graphs.forEach(child => child.updateSelectionArea({ ...selectionDimensions }));
      },
      updateRanges: (rangeRatios: RangeRatios) => {
        graphs.forEach(child => child.updateRanges({ ...rangeRatios }));
      },
    });
  });
}
