import { createFeature } from '@ngrx/store';

import { reducer } from './signal-overview.reducer';
import { extraSelectors } from './signal-overview.selectors';
import { featureName } from './signal-overview.state';

export const signalOverviewFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors,
});
