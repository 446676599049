import { Component, input, output } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { DisplayColorPipe } from '../display-color.pipe';

@Component({
  selector: 'app-trajectory-graph-color-select',
  standalone: true,
  imports: [DisplayColorPipe, ColorPickerModule],
  templateUrl: './trajectory-graph-color-select.component.html',
})
export class TrajectoryGraphColorSelectComponent {
  color = input.required<string>();

  colorChanged = output<string>();

  onChange(color: string) {
    this.colorChanged.emit(color);
  }
}
