import { createSelector } from '@ngrx/store';

import { Folder, FolderId } from '../interface/folder.interface';
import { foldersFeature } from './folders.feature';

export const selectFolderIsLeaf = (folderId: FolderId) =>
  createSelector(
    foldersFeature.selectFlattenedFoldersTree,
    (flattenedFoldersTree): boolean => flattenedFoldersTree.children[folderId].length === 0
  );

export const selectFolder = (folderId: FolderId) =>
  createSelector(
    foldersFeature.selectFlattenedFoldersTree,
    (flattenedFoldersTree): Folder | undefined => flattenedFoldersTree.folders[folderId]
  );
