import { ConnectionStatus } from '../interfaces/connection-status';

export const name = 'realtime';

export interface RealtimeState {
  connectionStatus: ConnectionStatus | undefined;
}

export const REALTIME_INITIAL_STATE: RealtimeState = {
  connectionStatus: undefined,
};
