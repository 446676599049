import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SignalOverviewEffects } from './shared/store/signal-overview.effects';
import { signalOverviewFeature } from './shared/store/signal-overview.feature';

@NgModule({
  declarations: [],
  providers: [],
  imports: [StoreModule.forFeature(signalOverviewFeature), EffectsModule.forFeature([SignalOverviewEffects])],
})
export class SignalOverviewModule {}
