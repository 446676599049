import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterButtonComponent } from '@navigation/footer-button/footer-button.component';

import { NavigationBarComponent } from './navigation/navigation-bar/navigation-bar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [NavigationBarComponent, RouterOutlet, FooterButtonComponent],
})
export class AppComponent {}
