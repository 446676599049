<div class="inline-flex w-full gap-0">
  @if (isScrollable) {
    <button mat-icon-button class="flex-initial" (click)="scrollLeft()" [disabled]="!this.isScrollableLeft">
      <mat-icon>chevron_left</mat-icon>
    </button>
  }
  <div
    #main
    class="no-scrollbar m-2 flex-grow cursor-grab select-none overflow-x-scroll scroll-auto active:cursor-grabbing">
    @for (l of sortedLegend(); track l.name) {
      <span class="mx-2 inline whitespace-nowrap" [style.opacity]="l.display ? 1.0 : 0.1">
        <span [style.backgroundColor]="l.color" class="inline-block h-[12px] w-[12px] rounded-full"></span>
        {{ l.name }}
      </span>
    }
  </div>
  @if (isScrollable) {
    <button mat-icon-button class="flex-initial" (click)="scrollRight()" [disabled]="!this.isScrollableRight">
      <mat-icon>chevron_right</mat-icon>
    </button>
  }
</div>
