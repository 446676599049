import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogOptions } from '@components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '@components/input-dialog/input-dialog.component';
import { UploadFilesComponent } from '@components/upload-files/upload-files.component';
import { isValidName } from '@tools/utilities/encode-uri-strict';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirm(options: ConfirmDialogOptions): Promise<boolean> {
    return firstValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: options,
        })
        .afterClosed()
    );
  }

  input(): Promise<string> {
    return firstValueFrom(
      this.dialog
        .open(InputDialogComponent, {
          data: { title: 'Create new folder', placeholder: 'New folder name', isValid: isValidName },
          autoFocus: true,
        })
        .afterClosed()
    );
  }

  uploadFiles(): Promise<File[]> {
    return firstValueFrom(
      this.dialog.open(UploadFilesComponent, { restoreFocus: false, minWidth: '30vw', minHeight: '50vh' }).afterClosed()
    );
  }
}
