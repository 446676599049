import { FolderId, FoldersMap } from '../interface/folder.interface';
import { getAncestorsSet } from '../testing/tree-paths';

export const addSignalsToFolder = (foldersMap: FoldersMap, folderId: FolderId, n: number): FoldersMap =>
  addSignalsToFolders(foldersMap, getAncestorsSet(foldersMap, folderId), n);

const addSignalsToFolders = (foldersMap: FoldersMap, folderIds: Set<FolderId>, n: number): FoldersMap =>
  Object.fromEntries(
    Object.entries(foldersMap).map(([folderId, folder]) => [
      folderId,
      folderIds.has(folderId as FolderId) ? { ...folder, signalsCount: folder.signalsCount + n } : folder,
    ])
  );
