import { Injectable } from '@angular/core';
import { HOME_FOLDER } from '@features/folders/shared/interface/folder.interface';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { AStrionSignalUploadStatus } from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { string2Status } from '@features/signals-status/shared/interface/astrion-signals-status.mapper';
import { Store } from '@ngrx/store';
import { ProcessingStatus } from '@shared/interfaces/processing-status';
import { firstValueFrom } from 'rxjs';

import { FilesExplorerActions } from '../store/files-explorer.actions';
import { filesExplorerFeature } from '../store/files-explorer.feature';
import { selectExplorerSensorContainSignal } from '../store/files-explorer.selectors';
import {
  SignalDeletedMessage,
  SignalEditedMessage,
  SignalStepStatusChanged,
  SignalUploadedMessage,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class FilesExplorerRealtimeRegistrationService {
  constructor(private store: Store) {}

  public registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'SignalUploaded', callback: this.signalUploadedHandler },
      { messageType: 'SignalEdited', callback: this.signalEditedHandler },
      { messageType: 'SignalDeleted', callback: this.signalDeletedHandler },
      { messageType: 'PeakIdentificationComputationStatusChanged', callback: this.signalStepStatusChangedHandler },
      { messageType: 'DataValidationComputationStatusChanged', callback: this.signalStepStatusChangedHandler },
      { messageType: 'StationarityTestComputed', callback: this.signalDataValidationStepCompletedHandler },
      { messageType: 'TimeSaturationTestComputed', callback: this.signalDataValidationStepCompletedHandler },
      { messageType: 'SamplingTestComputed', callback: this.signalDataValidationStepCompletedHandler },
    ]);
    realtimeClient.registerMultipleMessagesHandlers([
      {
        messageType: 'PeakIdentificationComputationStatusChanged',
        callback: this.multipleSignalStepStatusChangedHandler,
      },
      { messageType: 'DataValidationComputationStatusChanged', callback: this.multipleSignalStepStatusChangedHandler },
    ]);
  }

  private signalUploadedHandler = async (msg: unknown): Promise<void> => {
    const signalData = msg as SignalUploadedMessage;

    const newSignal: AStrionSignal = {
      id: signalData.signalId,
      name: signalData.name,
      folderId: signalData.folderId ?? HOME_FOLDER.id,
      createdAt: new Date(signalData.createdAt),
      date: signalData.date ? new Date(signalData.date) : undefined,
      samplingFrequency: signalData.samplingFrequency,
      samplesCount: signalData.samplesCount,
      description: signalData.description,
    };

    const signalUploadResult = {
      name: newSignal.name,
      status: AStrionSignalUploadStatus.Success,
      signal: newSignal,
    };

    this.store.dispatch(SignalsActions.signalsUploaded({ uploads: [signalUploadResult] }));
  };

  private signalEditedHandler = async (msg: unknown): Promise<void> => {
    const signalData = msg as SignalEditedMessage;

    const cachedFolderPromise = firstValueFrom(this.store.select(filesExplorerFeature.selectCachedFolder));
    const reportSignalIdPromise = firstValueFrom(this.store.select(signalReportFeature.selectSignalId));

    const [cachedFolder, reportSignalId] = await Promise.all([cachedFolderPromise, reportSignalIdPromise]);

    if (reportSignalId === null && cachedFolder.folderId !== signalData.currentFolderId) {
      return;
    }

    const signal =
      reportSignalId === null
        ? cachedFolder.signals.find(s => s.id === signalData.signalId)
        : await firstValueFrom(this.store.select(signalReportFeature.selectSignal));

    if (signal === undefined) {
      return;
    }

    this.store.dispatch(
      SignalsActions.signalUpdated({
        signal: {
          ...signal,
          name: signalData.name,
        },
      })
    );
  };

  private signalDeletedHandler = (msg: unknown): void => {
    const signalData = msg as SignalDeletedMessage;

    const folderId = signalData.folderId ?? HOME_FOLDER.id;

    this.store.dispatch(SignalsActions.signalDeleted({ signalId: signalData.signalId, folderId }));
  };

  private signalStepStatusChangedHandler = async (msg: unknown): Promise<void> => {
    this.multipleSignalStepStatusChangedHandler([msg]);
  };

  private multipleSignalStepStatusChangedHandler = async (msgs: unknown[]): Promise<void> => {
    const signalData = msgs as SignalStepStatusChanged[];
    if (string2Status(signalData[0].newStatus) in [ProcessingStatus.Dirty, ProcessingStatus.InProgress]) {
      const statuses = signalData.reduce(
        (acc, item) => {
          acc[item.signalId] = string2Status(item.newStatus);
          return acc;
        },
        {} as Record<AStrionSignalId, ProcessingStatus>
      );
      this.store.dispatch(SignalsActions.signalsStatusFetched({ statuses }));
    } else {
      this.store.dispatch(SignalsActions.signalsStatusFetchRequested({ signalIds: signalData.map(s => s.signalId) }));
    }
  };

  private signalDataValidationStepCompletedHandler = async (msg: unknown): Promise<void> => {
    const signalData = msg as SignalStepStatusChanged;

    const isInCurrentSensor = await firstValueFrom(
      this.store.select(selectExplorerSensorContainSignal(signalData.signalId))
    );

    if (isInCurrentSensor) {
      this.store.dispatch(FilesExplorerActions.validationFlagsFetchRequested({ signalId: signalData.signalId }));
    }
  };
}
