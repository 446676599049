import { SensorId } from '@features/files-explorer/shared/interface/sensor-id.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { SensorTrajectoriesDto, Trajectory } from '../interfaces/trajectory.interface';
import { TrajectoryTrend } from '../interfaces/trajectory-trend.interface';

export const SensorTrajectoriesActions = createActionGroup({
  source: 'SensorTrajectories',
  events: {
    trajectoriesFetchRequested: props<{ sensorId: SensorId }>(),
    trajectoriesFetchFailed: props<{ sensorId: SensorId }>(),
    trajectoriesNotFound: props<{ sensorId: SensorId }>(),
    trajectoriesFetched: props<{ sensorId: SensorId; sensorTrajectories: SensorTrajectoriesDto }>(),

    trajectoriesStatusChanged: props<{
      sensorId: SensorId;
      status: ProcessingStatus;
      nextComputationDate: Date | null;
    }>(),

    trajectoriesFrequencyTrendVisualizeRequested: props<{ trajectory: Trajectory }>(),
    trajectoriesFrequencyTrendFetchFailed: emptyProps(),
    trajectoriesFrequencyTrendVisualized: props<{ trajectoryTrend: TrajectoryTrend }>(),
  },
});
