<div class="flex h-full w-full flex-row justify-center">
  <div class="mr-2 flex h-full flex-col justify-center">
    <span class="font-semibold">{{ colorMapData.minValue }} {{ colorMapData.valueUnit }}</span>
  </div>
  <div class="h-full w-[60%] border border-solid border-gray-300">
    <canvas #canvas class="h-full w-full"></canvas>
  </div>
  <div class="ml-2 flex h-full flex-col justify-center">
    <span class="font-semibold"> {{ colorMapData.maxValue }} {{ colorMapData.valueUnit }} </span>
  </div>
</div>
