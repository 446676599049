export interface PFAThresholds {
  high: number;
  low: number;
  veryLow: number;
}

export enum PMC {
  Unknown,
  High,
  Low,
  VeryLow,
}
