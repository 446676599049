<div class="flex-columns flex h-full w-full">
  <div
    data-testid="timeSaturation"
    class="h-full w-1/3 border border-neutral-200"
    [class]="timeSaturation().color"
    [matTooltip]="timeSaturation().tooltip"
    matTooltipPosition="above"
    matTooltipShowDelay="400"></div>
  <div
    data-testid="shannon"
    class="h-full w-1/3 border border-neutral-200"
    [class]="shannon().color"
    [matTooltip]="shannon().tooltip"
    matTooltipClass="mat-large-tooltip"
    matTooltipPosition="above"
    matTooltipShowDelay="400"></div>
  <div
    data-testid="nonStationnarity"
    class="h-full w-1/3 border border-neutral-200"
    [class]="nonStationnarity().color"
    [matTooltip]="nonStationnarity().tooltip"
    matTooltipPosition="above"
    matTooltipShowDelay="400"></div>
</div>
