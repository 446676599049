import { GraphCurve } from '@astrion-webtools/graph';

import { TrajectoryTrend, TrendType } from '../interfaces/trajectory-trend.interface';

export function getTrajectoryTrendCurve(trajectoryTrend: TrajectoryTrend): GraphCurve {
  return {
    name: trajectoryTrend.name,
    data: {
      indexedDb: {
        id: trajectoryTrend.interlacedValues,
        valuesField: 'interlacedValues',
      },
    },
  };
}

export function getTrajectoryTrendCurveId(type: TrendType, trajectoryTrend: TrajectoryTrend): string {
  return `${type}-${trajectoryTrend.name}`;
}
