import { IndexedDbStructure } from '@astrion-webtools/graph';

export const ASTRION_INDEXEDDB_VERSION = 7;
export const ASTRION_INDEXEDDB_NAME = 'astrion';
export const ASTRION_INDEXEDDB_TABLES = {
  cycles: 'cycles',
  signals: 'signals',
  samplingTests: 'sampling-tests',
  fusion: 'fusion',
  fundamentalPediodicity: 'fundamental-pediodicity',
  trajectoryTrend: 'trajectoryTrend',
};

export const ASTRION_INDEXEDDB: IndexedDbStructure = {
  name: ASTRION_INDEXEDDB_NAME,
  version: ASTRION_INDEXEDDB_VERSION,
  tables: Object.values(ASTRION_INDEXEDDB_TABLES),
};
