<mat-accordion>
  <mat-expansion-panel #panel class="w-full" [expanded]="expanded()" hideToggle disabled>
    <mat-expansion-panel-header class="mx-4 flex cursor-default flex-row items-center gap-2 py-2 text-sm">
      <mat-panel-title class="!text-black">{{ name() }}</mat-panel-title>
      <span class="flex-grow"></span>
      <button mat-icon-button class="graph-action-button" (click)="onSettings($event)" [disabled]="!hasData()">
        <mat-icon>settings</mat-icon>
      </button>
      <button mat-icon-button class="graph-action-button" (click)="onDownload($event)" [disabled]="!hasData()">
        <mat-icon>download</mat-icon>
      </button>
      <button mat-icon-button class="graph-action-button" (click)="onDelete($event)" [disabled]="!hasData()">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button class="graph-action-button" (click)="onTogglePanel($event)">
        <mat-icon>{{ expanded() ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
      </button>
    </mat-expansion-panel-header>
    <mat-divider />
    <app-trajectory-graph-legend [legend]="legend()" />
    <mat-drawer-container [style.height]="graphHeight()">
      <mat-drawer-content class="relative overflow-clip">
        <lib-gl-graph
          #graph
          class="absolute inset-0"
          [style.visibility]="hasData() ? 'visible' : 'hidden'"
          [data]="completedData()"
          [id]="id()"
          [xTitle]="xTitle()"
          [yTitle]="yTitle()"
          [drawPoints]="true"
          [xAxisDataIsTimestamps]="true"
          [showGroupAxis]="showGroupAxis()" />
        @if (!hasData()) {
          <div class="absolue inset-0 flex h-full w-full flex-row content-center items-center justify-center gap-2">
            <mat-icon class="align-middle">block</mat-icon><span>No content</span>
          </div>
        }
      </mat-drawer-content>
      <mat-drawer #drawer position="end" mode="side">
        <app-trajectory-graph-settings
          #settings
          [data]="completedData()"
          [groups]="groups()"
          [visibleGroups]="visibleGroups()"
          [(groupStates)]="groupStates"
          (curvesGrouped)="curvesGrouped($event)"
          (curvesUngrouped)="curvesUngrouped($event)"
          (removeCurve)="removeCurve.emit($event)"
          (focusedCurve)="focusedCurve.set($event)"
          (colorSelected)="onColorSelected($event)" />
      </mat-drawer>
    </mat-drawer-container>
  </mat-expansion-panel>
</mat-accordion>
