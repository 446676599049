import { createFeature } from '@ngrx/store';

import { reducer } from './signal-report.reducer';
import { extraSelectors } from './signal-report.selectors';
import { featureName } from './signal-report.state';

export const signalReportFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors,
});
