import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FoldersRealtimeRegistrationService } from './shared/realtime/folders-realtime-service';
import { FoldersEffects } from './shared/store/folders.effects';
import { foldersFeature } from './shared/store/folders.feature';

@NgModule({
  imports: [
    StoreModule.forFeature(foldersFeature),
    EffectsModule.forFeature([FoldersEffects]),
    RealtimeModule.forFeature(FoldersRealtimeRegistrationService),
  ],
})
export class FoldersModule {}
