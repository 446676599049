import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  OnDestroy,
  output,
  QueryList,
  viewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';
import { selectFragment } from '@store/router.selectors';
import { combineLatest, Subscription, tap } from 'rxjs';

import { ReportPageBaseComponent } from '../report-page-base/report-page-base';

@Component({
  selector: 'app-report-content-container',
  standalone: true,
  imports: [MaterialModule, RouterModule, ScrollableBorderedContainerComponent],
  templateUrl: './report-content-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportContentContainerComponent implements OnDestroy {
  loading = input<boolean>(false);

  scrolledAnchor = output<string | undefined>();

  public container = viewChild.required<ElementRef>('container');

  private loadingState$ = this.store.select(signalReportFeature.selectLoadingState);
  private fragment$ = this.store.select(selectFragment);
  private subscription = new Subscription();
  private currentAnchors = new QueryList<ElementRef>();

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onScroll(): void {
    const container = this.container();

    const container_rect = container.nativeElement.getBoundingClientRect();
    const ctop = container_rect.top;
    const cbottom = container_rect.bottom;

    let maxPixels = 0;
    let mainAnchor = '';
    this.currentAnchors.forEach(anchor => {
      const rect = anchor.nativeElement.getBoundingClientRect();
      if (rect.top < cbottom && rect.bottom > ctop) {
        const pixels = Math.min(rect.bottom, cbottom) - Math.max(rect.top, ctop);
        if (pixels > maxPixels) {
          maxPixels = pixels;
          mainAnchor = anchor.nativeElement.id;
        }
      }
    });
    if (mainAnchor.length > 0) {
      this.scrolledAnchor.emit(mainAnchor);
    }
  }

  onActivate(pageComponent: ReportPageBaseComponent): void {
    this.scrolledAnchor.emit(undefined);
    this.subscription.unsubscribe();
    this.subscription = combineLatest([this.fragment$, this.loadingState$, pageComponent.anchors$])
      .pipe(
        tap(([, , anchors]) => {
          this.currentAnchors = anchors;
        })
      )
      .subscribe(([fragment, loading, anchors]: [string | undefined, LoadingState, QueryList<ElementRef>]) => {
        if (loading != LoadingState.Loading && fragment) {
          const element = anchors.find(elem => elem.nativeElement.id === fragment);
          if (element) element.nativeElement.scrollIntoView();
        }
      });
  }
}
