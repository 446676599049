import { PMC } from '@features/peak-identification/shared/interface/pfa-pmc-thresholds';
import { PeakTypes } from '@features/peak-identification/shared/utils/peak-style';

import { SimplifiedPeak } from '../peaks-graph-interactive-legend.component';

export const hasSWType = (peak: SimplifiedPeak) => peak.type === PeakTypes.SW;
export const hasNBType = (peak: SimplifiedPeak) => peak.type === PeakTypes.NB;
export const hasSWNoiseDoubtType = (peak: SimplifiedPeak) => peak.type === PeakTypes.SWNoiseDoubt;
export const hasNBNoiseDoubtType = (peak: SimplifiedPeak) => peak.type === PeakTypes.NBNoiseDoubt;
export const hasNoiseType = (peak: SimplifiedPeak) => peak.type === PeakTypes.Noise;

export const hasHighPMC = (peak: SimplifiedPeak) => peak.pmc === PMC.High;
export const hasLowPMC = (peak: SimplifiedPeak) => peak.pmc === PMC.Low;
export const hasVeryLowPMC = (peak: SimplifiedPeak) => peak.pmc === PMC.VeryLow;

export const countPeaks = (peaks: SimplifiedPeak[] | undefined, predicate: (peak: SimplifiedPeak) => boolean) => {
  if (peaks === undefined) {
    return undefined;
  }

  let count = 0;

  peaks.forEach(peak => {
    if (predicate(peak)) {
      count++;
    }
  });

  return count;
};
