import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createReducer, on } from '@ngrx/store';
import { loaded, LoadingState } from '@shared/interfaces/loading-state';

import { SignalContent } from '../interface/signal-content';
import { SignalOverviewActions } from './signal-overview.actions';
import { SIGNAL_OVERVIEW_INITIAL_STATE, SignalOverviewState } from './signal-overview.state';

export const reducer = createReducer(
  SIGNAL_OVERVIEW_INITIAL_STATE,
  on(
    SignalOverviewActions.signalContentReset,
    (state: SignalOverviewState, { signalId }: { signalId: AStrionSignalId | null }): SignalOverviewState => ({
      ...SIGNAL_OVERVIEW_INITIAL_STATE,
      signalId,
    })
  ),
  on(
    SignalOverviewActions.signalContentFetched,
    (
      state: SignalOverviewState,
      { signalId, signalContent }: { signalId: AStrionSignalId; signalContent: SignalContent }
    ): SignalOverviewState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        signalData: {
          ...signalContent,
        },
        loadingState: LoadingState.Loaded,
      };
    }
  ),
  on(
    SignalOverviewActions.signalContentNotFound,
    (state: SignalOverviewState, { signalId }: { signalId: AStrionSignalId }): SignalOverviewState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        ...SIGNAL_OVERVIEW_INITIAL_STATE,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    SignalOverviewActions.signalContentFetchFailed,
    (state: SignalOverviewState, { signalId }: { signalId: AStrionSignalId }): SignalOverviewState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        ...SIGNAL_OVERVIEW_INITIAL_STATE,
        loadingState: LoadingState.Error,
      };
    }
  ),
  on(
    SignalOverviewActions.signalBytesFetched,
    (
      state: SignalOverviewState,
      { signalId, samples }: { signalId: AStrionSignalId; samples: IndexedDbRowId }
    ): SignalOverviewState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      const currentData = state.signalData!;

      return {
        ...state,
        signalData: {
          ...currentData,
          content: {
            ...currentData.content,
            loadingState: LoadingState.Loaded,
            data: samples,
          },
        },
      };
    }
  ),
  on(
    SignalOverviewActions.signalBytesNotFound,
    (state: SignalOverviewState, { signalId }: { signalId: AStrionSignalId }): SignalOverviewState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      const currentData = state.signalData!;

      return {
        ...state,
        signalData: {
          ...currentData,
          content: {
            ...currentData.content,
            loadingState: LoadingState.NotFound,
          },
        },
      };
    }
  ),
  on(
    SignalOverviewActions.signalContentFetchFailed,
    (state: SignalOverviewState, { signalId }: { signalId: AStrionSignalId }): SignalOverviewState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      const currentData = state.signalData!;

      return {
        ...state,
        signalData: {
          ...currentData,
          content: {
            ...currentData.content,
            loadingState: LoadingState.Error,
          },
        },
      };
    }
  )
);
