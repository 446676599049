import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { createSelector } from '@ngrx/store';

import { featureName, PeakIdentificationState } from './peak-identification.state';

export const extraSelectors = ({ selectProgress }: BaseSelectors<typeof featureName, PeakIdentificationState>) => ({
  selectPeakIdentificationComputed: createSelector(
    selectProgress,
    (progress: ComputationStepProgress): boolean => progress === ComputationStepProgress.Completed
  ),
});
