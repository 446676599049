import { GraphCurve } from '@astrion-webtools/graph';

export const name = 'sensor-graphs';

export interface SensorGraphsState {
  curves: Record<string, GraphCurve>;
}

export const SENSOR_GRAPHS_INITIAL_STATE: SensorGraphsState = {
  curves: {},
};
