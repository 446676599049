import { encodeURIComponentStrict } from '@tools/utilities/encode-uri-strict';

import { FlattenedFolderTree, Folder, FolderId, HOME_FOLDER } from '../interface/folder.interface';

/**
 * Describes a mapping between folders and there corresponding escaped URI
 */
export interface FoldersUri {
  folderIdToUri: Record<FolderId, string>;
  uriToFolderId: Record<string, FolderId>;
}

/**
 * Get the folders URI to / from folders from an input folder tree structure
 * @param foldersTree the input folders tree structure
 * @returns folder <-> URI mapping
 */
export const getFoldersUri = (foldersTree: FlattenedFolderTree): FoldersUri => {
  const paths: FoldersUri = {
    folderIdToUri: {},
    uriToFolderId: {},
  };
  addFolderAndChildren(foldersTree, paths, HOME_FOLDER.id, '');
  return paths;
};

/**
 * Delete a folder from a folder <-> URI mapping (immutably)
 * @param foldersUri the folder <-> URI mapping to delete from
 * @param idToDelete id of the folder to delete from foldersUri
 * @returns the resulting new folders <-> URI mapping
 */
export const deleteFromFoldersUri = (foldersUri: FoldersUri, idToDelete: FolderId): FoldersUri => ({
  folderIdToUri: Object.fromEntries(
    Object.entries(foldersUri.folderIdToUri).filter(([currentId]) => currentId !== idToDelete)
  ),
  uriToFolderId: Object.fromEntries(
    Object.entries(foldersUri.uriToFolderId).filter(([, folderId]) => folderId !== idToDelete)
  ),
});

/**
 * Delete a folder from a folder <-> URI mapping (immutably)
 * @param foldersUri the folder <-> URI mapping to delete from
 * @param idsToDelete set of id of the folders to delete from foldersUri
 * @returns the resulting new folders <-> URI mapping
 */
export const deleteSetFromFoldersUri = (foldersUri: FoldersUri, idsToDelete: Set<FolderId>): FoldersUri => ({
  folderIdToUri: Object.fromEntries(
    Object.entries(foldersUri.folderIdToUri).filter(([currentId]) => !idsToDelete.has(currentId as FolderId))
  ),
  uriToFolderId: Object.fromEntries(
    Object.entries(foldersUri.uriToFolderId).filter(([, folderId]) => !idsToDelete.has(folderId))
  ),
});

/**
 * Add a folder to a folder <-> URI mapping (immutably)
 * @param foldersUri the folder <-> URI mapping to add into
 * @param folder the folder to add to foldersUri
 * @returns the resulting new folders <-> URI mapping
 */
export const addToFoldersUri = (foldersUri: FoldersUri, folder: Folder): FoldersUri => {
  const encodedName = encodeURIComponentStrict(folder.name);
  const folderPath = `${foldersUri.folderIdToUri[folder.parentId]}/${encodedName}`;
  return {
    folderIdToUri: { ...foldersUri.folderIdToUri, [folder.id]: folderPath },
    uriToFolderId: { ...foldersUri.uriToFolderId, [folderPath]: folder.id },
  };
};

/**
 * Get a folder's path from root folder to given folder, in the form of a folders list.
 * @param folder the input folder
 * @param foldersTree the input folders tree structure
 * @returns the folders list from root (home) folder to input folder
 */
export const getPathFromRoot = (folder: Folder, foldersTree: FlattenedFolderTree): Folder[] =>
  folder.parentId === folder.id // is home folder ?
    ? [folder]
    : [...getPathFromRoot(foldersTree.folders[folder.parentId], foldersTree), folder];

/**
 * Get a folder's path from root folder to given folder, in the form of a folders list.
 * @param folderId the input folder id
 * @param foldersTree the input folders tree structure
 * @returns the folders list from root (home) folder to input folder
 */
export const getPathFromRootWithId = (folderId: FolderId, foldersTree: FlattenedFolderTree): Folder[] | null =>
  foldersTree.folders[folderId] ? getPathFromRoot(foldersTree.folders[folderId], foldersTree) : null;

/**
 * Get folder id
 * @param foldersUri mapping between folder and url
 * @param url current url
 * @returns folder id found in mapping
 */
export const getFolderId = (foldersUri: FoldersUri, url: string): FolderId | null => {
  return foldersUri.uriToFolderId[removeFragment(url)] ?? null;
};

/**
 * Get folder url
 * @param foldersUri mapping between folder and url
 * @param folderId foler id
 * @param currentUrlFragment current url fragment, kept in return url
 * @returns url found in mapping with fragment added
 */
export const getFolderUrl = (foldersUri: FoldersUri, folderId: FolderId, currentUrlFragment?: string) => {
  const url = foldersUri.folderIdToUri[folderId];
  if (currentUrlFragment) {
    return `${url}#${currentUrlFragment}`;
  }
  return url;
};

const addFolderAndChildren = (
  foldersTree: FlattenedFolderTree,
  paths: FoldersUri,
  currentFolderId: FolderId,
  currentPath: string
) => {
  // currentPath is considered uri encoded
  paths.folderIdToUri[currentFolderId] = currentPath;
  paths.uriToFolderId[currentPath] = currentFolderId;
  foldersTree.children[currentFolderId].forEach((childId: FolderId) => {
    const child = foldersTree.folders[childId];
    const encodedName = encodeURIComponentStrict(child.name);
    addFolderAndChildren(foldersTree, paths, childId, `${currentPath}/${encodedName}`);
  });
};

const removeFragment = (url: string) => {
  const index = url.indexOf('#');
  if (index === -1) {
    return url;
  }
  return url.slice(0, index);
};
