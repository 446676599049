import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FlattenedFolderTree, Folder, FolderId } from '../interface/folder.interface';

export const FoldersActions = createActionGroup({
  source: 'Folders',
  events: {
    foldersFetchRequestedIfNotInit: emptyProps(),
    foldersFetchRequested: emptyProps(),
    foldersFetchSkipped: emptyProps(),
    foldersFetchFailed: emptyProps(),
    foldersFetched: props<{ flattenedFoldersTree: FlattenedFolderTree }>(),

    folderDeletionRequested: props<{ folderId: FolderId; parentId: FolderId; force: boolean }>(),
    folderDeleted: props<{ folderId: FolderId; parentId: FolderId }>(),
    folderDeletionFailed: props<{ folderId: FolderId }>(),

    folderCreationRequested: props<{ name: string; parentId: FolderId | null }>(),
    folderCreated: props<{ folder: Folder }>(),
    folderCreationFailed: emptyProps(),

    folderUpdateRequested: props<{ folder: Folder }>(),
    folderUpdated: props<{ folder: Folder }>(),
    folderUpdateFailed: props<{ folder: Folder }>(),
  },
});
