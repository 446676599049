import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { ValidationStepStatus } from './validation-step-status';

export type SaturationIndices = number[];

export interface TimeSaturationDataDto {
  saturationIndices: string;
}

export interface TimeSaturationDto {
  progress: string;
  data?: TimeSaturationDataDto;
}

export interface TimeSaturationData {
  status: ValidationStepStatus;
  saturationIndices: number[];
}

export interface TimeSaturation {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: TimeSaturationData;
}

export const defaultSaturationTestWithLoadingState = (
  loadingState: LoadingState = LoadingState.Unknown
): TimeSaturation => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
