import { Injectable } from '@angular/core';

import fileTypeInfo from './file-type-info.service.json';

export type HtmlColor = `#${number}`;

export interface FileTypeInfo {
  icon: string;
  color: HtmlColor;
}

function isFile(file: File | string): file is File {
  return file instanceof File;
}

@Injectable({
  providedIn: 'root',
})
export class FileTypeInfoService {
  getIcon(file: File | string): string {
    return this.getInfo(file).icon;
  }

  getColor(file: File | string): string {
    return this.getInfo(file).color;
  }

  getInfo(file: File | string): FileTypeInfo {
    const extension = this.getExtension(file);
    const info = fileTypeInfo as Record<string, FileTypeInfo>;
    return info[extension] ?? info['default'];
  }

  private getExtension(file: File | string): string {
    const filename: string = isFile(file) ? file.name : file;
    return filename.split('.').pop()?.toLowerCase() ?? 'default';
  }
}
